import { useMemo } from 'react';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { STAKEHOLDER_ROLE, StakeholderDraft } from '../../../../entities/Stakeholder';
import {
  AttachmentsSections,
  DataEntryPages,
  PaperworkPhases,
} from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useStakeholders } from '../../../../hooks/useStakeholders';

/**
 * Hook that filters the available stakeholder documents can be associated to:
 *   - for the relevant sections, filters out all the military bases, from whom it is apparently
 *     impossible to obtain any kind of document anyway
 *   - if SOME stakeholders are military bases, show a big yellow alert and allow the attachments
 *     upload for the other stakeholders
 *   - if ALL stakeholders are military bases, show only another big yellow alert
 *   - if there are no stakeholders at all... well, we might have a problem here
 */
export function useFilteredStakeholders(): {
  filteredStakeholders: Record<string, StakeholderDraft>;
  hasMilitaryBases: boolean;
  onlyMilitaryBases: boolean;
} {
  const { paperwork } = usePaperwork();
  const { stakeholders } = useStakeholders();
  const { fieldValues } = usePaperworkFields();
  const { navigationContext } = usePaperworkNavigation();

  return useMemo(() => {
    const filteredStakeholders: Record<string, StakeholderDraft> = {};
    let hasMilitaryBases = false;
    let onlyMilitaryBases = true;

    const paperworkType = paperwork?.paperworkTemplate.paperworkType.name;

    // The stakeholders check only applies to Matrice 5 ( LGT / AGT5 / AGT3 / LGP)
    if (
      paperworkType === 'L. 185/90 - LGT' ||
      paperworkType === 'L. 185/90 - AGT5' ||
      paperworkType === 'L. 185/90 - AGT3' ||
      paperworkType === 'L. 185/90 - LGP'
    ) {
      const endUserMatchesField = Object.values(fieldValues).find(
        (fieldValue) =>
          fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
      );
      const endUserMatchesShipTo = endUserMatchesField && endUserMatchesField.value === 'ship-to';
      const contextIsEus =
        navigationContext.phase === PaperworkPhases.DataEntry &&
        navigationContext.page === DataEntryPages.Attachments &&
        navigationContext.section === AttachmentsSections.Eus;

      for (const [key, stakeholder] of Object.entries(stakeholders)) {
        const militaryBaseField = Object.values(fieldValues).find((fieldValue) => {
          if (fieldValue.stakeholderID === stakeholder.id) {
            if (fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE) {
              return true;
            }
          }
          return false;
        });

        // Only show end users for EUS
        if (contextIsEus) {
          if (
            (endUserMatchesShipTo && stakeholder.role !== STAKEHOLDER_ROLE.SHIP_TO) ||
            (!endUserMatchesShipTo && stakeholder.role !== STAKEHOLDER_ROLE.END_USERS)
          ) {
            continue;
          }
        }

        if (militaryBaseField && militaryBaseField.value === 'true') {
          hasMilitaryBases = true;
        } else {
          onlyMilitaryBases = false;
          filteredStakeholders[key] = stakeholder;
        }
      }

      return { filteredStakeholders, hasMilitaryBases, onlyMilitaryBases: hasMilitaryBases && onlyMilitaryBases };
    }

    return {
      filteredStakeholders: stakeholders ?? {},
      hasMilitaryBases,
      onlyMilitaryBases: hasMilitaryBases && onlyMilitaryBases,
    };
  }, [fieldValues, navigationContext, paperwork?.paperworkTemplate.paperworkType.name, stakeholders]);
}
