import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

// Subset of https://en.wikipedia.org/wiki/Incoterms, relevant to MT's business
const mtIncoterms = ['FCA', 'EXW', 'DAP', 'CIP', 'CPT', 'CFR', 'DPU'];

function ContractualTermsYieldAutocompleteComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  return (
    <Autocomplete
      disablePortal
      options={mtIncoterms}
      renderInput={(params) => <TextField {...params} label={field.label} />}
      value={fieldValue?.value}
      onChange={(event, value) => updateValue(value ?? undefined)}
    />
  );
}

export const ContractualTermsYieldAutocomplete = React.memo(ContractualTermsYieldAutocompleteComponent);
