import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import Alert, { alertClasses } from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FileDocumentOutlineIcon } from '../../../components/Icons';
import { PrimaryButton } from '../../../components/NavigationPanel/NavigationButtons';
import { Attachment } from '../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE, PAPERWORK_STATUS, PaperworkSendPecPayloadSchema } from '../../../entities/Paperwork';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { usePatchPaperworkMutation, useSendPecMutation } from '../../../services/paperwork';
import { useReadPecSettingsQuery } from '../../../services/pecSettings';

const WriteEmailFormSchema = PaperworkSendPecPayloadSchema.omit({
  attachmentIdList: true,
}).extend({
  confirmSend: z.boolean().refine((arg) => arg === true),
});

type WriteEmailForm = z.infer<typeof WriteEmailFormSchema>;

interface WriteEmailDialogProps {
  signedPaperworkAttachment?: Attachment;
  mailAttachments: Set<number>;
  mailAttachmentContextId: number;
}

function WriteEmailDialogComponent(props: WriteEmailDialogProps) {
  const { mailAttachments, signedPaperworkAttachment, mailAttachmentContextId } = props;
  const [open, setOpen] = React.useState(false);

  const { paperwork } = usePaperwork();
  const { data: pecSettings } = useReadPecSettingsQuery();
  const [sendPec, sendPecRequest] = useSendPecMutation();
  const [patchPaperwork, patchPaperworkRequest] = usePatchPaperworkMutation();

  const { searchFieldValue } = usePaperworkFields();

  const signedFieldValue = searchFieldValue({
    attachmentID: signedPaperworkAttachment?.attachment.id,
    fieldDatatype: PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED,
  });

  const additionalMailAttachments = useMemo(() => {
    return (
      paperwork?.attachmentList
        ?.filter((attachment) => attachment.savedInContextId === mailAttachmentContextId)
        .map((attachment) => attachment.attachment.id) ?? []
    );
  }, [mailAttachmentContextId, paperwork?.attachmentList]);

  const { control, handleSubmit, reset, watch, setValue } = useForm<WriteEmailForm>({
    defaultValues: {
      paperworkId: paperwork?.id ?? -1,
      to: pecSettings?.to ?? [],
      subject: '',
      // TODO: the mail default text should not be hardcoded in a tsx file
      body: 'Si trasmette quanto in oggetto.\nDistinti saluti,\n\nMicrotecnica s.r.l.',
      confirmSend: false,
    },
    resolver: zodResolver(WriteEmailFormSchema),
  });

  const attachmentsToSend = useMemo(
    () => [signedPaperworkAttachment?.attachment.id, ...Array.from(mailAttachments), ...additionalMailAttachments],
    [additionalMailAttachments, mailAttachments, signedPaperworkAttachment?.attachment.id]
  );

  async function onSubmit(values: WriteEmailForm) {
    if (paperwork) {
      try {
        await sendPec({
          paperworkId: paperwork.id,
          attachmentIdList: attachmentsToSend,
          body: `<!DOCTYPE html><html><body>${values.body
            .split('\n')
            .map((p) => `<p>${p}<p/>`)
            .join('')}</body></html>`,
          subject: `Paperwork #${paperwork.id} — ${values.subject}`,
          to: values.to,
        }).unwrap();

        await patchPaperwork({
          paperworkId: paperwork.id,
          statusId: PAPERWORK_STATUS.SENT,
        }).unwrap();

        setOpen(false);
      } catch (_error) {
        // Noop
      }
    }
  }

  const confirmSend = watch('confirmSend');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const attachmentsMap = useMemo(() => {
    const attachmentsMap = new Map<Attachment['id'], Attachment>();
    for (const attachment of paperwork?.attachmentList ?? []) {
      attachmentsMap.set(attachment.attachment.id, attachment);
    }

    return attachmentsMap;
  }, [paperwork]);

  useEffect(() => {
    if (pecSettings) {
      setValue('to', pecSettings.to);
    }
  }, [pecSettings, setValue]);

  return (
    <React.Fragment>
      <PrimaryButton
        onClick={handleClickOpen}
        sx={{ mr: 1 }}
        disabled={!signedPaperworkAttachment || signedFieldValue?.value !== 'yes' || Boolean(paperwork?.pecStatus)}
      >
        {'Componi email'}
      </PrimaryButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="write-email-title"
        aria-describedby="write-email-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          onReset: handleClose,
        }}
        TransitionProps={{
          onExited: () => {
            reset();
          },
        }}
      >
        <DialogTitle id="write-email-title">{'Componi Email di invio pratica'}</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={1} paddingTop={1}>
            <Controller
              control={control}
              name="to"
              render={({ field, fieldState: { error, invalid } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  getOptionLabel={(option) => option}
                  value={field.value}
                  onChange={(_, values) => {
                    field.onChange(values);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="A:"
                      error={invalid}
                      helperText={error?.message}
                      onBlur={(e) => {
                        if (e.target.value?.length) {
                          field.onChange([...field.value, e.target.value]);
                        }
                      }}
                    />
                  )}
                  options={pecSettings?.to ?? []}
                  clearOnBlur
                />
              )}
            />
            <Controller
              control={control}
              name="subject"
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField {...field} label="Oggetto" size="small" helperText={error?.message} error={invalid} />
              )}
            />
            <Stack direction="column" marginY={1} gap={1}>
              <FormLabel>{'Allegati:'}</FormLabel>

              <Stack direction="row" gap={1.5} flexWrap="wrap">
                {attachmentsToSend.map((attachmentId) => {
                  const attachment = attachmentsMap.get(attachmentId);
                  if (!attachment) {
                    return null;
                  }
                  return (
                    <Chip
                      key={attachment.id}
                      variant="outlined"
                      component="a"
                      aria-label="Download"
                      download={attachment.attachment.name}
                      href={`/${attachment.attachment.path}`}
                      icon={<FileDocumentOutlineIcon fontSize="small" sx={{ color: 'text.secondary' }} />}
                      label={attachment.attachment.name}
                      clickable
                      sx={{ borderRadius: 1 }}
                    />
                  );
                })}
              </Stack>
            </Stack>
            <Controller
              control={control}
              name="body"
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField {...field} multiline rows={10} helperText={error?.message} error={invalid} />
              )}
            />
            <Controller
              control={control}
              name="confirmSend"
              render={({ field }) => (
                <Alert
                  severity="warning"
                  sx={{
                    marginTop: 2,
                    [`.${alertClasses.icon}`]: {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(_, checked) => field.onChange(checked)}
                      />
                    }
                    label="Confermo di voler inviare l’email/PEC"
                  />
                </Alert>
              )}
            />
          </Stack>
          {sendPecRequest.error ? (
            <Alert color="error" sx={{ marginTop: 1 }}>
              {'Si è verificato un errore, l’email non è stata inviata'}
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" type="reset">
            {'Annulla'}
          </Button>
          <LoadingButton
            type="submit"
            loading={sendPecRequest.isLoading || patchPaperworkRequest.isLoading}
            variant="contained"
            color="primary"
            disabled={!confirmSend}
          >
            {'Invia'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const WriteEmailDialog = React.memo(WriteEmailDialogComponent);
