import { z } from 'zod';

export const EccnSchema = z.object({
  availableToManufacturers: z.boolean(),
  country: z.string(),
  id: z.string(),
  isMilitary: z.boolean(),
  legislation: z.string(),
});

export type Eccn = z.infer<typeof EccnSchema>;
