import { useMemo } from 'react';
import { FileCogOutlineIcon } from '../../components/Icons';
import { SerniListUpdateRoute } from '../../entities/SerniListUpdate';
import {
  DataEntryPages,
  DataEntrySections,
  IssuingDeductionPages,
  PreparationSigningPages,
  SerniListUpdateNavigationStructure,
  SerniListUpdatePhases,
} from './serniListUpdateNavigation';
import { useSerniListUpdate } from './useSerniListUpdate';

export function useSerniListUpdateNavigation() {
  const { serniListUpdate } = useSerniListUpdate();

  // The path of the SLU Details page
  const detailsPath = useMemo(() => [`/serni-list-updates`, serniListUpdate?.id].join('/'), [serniListUpdate?.id]);

  const routes = useMemo(() => {
    const phasesRoutes: SerniListUpdateNavigationStructure<SerniListUpdateRoute> = {
      [SerniListUpdatePhases.Configuration]: {
        title: 'Configurazione',
        icon: <FileCogOutlineIcon />,
        path: 'configuration',
        absolutePath: 'configuration',
        matcher: 'configuration/*',
        absoluteMatcher: '/serni-list-update/:id/configuration/*',
      },
      [SerniListUpdatePhases.DataEntry]: {
        title: 'Inserimento dati',
        icon: <FileCogOutlineIcon />,
        path: 'data-entry',
        absolutePath: 'data-entry',
        matcher: 'data-entry/*',
        absoluteMatcher: '/serni-list-update/:id/data-entry/*',
        pages: {
          [DataEntryPages.DataEntry]: {
            title: 'Inserimento dati',
            icon: <FileCogOutlineIcon />,
            path: '',
            absolutePath: 'data-entry',
            matcher: '*',
            absoluteMatcher: '/serni-list-updates/:id/data-entry/*',
            sections: {
              [DataEntrySections.Equipments]: {
                title: 'Equipaggiamenti',
                path: 'equipments',
                absolutePath: 'data-entry/equipments',
                matcher: 'equipments',
                absoluteMatcher: '/serni-list-updates/:id/data-entry/equipments/*',
              },
              [DataEntrySections.Attachments]: {
                title: 'Documenti',
                path: 'attachments',
                absolutePath: 'data-entry/attachments',
                matcher: 'attachments',
                absoluteMatcher: '/serni-list-updates/:id/data-entry/attachments/*',
              },
            },
          },
        },
      },
      [SerniListUpdatePhases.PreparationSigning]: {
        title: 'Inserimento dati',
        icon: <FileCogOutlineIcon />,
        path: '',
        absolutePath: '',
        matcher: '*',
        absoluteMatcher: '/serni-list-update/:id/data-entry/*',
        pages: {
          [PreparationSigningPages.Preparation]: {
            title: 'Inserimento dati',
            icon: <FileCogOutlineIcon />,
            path: '',
            absolutePath: '',
            matcher: '*',
            absoluteMatcher: '/serni-list-update/:id/data-entry/*',
          }, // TODO: TBD
          [PreparationSigningPages.Signing]: {
            title: 'Inserimento dati',
            icon: <FileCogOutlineIcon />,
            path: '',
            absolutePath: '',
            matcher: '*',
            absoluteMatcher: '/serni-list-update/:id/data-entry/*',
          },
        },
      },
      [SerniListUpdatePhases.IssuingDeduction]: {
        title: 'Inserimento dati',
        icon: <FileCogOutlineIcon />,
        path: '',
        absolutePath: '',
        matcher: '*',
        absoluteMatcher: '/serni-list-update/:id/data-entry/*',
        pages: {
          [IssuingDeductionPages.Issuing]: {
            title: 'Inserimento dati',
            icon: <FileCogOutlineIcon />,
            path: '',
            absolutePath: '',
            matcher: '*',
            absoluteMatcher: '/serni-list-update/:id/data-entry/*',
          },
          [IssuingDeductionPages.Deduction]: {
            title: 'Inserimento dati',
            icon: <FileCogOutlineIcon />,
            path: '',
            absolutePath: '',
            matcher: '*',
            absoluteMatcher: '/serni-list-update/:id/data-entry/*',
          },
        },
      },
    };

    const serniListUpdateNavigation = {
      /**
       * A PaperworksNavigationStructure-like object describing the routes of /paperwork
       */
      routes: phasesRoutes,
      /**
       * The path of the Paperwork Details page
       */
      detailsPath,
      /**
       * The the route the browser is currently on
       */
      // route: currentRoute as unknown as SerniListUpdateRoute | undefined,
    };

    return serniListUpdateNavigation;
  }, [detailsPath]);

  return routes;
}
