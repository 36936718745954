import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FileCard } from './FileCard';

interface FileDropzoneProps {
  error?: Error | FieldError | undefined;
  value?: File | undefined;
  onChange?: (value: File | undefined) => void;
  localeText?: Partial<{
    download: string;
    delete: string;
    dropHere: string;
    clickOrDrop: string;
    singleDocumentOnly: string;
  }>;
}

function FileDropzoneComponent(props: FileDropzoneProps) {
  const { error, value, onChange, localeText } = props;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange?.(acceptedFiles[0]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Stack direction="column" gap={1}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        {...getRootProps()}
        sx={{
          border: '2px dashed',
          borderRadius: 1,
          borderColor: error ? 'error.main' : isDragActive ? 'primary.main' : 'grey.500',
          backgroundColor: isDragActive ? 'primary.100' : 'grey.100',
          cursor: 'pointer',
          padding: 2,
          height: 180,
          '&:active': {
            borderColor: 'primary.main',
          },
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{localeText?.dropHere ?? 'Trascina qui il documento...'}</p>
        ) : value ? (
          <FileCard
            file={value}
            onDelete={() => onChange?.(undefined)}
            localeText={localeText}
            sx={{
              maxWidth: 600,
            }}
          />
        ) : (
          <Stack direction="column">
            <Typography variant="h6" textAlign="center">
              {localeText?.clickOrDrop ?? 'Trascina o clicca per caricare un documento'}
            </Typography>
            <Typography textAlign="center">
              {localeText?.singleDocumentOnly ?? 'È possibile caricare un solo documento per volta'}
            </Typography>
          </Stack>
        )}
      </Stack>
      {error ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </Stack>
  );
}

export const FileDropzone = React.memo(FileDropzoneComponent);
