import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Paperwork, PaperworkSchema, PaperworkTemplate } from '../../entities/Paperwork';
import { useReadMovementTypeListQuery } from '../../services/movementType';

function getMovementTypeId(row: Paperwork | PaperworkTemplate): number | undefined {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork).paperworkTemplate.movementType?.id;
  }
  return (row as PaperworkTemplate).movementType?.id;
}

function getMovementTypeName(row: Paperwork | PaperworkTemplate): string | undefined {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork).paperworkTemplate.movementType?.name;
  }
  return (row as PaperworkTemplate).movementType?.name;
}

export function useMovementTypeColumn(): GridSingleSelectColDef<Paperwork | PaperworkTemplate> {
  const { data } = useReadMovementTypeListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'movementType',
      headerName: 'Movimentazione',
      type: 'singleSelect',
      valueOptions,
      groupingValueGetter: (value, row) => getMovementTypeName(row),
      valueGetter: (value, row: Paperwork | PaperworkTemplate) => {
        return getMovementTypeId(row);
      },
    }),
    [valueOptions]
  );
}
