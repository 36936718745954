import React, { useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AlertIcon } from '../../../../components/Icons';
import { CancelButton } from '../../../../components/NavigationPanel/CancelButton';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkDebugMenu } from '../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { DraftOperationEnum } from '../../../../entities/Drafts';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../entities/Paperwork';
import { Stakeholder, StakeholderDraft } from '../../../../entities/Stakeholder';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../../hooks/useNotifications';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { paperworksApi } from '../../../../services/paperwork';
import { useReadPaperworkReviewsQuery } from '../../../../services/paperworkReview';
import { stakeholderListenerMiddleware } from '../../../../store/listeners/stakeholderListenerMiddleware';
import { EditStakeholderDialog } from './EditStakeholderDialog';
import { ImportStakeholdersDialog } from './ImportStakeholderDialog/ImportStakeholderDialog';
import { StakeholderRow } from './StakeholderRow';
import { StakeholdersDatagrid } from './StakeholdersDatagrid';
import { StakeholdersStepLayout, StakeholdersStepProps } from './StakeholdersStepLayout';

function StakeholdersSectionComponent(
  props: StakeholdersStepProps & {
    role: NonNullable<Stakeholder['role']>;
    stepper: React.ReactNode;
    hideDatagrid?: boolean;
    children?: React.ReactNode;
    disableSaveButton?: boolean;
  }
) {
  const {
    leftActions,
    onBack,
    onForward,
    forceEnableForwardButton,
    role,
    hideDatagrid,
    stepper,
    disableSaveButton,
    children,
  } = props;
  const { pushNotification, pushPredefinedNotification } = useNotifications();

  const [selectedStakeholder, setSelectedStakeholder] = useState<StakeholderDraft | undefined>();
  const [editStakeholderOpen, setEditStakeholderOpen] = useState(false);

  const { contextFields, fieldValues } = usePaperworkFields({ subsection: undefined });

  const {
    stakeholders,
    createStakeholder,
    deleteStakeholder,
    saveStakeholders,
    operations,
    operationsCount,
    discardOperations,
  } = useStakeholders();
  const { operationsCount: fieldsOperationsCount, saveValues } = usePaperworkFields();
  const { paperwork } = usePaperwork();
  const readPaperworkReviewsQuery = useReadPaperworkReviewsQuery({ paperworkId: paperwork?.id ?? -1 });

  const handleCancel = useCallback(async () => {
    await discardOperations();
    pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_DISCARD_SUCCESS);
  }, [discardOperations, pushPredefinedNotification]);

  async function handleDelete(draftId: StakeholderDraft['draftId'], id: StakeholderDraft['id']) {
    deleteStakeholder(draftId, id);
    pushNotification('Stakeholder rimosso dalla pratica');
  }

  async function handleStakeholderSelect(draftId: StakeholderDraft['draftId']) {
    setEditStakeholderOpen(true);
    setSelectedStakeholder(stakeholders[draftId]);
  }

  const rows = useMemo(() => {
    if (!fieldValues || !stakeholders) {
      return [];
    }

    // Move all the field object related to each stakeholder into a `fields` property
    // in the row object. The ugly part is that the reference can be through the Stakeholder ID
    // or, if the user has just added the row without saving, through the Stakeholder
    // DraftID.

    const fieldValuesByStakeholderId: Record<string, PaperworkFieldValueDraft[]> = {};
    const fieldValuesByStakeholderDraftId: Record<string, PaperworkFieldValueDraft[]> = {};

    for (const [_, fieldValue] of Object.entries(fieldValues)) {
      if (fieldValue.stakeholderID) {
        fieldValuesByStakeholderId[fieldValue.stakeholderID] = (
          fieldValuesByStakeholderId[fieldValue.stakeholderID] ?? []
        ).concat([fieldValue]);
      } else if (fieldValue.stakeholderDraftID) {
        fieldValuesByStakeholderDraftId[fieldValue.stakeholderDraftID] = (
          fieldValuesByStakeholderDraftId[fieldValue.stakeholderDraftID] ?? []
        ).concat([fieldValue]);
      }
    }

    const rows: StakeholderRow[] = [];

    for (const [_, stakeholder] of Object.entries(stakeholders)) {
      if (operations[stakeholder.draftId] === DraftOperationEnum.Values.Delete) {
        continue;
      }

      if (stakeholder.role !== role) {
        continue;
      }

      const review = readPaperworkReviewsQuery.data?.find(
        (review) => review.paperworkId === paperwork?.id && stakeholder.id === review.stakeholderId
      );

      const row: StakeholderRow = {
        ...stakeholder,
        fields: {},
        review,
      };

      if (stakeholder.id && fieldValuesByStakeholderId[stakeholder.id]) {
        for (const fieldValue of fieldValuesByStakeholderId[stakeholder.id]) {
          row.fields[fieldValue.fieldDatatype] = fieldValue;
        }
      } else if (fieldValuesByStakeholderDraftId[stakeholder.draftId]) {
        for (const fieldValue of fieldValuesByStakeholderDraftId[stakeholder.draftId]) {
          row.fields[fieldValue.fieldDatatype] = fieldValue;
        }
      }

      rows.push(row);
    }

    return rows;
  }, [fieldValues, operations, paperwork?.id, readPaperworkReviewsQuery.data, role, stakeholders]);

  const incompleteRows = useMemo(() => {
    const incompleteRows = new Set<string>();

    for (const row of rows) {
      if (!row.address || !row.country) {
        incompleteRows.add(row.draftId);
      }

      for (const field of contextFields) {
        if (!row.fields[field.datatype]) {
          if (
            field.datatype !== PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO &&
            field.datatype !== PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_BILL_TO_MATCHES_SHIP_TO_OR_ORDER_FROM
          ) {
            incompleteRows.add(row.draftId);
          }
        }
      }
    }

    return incompleteRows;
  }, [contextFields, rows]);

  const totalOperationsCount = operationsCount + fieldsOperationsCount;

  const primaryActions = (
    <>
      <BackButton onClick={onBack} disabled={!onBack} sx={{ mr: 1 }} />
      <ForwardButton
        onClick={onForward}
        disabled={!forceEnableForwardButton && (totalOperationsCount > 0 || rows.length === 0)}
      />
    </>
  );

  return (
    <StakeholdersStepLayout
      stepper={stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={[
            <CancelButton
              key="cancel"
              onConfirm={handleCancel}
              disabled={totalOperationsCount <= 0 || incompleteRows.size > 0 || disableSaveButton}
            />,
            <SaveButton
              key="save"
              onClick={async () => {
                if (operationsCount > 0) {
                  saveStakeholders();
                }

                if (fieldsOperationsCount > 0) {
                  if (operationsCount > 0) {
                    const unsubscribeListener = stakeholderListenerMiddleware.startListening({
                      matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
                      effect: async (action) => {
                        saveValues(action.payload);
                        unsubscribeListener();
                      },
                    });
                  } else {
                    saveValues();
                  }
                }
              }}
              disabled={totalOperationsCount <= 0 || incompleteRows.size > 0 || disableSaveButton}
            />,
          ]}
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={totalOperationsCount}
        />
      }
    >
      {children}
      {hideDatagrid ? (
        <Box flex={1} />
      ) : (
        <>
          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            {incompleteRows.size > 0 ? (
              <Alert icon={<AlertIcon fontSize="inherit" />} severity="warning">
                {
                  'Integrare manualmente i dati delle parti coinvolte non trovate su SC Compliance & End-Use (evidenziati in giallo)'
                }
              </Alert>
            ) : (
              <Alert sx={{ opacity: 0 }} />
            )}
            <Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: 'auto' }}>
              {selectedStakeholder && (
                <EditStakeholderDialog
                  open={editStakeholderOpen}
                  onClose={() => setEditStakeholderOpen(false)}
                  stakeholder={selectedStakeholder}
                />
              )}
              <ImportStakeholdersDialog
                role={role}
                onImportStakeholders={(importedStakeholders) => {
                  for (const stakeholder of importedStakeholders) {
                    createStakeholder(stakeholder);
                  }
                }}
              />
            </Stack>
          </Stack>
          <DataGridWrapper>
            <StakeholdersDatagrid
              rows={rows}
              onDelete={handleDelete}
              onSelectStakeholder={handleStakeholderSelect}
              incompleteRows={incompleteRows}
            />
          </DataGridWrapper>
        </>
      )}
    </StakeholdersStepLayout>
  );
}

export const StakeholdersSection = React.memo(StakeholdersSectionComponent);
