import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MaterialDraft } from '../entities/Material';
import { usePatchMaterialListMutation } from '../services/material';
import { discardOperations, selectMaterialsState, updateMaterial, setSavingState } from '../store/materialsStateSlice';

export function useMaterials() {
  const dispatch = useDispatch();

  const [_, patchMaterialListRequest] = usePatchMaterialListMutation();

  const { materials, operations } = useSelector(selectMaterialsState);

  return useMemo(() => {
    return {
      /**
       * Save the state of all materials for the current paperwork.
       */
      saveMaterials: async () => {
        /**
         * Dispatch the saving state
         * Do not save directly but "enqueue" the saving action, so that redux does that after
         * applying all the operations
         */
        dispatch(setSavingState({ saving: true }));
      },
      /**
       * Update a single material. All changes are kept on redux until `saveMaterials` is called.
       */
      updateMaterial: (currentDraftId: string | undefined, material: MaterialDraft | undefined) =>
        dispatch(
          updateMaterial({
            currentDraftId: currentDraftId,
            materialDraft: material,
          })
        ),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchMaterialListRequest,
      discardOperations: () => dispatch(discardOperations()),
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      materials: materials,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: Object.values(operations).filter((value) => value !== undefined).length,
    };
  }, [patchMaterialListRequest, materials, operations, dispatch]);
}
