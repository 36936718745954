import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { FilterOperator, PagedRequestParams } from '@top-solution/microtecnica-utils';
import { StakeholderNameAutocompletete } from '../../../../../components/Autocompletes/StakeholderNameAutocomplete';
import { STAKEHOLDER_ROLE, StakeholderDraft, addDraftIdToStakeholder } from '../../../../../entities/Stakeholder';
import {
  useLazySearchCustomersQuery,
  useLazySearchEndUsersQuery,
  useReadVendorsQuery,
} from '../../../../../services/vendorBuyerEndUserApi';

interface SearchTabProps {
  onNext: (searchResults: StakeholderDraft[], manualStakeholders: StakeholderDraft[]) => void;
  onCancel: () => void;
  role: STAKEHOLDER_ROLE;
}

function SearchTabComponent(props: SearchTabProps) {
  const { onNext, onCancel, role } = props;

  const { control, handleSubmit, formState } = useForm<{ names: string[] }>({
    defaultValues: { names: [] },
    resolver: zodResolver(z.object({ names: z.string().array().min(1) })),
  });

  const readVendorsQuery = useReadVendorsQuery();
  const [searchCustomersQuery, searchCustomersQueryRequest] = useLazySearchCustomersQuery();
  const [searchEndUsersQuery, searchEndUsersQueryRequest] = useLazySearchEndUsersQuery();

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (data) => {
    const stakeholderDrafts: StakeholderDraft[] = [];
    const notFountStakeholders: string[] = [];

    for (const name of data.names) {
      let atLeastOneResultFound = false;
      if (readVendorsQuery.data) {
        for (const vendor of readVendorsQuery.data) {
          if (vendor.name.indexOf(name) >= 0) {
            atLeastOneResultFound = true;
            stakeholderDrafts.push(
              addDraftIdToStakeholder({
                name: vendor.name,
                address: '',
                country: vendor.country,
                imported: true,
                role,
                ptId: vendor.id,
              })
            );
          }
        }
      }

      const params: PagedRequestParams = {
        offset: 0,
        limit: 10,
        sort: ['name'],
        filters: [{ field: 'name', operator: FilterOperator.like, value: `${name}%` }],
      };
      //     searchCustomersQuery(params);
      //     searchEndUsersQuery(params);

      const searchCustomers = await searchCustomersQuery(params).unwrap();
      const customersDrafts: StakeholderDraft[] = searchCustomers.data.map(({ id, ...ptCustomer }) =>
        addDraftIdToStakeholder({
          ...ptCustomer,
          role: role,
          imported: true,
          ptId: `${id}`,
        })
      );
      stakeholderDrafts.push(...customersDrafts);

      const searchEndUsers = await searchEndUsersQuery(params).unwrap();
      const endUsers: StakeholderDraft[] = searchEndUsers.data.map(({ id, ...ptEndUsers }) =>
        addDraftIdToStakeholder({
          ...ptEndUsers,
          role: role,
          imported: true,
          ptId: `${id}`,
        })
      );
      stakeholderDrafts.push(...endUsers);

      if (!atLeastOneResultFound && customersDrafts.length === 0 && endUsers.length === 0) {
        notFountStakeholders.push(name);
      }
    }

    if (stakeholderDrafts.length > 0) {
      onNext(stakeholderDrafts, []);
    } else {
      onNext(
        [],
        data.names.map((name) => {
          const stakeholder = {
            name: name,
            country: '',
            address: '',
            imported: false,
          };

          return addDraftIdToStakeholder(stakeholder);
        })
      );
    }
  };

  return (
    <Stack direction="column" component="form" onSubmit={handleSubmit(onSubmit)} onReset={onCancel} flex={1}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, minHeight: 0, flex: 1 }}>
        <Stack direction="column" alignItems="stretch" gap={4} paddingTop={1}>
          <Box>
            <Alert
              severity="info"
              sx={{
                '& p': {
                  marginY: 0.5,
                },
              }}
            >
              <AlertTitle>{'Importazione e inserimento parti coinvolte'}</AlertTitle>
              <p>
                {
                  'Inserire nel campo “Parti coinvolte” tutti i clienti / fornitori in base al relativo ruolo all’interno della transazione da includere nella richiesta di autorizzazione e premere Invio.'
                }
              </p>
              <p>
                {
                  'Selezionare “Cerca parti coinvolte”. La schermata successiva restituirà tutti i clienti / fornitori presenti nell’anagrafica in SAP corrispondenti alla tua ricerca, seleziona quindi le righe di tuo interesse.'
                }
              </p>
              {'Le informazioni relative all’indirizzo e al paese si compileranno in automatico.'}
              <p>
                {
                  'Qualora non trovassi un cliente / fornitore inseriti nella ricerca, potrai aggiungerli manualmente ed integrare manualmente le informazioni necessarie per la presentazione dell’istanza.'
                }
              </p>
            </Alert>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Controller
              control={control}
              name="names"
              render={({ field: { ...field } }) => (
                <StakeholderNameAutocompletete
                  {...field}
                  placeholder="Inserisci una ragione sociale e premi Invio, o incolla una lista di ragioni sociali"
                />
              )}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {'Annulla'}
        </Button>
        <LoadingButton
          type="submit"
          loading={searchCustomersQueryRequest.isFetching || searchEndUsersQueryRequest.isFetching}
          variant="contained"
          disabled={!formState.isValid}
        >
          {'Cerca parti coinvolte'}
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
}

export const SearchTab = React.memo(SearchTabComponent);
