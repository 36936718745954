import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { DataEntryPages, PaperworkPhases, PreparationSigningPages } from '../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../hooks/usePaperwork/usePaperworkNavigation';
import PaperworkContractualTermsPage from './DataEntry/ContractualTerms/PaperworkContractualTermsPage';
import PaperworkDocumentsPage from './DataEntry/Documents/PaperworkAttachmentsPage';
import PaperworkMaterialsPage from './DataEntry/Materials/PaperworkMaterialsPage';
import PaperworkStakeholdersPage from './DataEntry/Stakeholders/PaperworkStakeholdersPage';
import PaperworkCreatePage from './PaperworkCreatePage/PaperworkCreatePage';
import { PaperworkDebug } from './PaperworkDetail/PaperworkDebug';
import { PaperworkDetailPage } from './PaperworkDetail/PaperworkDetailPage';
import { PaperworkSigningPageWrapper } from './PreparationSigning/PaperworkSigningPage';

export default function PaperworkDetailsRouter(): JSX.Element | null {
  const { contexts } = usePaperwork();
  const { routes } = usePaperworkNavigation();

  const dataEntryPhasePages = routes[PaperworkPhases.DataEntry].pages;
  const preparationSigningPhasePages = routes[PaperworkPhases.PreparationSigning].pages;

  // Prevent rendering stuff while the templates (and contexts) are not loaded yet
  // <Layout> renders the NavBar to make the transition between pages more smooth
  if (!contexts) {
    return <Layout maxWidth={false} sx={{ padding: 0 }} />;
  }

  return (
    <Routes>
      <Route index element={<PaperworkDetailPage />} />
      <Route path={routes[PaperworkPhases.Configuration].matcher} element={<PaperworkCreatePage />} />
      <Route path={routes[PaperworkPhases.DataEntry].matcher}>
        <Route path={dataEntryPhasePages[DataEntryPages.Materials].matcher} element={<PaperworkMaterialsPage />} />
        <Route
          path={dataEntryPhasePages[DataEntryPages.Stakeholders].matcher}
          element={<PaperworkStakeholdersPage />}
        />
        <Route path={dataEntryPhasePages[DataEntryPages.Attachments].matcher} element={<PaperworkDocumentsPage />} />
        <Route
          path={dataEntryPhasePages[DataEntryPages.ContractualTerms].matcher}
          element={<PaperworkContractualTermsPage />}
        />
      </Route>
      <Route path={routes[PaperworkPhases.PreparationSigning].matcher}>
        <Route
          path={preparationSigningPhasePages[PreparationSigningPages.Signing].matcher}
          element={<PaperworkSigningPageWrapper />}
        />
      </Route>
      <Route path="debug" element={<PaperworkDebug />} />
      <Route path="*" element={<Navigate to={'..'} />} />
    </Routes>
  );
}
