import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { SERNI_LIST_UPDATE_STATUS, SerniListUpdate } from '../../entities/SerniListUpdate';
import { useReadPaperworkStatusListQuery } from '../../services/paperwork';
// import { useReadSerniListUpdateStatusListQuery } from '../../services/serniListUpdate';

export function SerniListUpdateStatusChip(props: {
  status: SERNI_LIST_UPDATE_STATUS | undefined;
}): React.ReactElement | null {
  const { status } = props;
  const { data } = useReadPaperworkStatusListQuery();

  const style = useMemo(() => {
    switch (status) {
      case SERNI_LIST_UPDATE_STATUS.APPROVED:
        return { bgcolor: 'common.white', color: 'success.main', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.CONDITIONAL_APPROVED:
        return { bgcolor: 'common.white', color: 'success.main', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.DRAFT:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.REJECTED:
        return { bgcolor: 'common.white', color: 'error.main', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.RETURNED:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.TAKEN_CHARGE:
        return { bgcolor: 'common.white', color: 'warning.main', border: 1 };
      case SERNI_LIST_UPDATE_STATUS.WAITING:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      default:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
    }
  }, [status]);

  if (!status) {
    return null;
  }

  return (
    <Chip
      label={data?.map[status].name}
      size="small"
      sx={{
        textTransform: 'capitalize',
        ...style,
      }}
    />
  );
}

export function useSerniListUpdateStatusColumn(): GridSingleSelectColDef<SerniListUpdate, SERNI_LIST_UPDATE_STATUS> {
  const { data } = useReadPaperworkStatusListQuery();

  const valueOptions = useMemo(() => data?.list.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'status',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: SerniListUpdate) => {
        return row.status.id;
      },
      renderCell: ({ value }: GridRenderCellParams<SerniListUpdate, SERNI_LIST_UPDATE_STATUS>) => (
        <SerniListUpdateStatusChip status={value!} />
      ),
    }),
    [valueOptions]
  );
}
