import React, { useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import {
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid-premium';
import { DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import CurrencySelect from '../../../../../components/Form/CurrencySelect/CurrencySelect';
import { AddIcon, DeleteIcon, InformationOutlineIcon } from '../../../../../components/Icons';
import { ReviewButton } from '../../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../../components/review/ReviewChip';
import { ReviewGuard } from '../../../../../components/review/ReviewGuard';
import { ReviewPopover } from '../../../../../components/review/ReviewPopover';
import { usePaperworkReviews } from '../../../../../components/review/usePaperworkReviews';
import { DraftOperationEnum } from '../../../../../entities/Drafts';
import {
  PAPERWORK_FIELD_DATATYPE,
  addDraftIdToPaperworkFieldValue,
  getPaperworkFieldValueDraftId,
} from '../../../../../entities/Paperwork';
import { usePaperwork } from '../../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { Currency } from '../../../../Currency/currency';
import { BillingYear, BillingYearValue, BillingYearValueSchema } from './BillingYear';
import BillingYearAddDialog from './BillingYearAddDialog';

export default function BillingYearComponent() {
  const [openAdd, setOpenAdd] = useState(false);
  const { data: currencies } = useReadCurrencyListQuery();
  const { fieldValues, updateValue, deleteValue, operations } = usePaperworkFields();
  const { paperwork } = usePaperwork();

  const { route } = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const values = useMemo(() => {
    return BillingYearValueSchema.array().parse(
      Object.values(fieldValues)
        .filter(
          (v) =>
            v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_BILLING_YEARS &&
            operations[v.draftId] !== DraftOperationEnum.Values.Delete
        )
        .map((v) => ({ id: v.id, progIndex: v.progIndex, ...JSON.parse(v.value!) })) || []
    );
  }, [fieldValues, operations]);

  const currencyValue = useMemo(() => {
    const value = Object.values(fieldValues).find(
      (v) => v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY
    )?.value;
    return Number(value);
  }, [fieldValues]);

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const maxProgIndex = useMemo(() => {
    if (!values || values.length === 0) return -1;
    return Math.max(...values.map((v) => v.progIndex));
  }, [values]);

  const handleAddBillingValues = useCallback(
    (v: BillingYear) => {
      updateValue(
        addDraftIdToPaperworkFieldValue({
          fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_BILLING_YEARS,
          progIndex: maxProgIndex + 1,
          value: JSON.stringify({ ...v }),
        })
      );
      setOpenAdd(false);
    },
    [maxProgIndex, updateValue]
  );

  const handleDeleteBillingValue = useCallback(
    (v: BillingYearValue) => {
      deleteValue(
        getPaperworkFieldValueDraftId({ ...v, fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_BILLING_YEARS }),
        v.id
      );
    },
    [deleteValue]
  );

  const currencyFormatter = useMemo(
    () =>
      currenciesById && currencyValue
        ? new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: currenciesById.get(currencyValue!)?.code,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : null,
    [currenciesById, currencyValue]
  );

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'billingYear',
        headerName: 'Anno di Riferimento',
        flex: 1,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'supplierValue',
        headerName: 'Valore dal fornitore',
        width: 250,
        type: 'number',
        groupable: false,
        valueFormatter: (value) => {
          if (!value) {
            return value;
          }
          return currencyFormatter?.format(value);
        },
      },
      {
        field: 'customerValue',
        headerName: 'Valore verso cliente',
        width: 250,
        type: 'number',
        groupable: false,
        valueFormatter: (value) => {
          if (!value) {
            return value;
          }
          return currencyFormatter?.format(value);
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        hideable: false,
        width: 90,
        renderHeader: () => (
          <Tooltip
            title={currencyValue ? 'Aggiungi valuta' : 'Inserisci una valuta per aggiungere gli anni di fatturazione'}
            placement="left"
          >
            <div>
              <IconButton color="primary" onClick={() => setOpenAdd(true)} disabled={!currencyValue}>
                <AddIcon />
              </IconButton>
            </div>
          </Tooltip>
        ),
        getActions: ({ id, row }) => {
          if (id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
            return [];
          }
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              key={'delete'}
              label="Delete"
              onClick={() => handleDeleteBillingValue(row)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [currencyFormatter, currencyValue, handleDeleteBillingValue]
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          flexWrap="wrap"
          marginBottom={1}
          justifyContent={'space-between'}
        >
          <Alert icon={<InformationOutlineIcon fontSize="inherit" />} severity="info">
            {'Inserire i dati riferiti alla pratica'}
          </Alert>
          <CurrencySelect
            value={currencyValue || ''}
            onChange={(e) => {
              updateValue(
                addDraftIdToPaperworkFieldValue({
                  fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY,
                  progIndex: 0,
                  value: e.target.value.toString(),
                  id: currencyValue,
                })
              );
            }}
            disabled={values.length > 0}
          />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <ReviewGuard>
          <Stack direction="row">
            <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
              <ReviewChip review={review} size="medium" />
              <ReviewGuard editReview>
                <ReviewPopover review={review}>
                  {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
                </ReviewPopover>
              </ReviewGuard>
            </Stack>
          </Stack>
        </ReviewGuard>
      </Grid>
      <Grid xs={12}>
        <DataGridWrapper maxHeight={500}>
          <DataGrid
            getRowId={(r) => r.billingYear}
            density="standard"
            columns={columns}
            disableRowGrouping
            rows={values}
            initialState={{
              aggregation: {
                model: {
                  supplierValue: 'sum',
                  customerValue: 'sum',
                },
              },
            }}
            hideFooter
            localeText={{ noRowsLabel: 'Inserire almeno un anno di fatturazione' }}
          />
        </DataGridWrapper>
      </Grid>
      {currenciesById && currencyValue && (
        <BillingYearAddDialog
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onSubmit={handleAddBillingValues}
          currentCurrencySymbol={currenciesById.get(currencyValue)!.symbol}
          disabledYears={values.map((r) => r.billingYear)}
        />
      )}
    </Grid>
  );
}
