import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export function ImportStakeholdersNoRows() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" height="100%">
      <Box>{'Non è stato trovato alcuna parte coinvolta corrispondente ai criteri di ricerca'}</Box>
    </Stack>
  );
}
