import React from 'react';
import { Layout } from '../../../../components/Layout';
import { NavigationPanelProps } from '../../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../../components/PageTitle';
import { DataEntryPages, PaperworkPhases } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { paperworkRoutesConfiguration } from '../../../../hooks/usePaperwork/paperworkRouteConfiguration';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { PaperworkInfoPanel } from '../../PaperworkInfoPanel';

export type StakeholdersStepProps = NavigationPanelProps & {
  onBack?: () => void;
  onForward?: () => void;
  forceEnableForwardButton?: boolean;
  stepper: React.ReactNode;
  footer?: React.ReactNode;
};

function StakeholdersStepLayoutComponent(props: StakeholdersStepProps) {
  const { children, footer, stepper } = props;
  const { paperwork } = usePaperwork();
  const pageTitle = paperwork
    ? paperworkRoutesConfiguration[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].title(paperwork)
    : '';

  return (
    <Layout
      title={pageTitle}
      header={<PageTitle pageTitle={pageTitle} rightItems={<PaperworkInfoPanel />} />}
      footer={footer}
    >
      {stepper}
      {children}
    </Layout>
  );
}

export const StakeholdersStepLayout = React.memo(StakeholdersStepLayoutComponent);
