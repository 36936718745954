import React from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../entities/User';
import { useReadCountryListQuery } from '../../services/countries';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import CountryListGrid from './CountryListGrid';
import { countrySection } from './sections';

const PAGE_TITLE = countrySection.title;

export default function CountryList(): React.ReactElement {
  const { isLoading, data, error } = useReadCountryListQuery();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout title={PAGE_TITLE} maxWidth="lg" header={<PageTitle pageTitle={PAGE_TITLE} />} error={error}>
        <DataGridWrapper>
          <CountryListGrid enabledCountryIdList={data || []} loading={isLoading} />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
