import React from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../entities/User';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import EquipmentListGrid from './EquipmentListGrid';
import { equipmentSection } from './sections';

const PAGE_TITLE = equipmentSection.title;

export default function EquipmentList(): React.ReactElement {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout title={PAGE_TITLE} maxWidth="lg" header={<PageTitle pageTitle={PAGE_TITLE} />}>
        <DataGridWrapper>
          <EquipmentListGrid />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
