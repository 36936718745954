import { createListenerMiddleware } from '@reduxjs/toolkit';

import { DraftOperationEnum } from '../../entities/Drafts';
import { PaperworkFieldValueDraft, PatchPaperworkValueListRequest } from '../../entities/Paperwork';
import { getStakeholderDraftId } from '../../entities/Stakeholder';
import { paperworksApi } from '../../services/paperwork';
import { PaperworkFieldValuesStateSlice, replaceFieldValues, setSavingState } from '../paperworkFieldValuesStateSlice';

export const paperworkFieldValuesMiddleware = createListenerMiddleware();

paperworkFieldValuesMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperworkValues.matchFulfilled,
  effect: async (action, listenerApi) => {
    const actionPaperworkId = action.meta.arg.originalArgs.paperworkId;

    listenerApi.dispatch(
      replaceFieldValues({
        paperworkId: actionPaperworkId,
        fieldValues: action.payload,
      })
    );
  },
});

/**
 * Reset the saving state to `false` after saving
 */
paperworkFieldValuesMiddleware.startListening({
  matcher: paperworksApi.endpoints.patchPaperworkValues.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setSavingState({ saving: false }));
  },
});

/**
 * Save the values when the setSavingState action is dispatched,
 * setting `saving` to true
 */
paperworkFieldValuesMiddleware.startListening({
  actionCreator: setSavingState,
  effect: async (action, listenerApi) => {
    if (action.payload.saving === true) {
      const state = listenerApi.getState() as { paperworkFieldValuesState: PaperworkFieldValuesStateSlice };
      if (state.paperworkFieldValuesState.paperworkId !== undefined) {
        const patchPayload: PatchPaperworkValueListRequest = {
          paperworkId: state.paperworkFieldValuesState.paperworkId,
          create: [],
          update: [],
          delete: [],
        };

        const updatedPaperwork = action.payload.updatedPaperwork;

        /**
         * Map stakeholder IDs by draftIDs
         */
        const stakeholdersIDsByDraftID: Record<string, number> = {};
        if (updatedPaperwork?.stakeholderList) {
          for (const stakeholder of updatedPaperwork.stakeholderList) {
            const draftId = getStakeholderDraftId(stakeholder);
            stakeholdersIDsByDraftID[draftId] = stakeholder.id;
          }
        }

        const { fieldValues, operations } = state.paperworkFieldValuesState;

        for (const [draftId, _value] of Object.entries<PaperworkFieldValueDraft>(fieldValues)) {
          const operation = operations[draftId];

          if (!operation) {
            continue;
          }

          const value = { ..._value };

          /**
           * Replace stakeholderDraftIDs with Stakeholder IDs
           */
          if (value.stakeholderDraftID) {
            if (stakeholdersIDsByDraftID[value.stakeholderDraftID]) {
              value.stakeholderID = stakeholdersIDsByDraftID[value.stakeholderDraftID];
            }
          }

          if (operation === DraftOperationEnum.Values.Delete) {
            if (value.id) {
              patchPayload.delete.push({ id: value.id });
            }
          } else if (value.value !== undefined) {
            if (operation === DraftOperationEnum.Values.Create) {
              patchPayload.create.push({
                ...value,
                value: value.value,
              });
            } else if (operation === DraftOperationEnum.Values.Update) {
              if (value.id) {
                patchPayload.update.push({
                  ...value,
                  id: value.id,
                  value: value.value,
                });
              } else {
                // eslint-disable-next-line no-console
                console.warn(`Missing field id of ${value.fieldDatatype}`);
              }
            }
          }
        }

        listenerApi.dispatch(paperworksApi.endpoints.patchPaperworkValues.initiate(patchPayload));
      }
    }
  },
});
