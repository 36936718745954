import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { TrayArrowDownIcon } from '../../../../components/Icons';
import { Attachment } from '../../../../entities/Attachment';

interface DownloadButtonProps {
  attachment: Pick<Attachment, 'attachment'>;
}

const downloadLabel = 'Scarica documento';

function DownloadButtonComponent(props: DownloadButtonProps) {
  const { attachment } = props;
  return (
    <Tooltip title={downloadLabel}>
      <IconButton
        component="a"
        edge="end"
        size="small"
        aria-label={downloadLabel}
        download={attachment.attachment.name}
        href={`/${attachment.attachment.path}`}
      >
        <TrayArrowDownIcon />
      </IconButton>
    </Tooltip>
  );
}

export const DownloadButton = React.memo(DownloadButtonComponent);
