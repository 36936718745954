import { MovementType, MovementTypeSchema } from '../entities/MovementType';
import { api, TAG_TYPES } from './baseApi';

const url = 'movement-types';

const movementTypeAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    readMovementTypeList: builder.query<Array<MovementType>, void>({
      query: () => ({ url }),
      transformResponse: (data) => MovementTypeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.MOVEMENT_TYPE, id: 'LIST' }],
    }),
  }),
});

export const { useReadMovementTypeListQuery } = movementTypeAPi;
