import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Paperwork, PaperworkSchema, PaperworkTemplate } from '../../entities/Paperwork';
import { useReadSaleTypeListQuery } from '../../services/saleType';

function getSaleTypeId(row: Paperwork | PaperworkTemplate): number {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork)?.paperworkTemplate?.saleType?.id;
  }
  return (row as PaperworkTemplate)?.saleType?.id;
}

function getSaleTypeName(row: Paperwork | PaperworkTemplate): string {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork)?.paperworkTemplate?.saleType?.name;
  }
  return (row as PaperworkTemplate)?.saleType?.name;
}

export function useSaleTypeColumn(): GridSingleSelectColDef<Paperwork | PaperworkTemplate> {
  const { data } = useReadSaleTypeListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'saleType',
      headerName: 'Tipologia di vendita',
      type: 'singleSelect',
      valueOptions,
      groupingValueGetter: (value, row) => getSaleTypeName(row),
      valueGetter: (value, row) => getSaleTypeId(row),
    }),
    [valueOptions]
  );
}
