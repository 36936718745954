import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

type NotificationsItem = {
  id: string;
  message: string;
  closing: boolean;
};

type notificationsSlice = NotificationsItem[];

const initialState: notificationsSlice = [];

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, action: PayloadAction<{ id: string; message: string }>) => {
      const lastNotification = state[state.length - 1];
      if (lastNotification && !lastNotification.closing && lastNotification.message === action.payload.message) {
        return;
      }

      state.push({
        id: action.payload.id,
        message: action.payload.message,
        closing: false,
      });
    },
    closeNotification: (state, action: PayloadAction<{ id: string }>) => {
      const index = state.findIndex((notification) => notification.id === action.payload.id);
      if (index !== undefined && state[index]) {
        state[index].closing = true;
      }
    },
    removeNotification: (state, action: PayloadAction<{ index: number }>) => {
      state.splice(action.payload.index, 1);
    },
  },
});

export const { pushNotification, closeNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications ?? [];

export default notificationsSlice.reducer;
