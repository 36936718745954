import React, { useState } from 'react';
import Alert, { alertClasses } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';

// TODO: move into frontend-libraries

interface CheckConfirmDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dialogTitleLabel?: string;
  dialogDescriptionLabel?: string;
  confirmTextLabel?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

function CheckConfirmDialogComponent(props: CheckConfirmDialogProps) {
  const {
    open,
    onClose,
    onConfirm,
    dialogTitleLabel,
    dialogDescriptionLabel,
    confirmTextLabel,
    confirmButtonLabel,
    cancelButtonLabel,
    ...dialogProps
  } = props;
  const [confirmed, setConfirmed] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{
        onExited: () => setConfirmed(false),
      }}
      {...dialogProps}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitleLabel}</DialogTitle>
      <DialogContent>
        {dialogDescriptionLabel && (
          <DialogContentText id="alert-dialog-description">{dialogDescriptionLabel}</DialogContentText>
        )}
        <Alert
          severity="warning"
          sx={{
            marginTop: 2,
            [`.${alertClasses.icon}`]: {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox color="secondary" checked={confirmed} onChange={(_, checked) => setConfirmed(checked)} />
            }
            label={confirmTextLabel ?? 'Annulla'}
          />
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose} autoFocus>
          {cancelButtonLabel}
        </Button>
        <Button
          color="warning"
          disabled={!confirmed}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmButtonLabel ?? 'Conferma'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const CheckConfirmDialog = React.memo(CheckConfirmDialogComponent);
