import React, { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Attachment } from '../../../entities/Attachment';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { AddMailAttachmentDialog } from './AddMailAttachmentDialog';
import { MailAttachmentCard } from './MailAttachmentCard';

interface MailAttachmentsProps {
  signedPaperworkAttachment?: Attachment;
  mailAttachmentContextId: number;
  mailAttachments: Set<number>;
  setMailAttachments: (attachments: Set<number>) => void;
}
function MailAttachmentsComponent(props: MailAttachmentsProps) {
  const { signedPaperworkAttachment, mailAttachments, setMailAttachments, mailAttachmentContextId } = props;

  const { paperwork } = usePaperwork();

  const [attachmentList, mailAttachmentList] = useMemo(() => {
    const attachmentList: Attachment[] = [];
    const mailAttachmentList: Attachment[] = [];
    for (const attachment of paperwork?.attachmentList ?? []) {
      if (attachment.attachment.id !== signedPaperworkAttachment?.attachment.id) {
        if (attachment.savedInContextId === mailAttachmentContextId) {
          mailAttachmentList.push(attachment);
        } else {
          attachmentList.push(attachment);
        }
      }
    }
    return [attachmentList, mailAttachmentList];
  }, [mailAttachmentContextId, paperwork?.attachmentList, signedPaperworkAttachment?.attachment.id]);
  if (!attachmentList) {
    return null;
  }

  return (
    <Stack direction="column" marginY={1} gap={2} width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Alert severity="info">
          {'Seleziona i documenti inseriti nella pratica che si desidera allegare all’email/PEC'}
        </Alert>
      </Stack>
      <Stack direction="row" gap={1.5} flexWrap="wrap">
        {attachmentList.map((attachment) => {
          if (!attachment) {
            return null;
          }
          return (
            <MailAttachmentCard
              key={attachment.id}
              mailAttachmentContextId={mailAttachmentContextId}
              attachment={attachment}
              checked={mailAttachments.has(attachment.attachment.id)}
              onCheckChange={(checked) => {
                const newSet = new Set(mailAttachments);
                if (checked) {
                  newSet.add(attachment.attachment.id);
                } else {
                  newSet.delete(attachment.attachment.id);
                }
                setMailAttachments(newSet);
              }}
            />
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginTop={2} alignItems="center">
        <Alert severity="info">{'Inserisci altri allegati da aggiungere all’email/PEC'}</Alert>
        <AddMailAttachmentDialog mailAttachmentContextId={mailAttachmentContextId} />
      </Stack>
      <Stack direction="row" gap={1.5} flexWrap="wrap">
        {mailAttachmentList.map((attachment) => {
          if (!attachment) {
            return null;
          }
          return (
            <MailAttachmentCard
              key={attachment.id}
              mailAttachmentContextId={mailAttachmentContextId}
              attachment={attachment}
              checked={true}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export const MailAttachments = React.memo(MailAttachmentsComponent);
