import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadCurrencyListQuery } from '../../../services/currency';

function CurrencySelect(props: TextFieldProps, ref: React.Ref<HTMLDivElement>) {
  const { ...otherProps } = props;
  const { data, isLoading, error } = useReadCurrencyListQuery();

  return (
    <TextField
      label={'Valuta'}
      variant="outlined"
      select
      disabled={isLoading || !!error}
      {...otherProps}
      ref={ref}
      sx={{ '& .MuiInputBase-input': { display: 'flex', justifyContent: 'start' }, minWidth: '300px' }}
    >
      {data?.map((currency) => (
        <MenuItem key={currency.id} value={currency.id} sx={{ display: 'flex', justifyContent: 'start' }}>
          {`${currency.name} ${currency.symbol}`}
        </MenuItem>
      )) || <MenuItem value={undefined}></MenuItem>}
    </TextField>
  );
}

export default React.forwardRef(CurrencySelect);
