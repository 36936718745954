import { z } from 'zod';

export const AttachmentSchema = z.object({
  id: z.number().optional(),
  attachment: z.object({
    id: z.number(),
    name: z.string(),
    path: z.string(),
    uploadDate: z.string(),
    username: z.string(),
  }),
  savedInContextId: z.number(),
  stakeholderIdList: z.number().array().optional(),
});
const PatchAttachmentListRequestSchema = z.object({
  paperworkId: z.number(),
  add: AttachmentSchema.pick({ savedInContextId: true, stakeholderIdList: true })
    .extend({ attachmentId: AttachmentSchema.shape.id })
    .array(),
  delete: z.object({ id: AttachmentSchema.shape.id }).array(),
});
const AttachmentDraftSchema = AttachmentSchema.extend({
  draftId: z.string(),
});

export const AttachmentCreateResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  uploadDate: z.string(),
  username: z.string(),
});

export type Attachment = z.infer<typeof AttachmentSchema>;
export type PatchAttachmentListRequest = z.infer<typeof PatchAttachmentListRequestSchema>;
export type AttachmentDraft = z.infer<typeof AttachmentDraftSchema>;
export type AttachmentCreateResponse = z.infer<typeof AttachmentCreateResponseSchema>;

export function getAttachmentDraftId(attachment: Partial<Attachment>) {
  const { attachment: attachmentFile, savedInContextId, stakeholderIdList } = attachment;

  return ['draft', attachmentFile?.id, savedInContextId, stakeholderIdList]
    .filter(Boolean)
    .map((s) => `${s}`.toLocaleLowerCase().replace(/[\W_]+/g, '-'))
    .join('---');
}

export function addDraftIdToAttachment(
  fieldValue: Omit<AttachmentDraft, 'draftId'> & { draftId?: AttachmentDraft['draftId'] | undefined }
): AttachmentDraft {
  return {
    ...fieldValue,
    draftId: fieldValue.draftId ?? getAttachmentDraftId(fieldValue),
  };
}
