import React, { useId } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

export function AttachmentsDigitallySignedRadioButton(props: FieldComponentProps) {
  const { field, fieldLinks } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field, fieldLinks);

  const id = useId();

  return (
    <FormControl>
      <FormLabel id={id}>{field.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={id}
        name={field.datatype}
        value={fieldValue?.value}
        onChange={(_, value) => {
          updateValue(value);
        }}
        sx={{ gap: 3, paddingX: 2, paddingY: 1 }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Si" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
}
