import React from 'react';
import {
  GridFooterContainer,
  GridFooterContainerProps,
  GridToolbarFilterButton,
  useGridRootProps,
} from '@mui/x-data-grid-premium';

function ImportStakeholdersFooterComponent(props: GridFooterContainerProps): JSX.Element {
  const rootProps = useGridRootProps();

  const paginationElement = rootProps.pagination && !rootProps.hideFooterPagination && rootProps.slots.pagination && (
    <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
  );

  return (
    <GridFooterContainer {...props}>
      <GridToolbarFilterButton />
      {paginationElement}
    </GridFooterContainer>
  );
}
export const ImportMaterialsFooter = React.memo(ImportStakeholdersFooterComponent);
