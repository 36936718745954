import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  PAPERWORK_FIELD_DATATYPE,
  Paperwork,
  PaperworkContext,
  PaperworkFieldValue,
  PaperworkFieldValueDraft,
} from '../../entities/Paperwork';
import { usePatchPaperworkValuesMutation, useReadPaperworkValuesQuery } from '../../services/paperwork';
import {
  deleteValue,
  discardOperations,
  selectPaperworkFieldValuesState,
  setSavingState,
  updateValue,
} from '../../store/paperworkFieldValuesStateSlice';
import { usePaperwork } from './usePaperwork';
import { findStrictContext, usePaperworkNavigation } from './usePaperworkNavigation';

export interface ContextField {
  id: number;
  datatype: PAPERWORK_FIELD_DATATYPE;
  label: string;
  linkedTo: string;
  isRequired: boolean;
}

export function usePaperworkFields(overrideContext?: Pick<PaperworkContext, 'section' | 'subsection'>) {
  const dispatch = useDispatch();
  const { contexts, paperwork } = usePaperwork();
  const { navigationContext } = usePaperworkNavigation();

  // Just run the query - paperworkFieldValuesMiddleware will update the redux store on fulfillment
  useReadPaperworkValuesQuery({ paperworkId: paperwork?.id ?? 1 }, { skip: !paperwork });

  const [_, patchPaperworkValuesRequest] = usePatchPaperworkValuesMutation();

  const contextFields: ContextField[] = useMemo(() => {
    const contextFields =
      findStrictContext(
        contexts,
        navigationContext.phase,
        navigationContext.page,
        overrideContext?.section ?? navigationContext.section,
        overrideContext?.subsection ?? navigationContext.subsection
      )?.fieldList ?? [];

    return contextFields;
  }, [
    contexts,
    navigationContext.page,
    navigationContext.phase,
    navigationContext.section,
    navigationContext.subsection,
    overrideContext,
  ]);

  const { fieldValues, originalFieldValues, operations } = useSelector(selectPaperworkFieldValuesState);

  return useMemo(() => {
    return {
      /**
       * All the available fields for the PaperworkContext matching the current
       * navigation route.
       */
      contextFields,
      /**
       * Save the state of all values for this specific context. The values are
       * compared with contextValues to determine which ones need to be created,
       * updated, or deleted.
       * @param updatedPaperwork if defined, is used to replace draftIDs of referenced entities
       * (like stakeholders) in values with their IDs, so values are going to be saved on DB
       * with the correct reference
       */
      saveValues: async (updatedPaperwork?: Paperwork) => {
        if (paperwork) {
          dispatch(setSavingState({ saving: true, updatedPaperwork }));
        }
      },
      /**
       * Update (or create if does not exist yet) a single value. All changes are kept on redux until `saveValues` is called.
       */
      updateValue: (fieldValueDraft: PaperworkFieldValueDraft) => dispatch(updateValue({ fieldValueDraft })),
      /**
       * Delete a single value. All changes are kept on redux until `saveValues` is called.
       */
      deleteValue: (draftId: PaperworkFieldValueDraft['draftId'], id: PaperworkFieldValueDraft['id']) =>
        dispatch(deleteValue({ draftId, id })),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchPaperworkValuesRequest,
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      fieldValues: fieldValues,
      /**
       * Like fieldValues, but ignoring any unsaved changes
       */
      originalFieldValues,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: Object.values(operations).filter((value) => value !== undefined).length,
      /**
       * Flush all the unsaved operations on redux.
       */
      discardOperations: () => dispatch(discardOperations()),
      /**
       * Search a field value of the current paperwork
       */
      searchFieldValue: (
        params: Partial<Pick<PaperworkFieldValue, 'fieldDatatype' | 'materialID' | 'stakeholderID' | 'attachmentID'>>
      ) => {
        return Object.values(fieldValues).find((fieldValue) => {
          if (params.fieldDatatype && fieldValue.fieldDatatype !== params.fieldDatatype) {
            return false;
          }
          if (params.materialID && fieldValue.materialID !== params.materialID) {
            return false;
          }
          if (params.stakeholderID && fieldValue.stakeholderID !== params.stakeholderID) {
            return false;
          }
          if (params.attachmentID && fieldValue.attachmentID !== params.attachmentID) {
            return false;
          }
          return true;
        });
      },
    };
  }, [contextFields, patchPaperworkValuesRequest, fieldValues, originalFieldValues, operations, paperwork, dispatch]);
}
