import { ArchiveCogIcon, ArchiveIcon } from '../../components/Icons';
import { AppSection } from '../../entities/AppSection';

export const paperworkSection: AppSection = {
  path: '/paperworks',
  title: 'Richieste autorizzazione L. 185/90 - L. 428',
  icon: <ArchiveIcon />,
};

export const paperworkTemplateSection: AppSection = {
  path: `${paperworkSection.path}/templates`,
  title: 'Visualizzazione tipologia autorizzazioni',
  icon: <ArchiveCogIcon />,
};

export const paperworkCreateSection: AppSection = {
  path: 'create',
  title: 'Nuova Richiesta autorizzazione L. 185/90 - L. 428',
  icon: <ArchiveIcon />,
};
