import {
  PagedRequestParams,
  PagedResponse,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import {
  PTCustomer,
  PTCustomerSchema,
  PTEndUser,
  PTEndUserSchema,
  PTVendor,
  PTVendorSchema,
} from '../entities/Stakeholder';
import { procurementToolApi } from './procurementToolApi';

const vendorBuyerEndUserApi = procurementToolApi.injectEndpoints({
  endpoints: (builder) => ({
    readVendors: builder.query<Array<PTVendor>, void>({
      query: () => ({
        method: 'GET',
        url: '/vendors',
      }),
      transformResponse: (data) => PTVendorSchema.array().parse(data),
    }),
    searchCustomers: builder.query<PagedResponse<PTCustomer>, PagedRequestParams>({
      query: (params) => ({
        method: 'GET',
        url: '/customers',
        params: formatQueryParams(params),
      }),
      transformResponse: (data) => createPagedResponseSchema(PTCustomerSchema).parse(data),
    }),
    searchEndUsers: builder.query<PagedResponse<PTEndUser>, PagedRequestParams>({
      query: (params) => ({
        method: 'GET',
        url: '/endusers',
        params: formatQueryParams(params),
      }),
      transformResponse: (data) => createPagedResponseSchema(PTEndUserSchema).parse(data),
    }),
  }),
});

export const { useReadVendorsQuery, useLazySearchCustomersQuery, useLazySearchEndUsersQuery } = vendorBuyerEndUserApi;
