import React from 'react';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkDebugMenu } from '../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { DraftOperationEnum } from '../../../../entities/Drafts';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import BillingYearComponent from './BillingYearComponent/BillingYearComponent';
import { MaterialsStepLayout, MaterialsStepProps } from './MaterialsStepLayout';

function BillingYearsStepComponent(props: MaterialsStepProps) {
  const { leftActions, onBack, onForward, forceEnableForwardButton } = props;
  const { saveValues, patchPaperworkValuesRequest, operationsCount, fieldValues, operations } = usePaperworkFields();

  const primaryActions = (
    <>
      <BackButton onClick={onBack} sx={{ mr: 1 }} />
      <ForwardButton
        onClick={onForward}
        disabled={
          !forceEnableForwardButton &&
          (operationsCount > 0 ||
            Object.values(fieldValues).filter(
              (v) =>
                v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_BILLING_YEARS &&
                operations[v.draftId] !== DraftOperationEnum.Values.Delete
            ).length === 0)
        }
      />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={
            <SaveButton
              onClick={() => {
                saveValues();
              }}
              loading={patchPaperworkValuesRequest.isLoading}
              disabled={operationsCount === 0}
            />
          }
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={operationsCount}
        />
      }
    >
      <BillingYearComponent />
    </MaterialsStepLayout>
  );
}

export const BillingYearsStep = React.memo(BillingYearsStepComponent);
