import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Home, NotFoundPage, UnauthorizedPage } from './pages';
import CountryList from './pages/Country/CountryList';
import CurrencyList from './pages/Currency/CurrencyList';
import EquipmentList from './pages/Equipment/EquipmentList';
import { TemplatesDebug } from './pages/Paperwork/PaperworkDetail/TemplatesDebug';
import PaperworkRouter from './pages/Paperwork/PaperworkRouter';
import SerniListUpdateRouter from './pages/SerniListUpdate/SerniListUpdateRouter';
import UserList from './pages/User/UserList';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="/paperworks/*" element={<PaperworkRouter />} />
      <Route path="/serni-list-updates/*" element={<SerniListUpdateRouter />} />
      <Route path="/currencies" element={<CurrencyList />} />
      <Route path="/countries" element={<CountryList />} />
      <Route path="/users" element={<UserList />} />
      <Route path="/equipment" element={<EquipmentList />} />
      <Route path="/templates-debug" element={<TemplatesDebug />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
