import React from 'react';
import { PaperworkPriority } from '../../entities/Paperwork';
import { capitalize } from '../../utils/strings';
import { InlineBadgeLabel, InlineBadgeLabelProps } from './InlineBadgeLabel';

interface PriorityInlineBadgeLabelProps extends Omit<InlineBadgeLabelProps, 'badgeColor'> {
  priority: PaperworkPriority | undefined;
}

function PriorityInlineBadgeLabelComponent(props: PriorityInlineBadgeLabelProps) {
  const { priority, ...inlineBadgeLabelProps } = props;

  if (!priority) {
    return '—';
  }

  return (
    <InlineBadgeLabel
      {...inlineBadgeLabelProps}
      badgeColor={priority.id < 20 ? 'success' : priority.id > 30 ? 'error' : 'warning'}
    >
      {capitalize(priority.name)}
    </InlineBadgeLabel>
  );
}

export const PriorityInlineBadgeLabel = React.memo(PriorityInlineBadgeLabelComponent);
