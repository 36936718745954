import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { PAPERWORK_STATUS, Paperwork } from '../../entities/Paperwork';
import { useReadPaperworkStatusListQuery } from '../../services/paperwork';

export function PaperworkStatusChip(props: {
  status: PAPERWORK_STATUS | undefined;
  note?: string;
}): React.ReactElement | null {
  const { status } = props;
  const { data } = useReadPaperworkStatusListQuery();

  const style = useMemo(() => {
    switch (status) {
      case PAPERWORK_STATUS.APPROVED:
        return { bgcolor: 'common.white', color: 'success.main', border: 1 };
      case PAPERWORK_STATUS.CONDITIONAL_APPROVED:
        return { bgcolor: 'common.white', color: 'success.main', border: 1 };
      case PAPERWORK_STATUS.DRAFT:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
      case PAPERWORK_STATUS.REJECTED:
        return { bgcolor: 'common.white', color: 'error.main', border: 1 };
      case PAPERWORK_STATUS.RETURNED:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      case PAPERWORK_STATUS.TAKEN_CHARGE:
        return { bgcolor: 'common.white', color: 'warning.main', border: 1 };
      case PAPERWORK_STATUS.WAITING:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      case PAPERWORK_STATUS.PREPARED:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      case PAPERWORK_STATUS.SIGNED:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      case PAPERWORK_STATUS.SENT:
        return { bgcolor: 'common.white', color: 'info.main', border: 1 };
      default:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
    }
  }, [status]);

  if (!status) {
    return null;
  }

  return (
    <Tooltip title={props.note} arrow placement="right">
      <Chip
        label={data?.map[status].name}
        size="small"
        sx={{
          textTransform: 'capitalize',
          ...style,
        }}
      />
    </Tooltip>
  );
}

export function usePaperworkStatusColumn(): GridSingleSelectColDef<Paperwork, PAPERWORK_STATUS> {
  const { data } = useReadPaperworkStatusListQuery();

  const valueOptions = useMemo(() => data?.list.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'status',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Paperwork) => {
        return row.status.id;
      },
      renderCell: ({ value, row }: GridRenderCellParams<Paperwork, PAPERWORK_STATUS>) => {
        const note = row.historyList.find((entry) => entry.status && entry.status.id === value)?.note;
        return <PaperworkStatusChip status={value!} note={note} />;
      },
    }),
    [valueOptions]
  );
}
