import { Material, MaterialSchema, PatchMaterialListRequest } from '../entities/Material';
import { api, TAG_TYPES } from './baseApi';

const url = 'materials';

export const materialAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    readMaterialList: builder.query<
      {
        list: Material[];
        partNumberList: string[];
        materialsByPN: Record<string, Material>;
        equipmentList: string[];
        eccnList: string[];
      },
      void
    >({
      query: () => ({ url }),
      transformResponse: (data) => {
        const list = MaterialSchema.array().parse(data);

        const materialsByPN: Record<string, Material> = {};
        const partNumberListSet = new Set<string>();
        const equipmentListSet = new Set<string>();
        const eccnListSet = new Set<string>();

        for (const material of list) {
          if (material.pn) {
            partNumberListSet.add(material.pn);
            materialsByPN[material.pn] = material;
          }
          if (material.eccn) {
            eccnListSet.add(material.eccn);
          }
        }

        return {
          list,
          materialsByPN,
          partNumberList: Array.from(partNumberListSet),
          equipmentList: Array.from(equipmentListSet),
          eccnList: Array.from(eccnListSet),
        };
      },
      providesTags: () => [{ type: TAG_TYPES.MATERIAL, id: 'LIST' }],
    }),
    patchMaterialList: builder.mutation<{ id: number }, PatchMaterialListRequest>({
      query: ({ paperworkId, ...body }) => ({ url: `/paperworks/${paperworkId}/materials`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [
        { type: TAG_TYPES.MATERIAL, id: 'LIST' },
        { type: TAG_TYPES.PAPERWORK, id: req.paperworkId },
      ],
    }),
  }),
});

export const { useReadMaterialListQuery, usePatchMaterialListMutation } = materialAPi;
