import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';

export function useMaterialQuantityColumn(): GridColDef {
  return useMemo(
    () => ({
      headerName: 'Quantità',
      type: 'number',
      width: 100,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]?.value);
        return Number.isNaN(v) ? null : v;
      },
    }),
    []
  );
}
