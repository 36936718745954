import React from 'react';
import { GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid-premium';
import { ClipboardEditOutlineIcon } from '../Icons';

interface ReviewIconButtonComponentProps extends Omit<GridActionsCellItemProps, 'onClick' | 'label'> {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  highlight: boolean;
}

function ReviewIconButtonComponent(props: ReviewIconButtonComponentProps) {
  const { onClick, highlight } = props;

  return (
    <GridActionsCellItem
      key="review"
      icon={<ClipboardEditOutlineIcon />}
      onClick={onClick}
      label="Revisiona"
      title="Revisiona"
      color={highlight ? 'primary' : 'inherit'}
    />
  );
}

export const ReviewGridActionsCellItem = React.memo(ReviewIconButtonComponent);
