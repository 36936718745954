import { useMemo } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import { PAPERWORK_STATUS, PaperworkReview } from '../../entities/Paperwork';
import { UserRole } from '../../entities/User';
import { DataEntryPages, MaterialsSections, PaperworkPhases } from '../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import { useReadPaperworkCompletedPagesQuery, useReadPaperworkTemplatesListQuery } from '../../services/paperwork';
import { useReadPaperworkReviewsQuery } from '../../services/paperworkReview';

type PaperworkReviewReturnType = {
  showReviews: boolean;
  showEditReviews: boolean;
  reviews: PaperworkReview[];
  allDataReviewed: boolean;
  hasRejectedReviews: boolean;
  attachmentsRejectedReviews: PaperworkReview[];
  materialsRejectedReviews: PaperworkReview[];
  stakeholdersRejectedReviews: PaperworkReview[];
  contractualTermsRejectedReviews: PaperworkReview[];
};

export function usePaperworkReviews(): PaperworkReviewReturnType {
  const { paperwork } = usePaperwork();
  const { data: paperworkTemplates } = useReadPaperworkTemplatesListQuery();
  const { hasRole, isAdmin } = useAuth();

  const { data: reviews } = useReadPaperworkReviewsQuery({ paperworkId: paperwork?.id ?? -1 }, { skip: !paperwork });
  const readPaperworkCompletedPagesQuery = useReadPaperworkCompletedPagesQuery({ paperworkId: paperwork?.id ?? -1 });

  const contractualTermsContext = paperworkTemplates
    ?.find((template) => template.id === paperwork?.paperworkTemplate.id)
    ?.paperworkContextList.find(
      (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.ContractualTerms
    );

  const additionalEconomicConditionsContext = paperworkTemplates
    ?.find((template) => template.id === paperwork?.paperworkTemplate.id)
    ?.paperworkContextList.find(
      (context) =>
        context.phase === PaperworkPhases.DataEntry &&
        context.page === DataEntryPages.Materials &&
        context.section === MaterialsSections.AdditionalEconomicConditions
    );

  return useMemo(() => {
    let rejectedReviewsCount = 0;
    const attachmentsRejectedReviews = [];
    const materialsRejectedReviews = [];
    const stakeholdersRejectedReviews = [];
    const contractualTermsRejectedReviews = [];

    if (reviews) {
      for (const review of reviews) {
        if (!review.review.isApproved) {
          rejectedReviewsCount = rejectedReviewsCount + 1;

          if (review.attachmentId !== undefined) {
            attachmentsRejectedReviews.push(review);
          }
          if (review.materialId !== undefined) {
            materialsRejectedReviews.push(review);
          }
          if (review.stakeholderId !== undefined) {
            stakeholdersRejectedReviews.push(review);
          }
          if (review.contextId === contractualTermsContext?.id) {
            contractualTermsRejectedReviews.push(review);
          }
        }
      }

      if (additionalEconomicConditionsContext) {
        const additionalEconomicConditionsReview = reviews?.find(
          (review) => review.contextId === additionalEconomicConditionsContext.id
        );
        if (additionalEconomicConditionsReview) {
          if (!additionalEconomicConditionsReview.review.isApproved) {
            materialsRejectedReviews.push(additionalEconomicConditionsReview);
          }
        }
      }
    }

    const allDataReviewed = readPaperworkCompletedPagesQuery.data?.completedPages
      ? Object.values(readPaperworkCompletedPagesQuery.data.completedPages).every(
          (completedPage) => completedPage.reviewCompleted === true
        )
      : false;

    const paperworkReview: PaperworkReviewReturnType = {
      showReviews: false,
      showEditReviews: false,
      reviews: reviews ?? [],
      allDataReviewed: allDataReviewed,
      hasRejectedReviews: rejectedReviewsCount > 0,
      attachmentsRejectedReviews,
      materialsRejectedReviews,
      stakeholdersRejectedReviews,
      contractualTermsRejectedReviews,
    };

    if (!paperwork) {
      return paperworkReview;
    }

    const statusId = paperwork.status.id;

    if (statusId === PAPERWORK_STATUS.DRAFT) {
      return paperworkReview;
    }

    if (!isAdmin && !hasRole(UserRole.GT)) {
      paperworkReview.showReviews = true;
      return paperworkReview;
    }

    paperworkReview.showReviews = true;
    paperworkReview.showEditReviews = true;

    return paperworkReview;
  }, [
    additionalEconomicConditionsContext,
    contractualTermsContext?.id,
    hasRole,
    isAdmin,
    paperwork,
    readPaperworkCompletedPagesQuery,
    reviews,
  ]);
}
