import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { DetailSection } from '../../../components/DetailSection';
import { AvailableCountryAutocomplete } from '../../../components/Form/AvailableCountryAutocomplete/AvailableCountryAutocomplete';
import { MovementTypeAutocomplete } from '../../../components/Form/MovementTypeAutocomplete/MovementTypeAutocomplete';
import { SaleTypeAutocomplete } from '../../../components/Form/SaleTypeAutocomplete/SaleTypeAutocomplete';
import { Layout } from '../../../components/Layout';
import { PageTitle } from '../../../components/PageTitle';
import { EnabledCountry } from '../../../entities/Country';
import { AddPaperwork, AddPaperworkSchema, PAPERWORK_STATUS } from '../../../entities/Paperwork';
import { useReadCountryListQuery } from '../../../services/countries';
import { useCreatePaperworkMutation, useReadPaperworkTemplatesListQuery } from '../../../services/paperwork';
import { paperworkCreateSection } from '../sections';
import PaperworkPrioritySelect from './PaperworkPrioritySelect';
import PaperworkStatusSelect from './PaperworkStatusSelect';
import { PaperworkTypeAutocomplete } from './PaperworkTypeAutocomplete';

const PAGE_TITLE = paperworkCreateSection.title;

export default function PaperworkCreatePage(): React.ReactElement {
  const { data: templateList, isFetching, error: loadingError } = useReadPaperworkTemplatesListQuery();
  const { data: countries } = useReadCountriesQuery();
  const { username } = useAuth();
  const [create, createStatus] = useCreatePaperworkMutation();
  const { data: enabledCountries } = useReadCountryListQuery();
  const navigate = useNavigate();

  const form = useForm<AddPaperwork>({
    defaultValues: {
      requester: username || '',
      createdAt: new Date(),
      statusId: PAPERWORK_STATUS.DRAFT,
    },
    resolver: zodResolver(AddPaperworkSchema),
  });

  const { control, watch, resetField, setError, clearErrors } = form;

  const watchAllFields = watch();

  const enabledCountriesMap = useMemo(() => {
    const enabledCountriesMap: Record<string, EnabledCountry> = {};

    if (enabledCountries) {
      for (const enabledCountry of enabledCountries) {
        enabledCountriesMap[enabledCountry.code] = enabledCountry;
      }
    }

    return enabledCountriesMap;
  }, [enabledCountries]);

  const filteredTemplateList = useMemo(() => {
    if (!templateList || !watchAllFields.country || !countries) return [];

    const enabledCountry = enabledCountriesMap[watchAllFields.country];

    if (!enabledCountry) {
      return [];
    }

    let filteredTemplatesByCountry = templateList.filter((t) => t.countryCategory === enabledCountry.category);
    if (watchAllFields.saleType)
      filteredTemplatesByCountry = filteredTemplatesByCountry.filter((t) => t.saleType.id === watchAllFields.saleType);
    if (watchAllFields.paperworkTypeId)
      filteredTemplatesByCountry = filteredTemplatesByCountry.filter(
        (t) => t.paperworkType.id === watchAllFields.paperworkTypeId
      );
    return filteredTemplatesByCountry;
  }, [
    countries,
    enabledCountriesMap,
    templateList,
    watchAllFields.country,
    watchAllFields.paperworkTypeId,
    watchAllFields.saleType,
  ]);

  const onSubmit = useCallback(
    async (values: AddPaperwork) => {
      clearErrors('movementTypeId');
      if (!filteredTemplateList) return;
      const selectedTemplated = filteredTemplateList.find(
        (t) => (t.movementType?.id || null) === (values.movementTypeId || null)
      );
      if (!selectedTemplated) {
        setError('movementTypeId', { type: 'custom', message: 'Selezionare una tipologia di movimentazione' });
        return;
      }
      const res = await create({
        templateId: selectedTemplated.id,
        country: values.country,
        priorityId: values.priorityId,
      }).unwrap();
      navigate(`/paperworks/${res.id}`);
    },
    [clearErrors, create, filteredTemplateList, navigate, setError]
  );

  return (
    <Layout title={PAGE_TITLE} header={<PageTitle pageTitle={PAGE_TITLE} />} maxWidth="xl">
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid xs={12}>
          <Stack
            component="form"
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
            id="create-paperwork-form"
            spacing={1}
          >
            <Grid container spacing={2}>
              <Grid xs={12} sm={4}>
                <Controller
                  control={control}
                  name="requester"
                  render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                    <UserAutocomplete
                      label="Richiedente"
                      onChange={(_, value) => onChange(value)}
                      disableClearable
                      error={Boolean(error)}
                      helperText={error?.message ?? ' '}
                      {...field}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Data apertura pratica"
                      {...field}
                      disabled
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: Boolean(error),
                          helperText: error?.message || ' ',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Controller
                  name="statusId"
                  control={control}
                  render={({ field }) => <PaperworkStatusSelect label="Stato pratica" {...field} disabled fullWidth />}
                />
              </Grid>
            </Grid>
            <DetailSection sectionTitle="Priorità richiesta" gutterBottom />
            <Grid container spacing={2}>
              <Grid md={4} xs={6}>
                <Controller
                  name="priorityId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <PaperworkPrioritySelect
                      label="Priorità della richiesta"
                      {...field}
                      error={Boolean(error)}
                      value={field.value ?? ' '}
                      helperText={error?.message ?? ' '}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <DetailSection sectionTitle="Tipologia Autorizzazione" gutterBottom />
            <Grid container spacing={2}>
              <Grid md={4} xs={6}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                    <AvailableCountryAutocomplete
                      label="Paese destinatario"
                      {...field}
                      value={field.value ?? ' '}
                      error={Boolean(error)}
                      helperText={error?.message ?? ' '}
                      onChange={(_, value) => {
                        onChange(value);
                        resetField('saleType');
                        resetField('paperworkTypeId');
                        resetField('movementTypeId');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid md={8} xs={6}>
                <Alert severity="info">{'Paese del consignee MT (ship to)'}</Alert>
              </Grid>
              <Grid md={4} xs={6}>
                <Controller
                  name="saleType"
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                    <SaleTypeAutocomplete
                      label="Tipologia di vendita"
                      {...field}
                      value={field.value ?? null}
                      error={Boolean(error)}
                      helperText={error?.message ?? ' '}
                      onChange={(_, value) => {
                        onChange(value);
                        resetField('paperworkTypeId');
                        resetField('movementTypeId');
                      }}
                      disabled={!watch('country')}
                      filteredIdValues={[...new Set(filteredTemplateList.map((d) => d.saleType.id))]}
                    />
                  )}
                />
              </Grid>
              <Grid md={8} xs={6}>
                <Alert severity="info">
                  {
                    'La scelta deve essere effettuata a prescindere dal tipo di transazione (finanziaria, movimentazione di hardware, trasferimento di dati tecnici in via tangibile o intangibile)'
                  }
                </Alert>
              </Grid>
              <Controller
                name="paperworkTypeId"
                control={control}
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <PaperworkTypeAutocomplete
                    label="Tipologia di autorizzazione"
                    {...field}
                    value={field.value ?? null}
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    onChange={(_, value) => {
                      onChange(value);
                      resetField('movementTypeId');
                    }}
                    disabled={!watch('saleType')}
                    filteredIdValues={[...new Set(filteredTemplateList.map((d) => d.paperworkType.id))]}
                  />
                )}
              />
              <Grid md={4} xs={6}>
                <Controller
                  name="movementTypeId"
                  control={control}
                  render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                    <MovementTypeAutocomplete
                      label="Tipologia di movimentazione"
                      {...field}
                      value={field.value ?? null}
                      error={Boolean(error)}
                      onChange={(_, value) => onChange(value)}
                      helperText={error?.message ?? ' '}
                      disabled={!watch('paperworkTypeId')}
                      filteredIdValues={
                        [...new Set(filteredTemplateList.map((d) => d.movementType?.id))].filter(Number) as number[]
                      }
                    />
                  )}
                />
              </Grid>
              <Grid md={8} xs={6} />
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            form="create-paperwork-form"
            loading={createStatus.isLoading}
            disabled={isFetching || !!loadingError}
          >
            Crea Autorizzazione
          </LoadingButton>
        </Grid>
      </Grid>
    </Layout>
  );
}
