import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-unresolved
import PreparedPaperworkSample from '../../../assets/prepared-paperwork-sample.docx?url';
import { DownloadIcon, UploadIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { AccentButton } from '../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../components/PageTitle';
import { PaperworkDebugMenu } from '../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { PAPERWORK_STATUS } from '../../../entities/Paperwork';
import {
  PaperworkPhases,
  PreparationSigningPages,
  SigningSection,
} from '../../../hooks/usePaperwork/paperworkNavigation';
import { paperworkRoutesConfiguration } from '../../../hooks/usePaperwork/paperworkRouteConfiguration';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { usePatchPaperworkMutation } from '../../../services/paperwork';
import { useReadPecSettingsQuery } from '../../../services/pecSettings';
import { formatDateTime } from '../../../utils/dates';
import { PaperworkInfoPanel } from '../PaperworkInfoPanel';
import { AddSignedPaperworkDialog } from './AddSignedPaperworkDialog';
import { MailAttachments } from './MailAttachments';
import { SignedPaperworkAttachmentCard } from './SignedPaperworkAttachmentCard';
import { WriteEmailDialog } from './WriteEmailDialog';

function PaperworkSigningPage() {
  const { paperwork } = usePaperwork();
  const { route, detailsPath } = usePaperworkNavigation();
  const navigate = useNavigate();

  const [patchPaperwork] = usePatchPaperworkMutation();

  const mailAttachmentContextId = route?.sections?.[SigningSection.Attachments]?.context?.id ?? -1;

  const paperworkAttachment = useMemo(() => {
    return paperwork?.attachmentList?.find((attachment) => attachment.savedInContextId === route?.context?.id);
  }, [paperwork?.attachmentList, route?.context?.id]);

  const [mailAttachments, setMailAttachments] = useState<Set<number>>(
    new Set(
      (paperwork?.attachmentList ?? [])
        .filter(
          (attachment) =>
            attachment.savedInContextId !== route?.context?.id &&
            attachment.savedInContextId !== mailAttachmentContextId
        )
        .map((attachment) => attachment.attachment.id)
    )
  );

  useReadPecSettingsQuery(); // Preload recipients
  const pageTitle = paperwork
    ? paperworkRoutesConfiguration[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].title(
        paperwork
      )
    : '';

  return (
    <Layout
      title={pageTitle}
      header={<PageTitle pageTitle={pageTitle} rightItems={<PaperworkInfoPanel />} />}
      maxWidth={false}
      footer={
        <NavigationPanel
          primaryActions={
            <>
              <AccentButton onClick={() => navigate(`../${detailsPath}`)} disabled sx={{ mr: 1 }}>
                {'Salta invio email'}
              </AccentButton>
              <WriteEmailDialog
                signedPaperworkAttachment={paperworkAttachment}
                mailAttachments={mailAttachments}
                mailAttachmentContextId={mailAttachmentContextId}
              />
            </>
          }
          leftActions={
            <>
              <PaperworkDebugMenu />
            </>
          }
        />
      }
    >
      <Container maxWidth="xl">
        {!paperwork?.pecStatus?.sentAt ? (
          <Stack direction="column" alignItems="flex-start" height="100%" gap={2}>
            <Alert severity="info">{'Scarica, compila e carica il documento'}</Alert>
            <Divider textAlign="left" sx={{ width: '100%', marginTop: 2 }}>
              {'1. Scaricamento richiesta preparata'}
            </Divider>
            <Box>
              <Button variant="contained" startIcon={<DownloadIcon />} type="a" href={PreparedPaperworkSample} download>
                {'Scarica richiesta preparata'}
              </Button>
            </Box>
            <Divider textAlign="left" sx={{ width: '100%', marginTop: 2 }}>
              {'2. Caricamento richiesta firmata'}
            </Divider>
            <Box>
              <AddSignedPaperworkDialog
                paperworkAttachment={paperworkAttachment}
                buttonProps={{
                  children: 'Carica richiesta compilata',
                  startIcon: <UploadIcon />,
                }}
                onAdd={async (signed) => {
                  if (paperwork) {
                    if (signed) {
                      await patchPaperwork({
                        paperworkId: paperwork.id,
                        statusId: PAPERWORK_STATUS.SIGNED,
                      }).unwrap();
                    } else {
                      await patchPaperwork({
                        paperworkId: paperwork.id,
                        statusId: PAPERWORK_STATUS.PREPARED,
                      }).unwrap();
                    }
                  }
                }}
              />
            </Box>
            <Box width="100%">
              {paperworkAttachment ? <SignedPaperworkAttachmentCard attachment={paperworkAttachment} /> : null}
            </Box>
            <Divider textAlign="left" sx={{ width: '100%', marginTop: 2 }}>
              {'3. Verifica documenti da allegare alla mail'}
            </Divider>
            <MailAttachments
              signedPaperworkAttachment={paperworkAttachment}
              mailAttachmentContextId={mailAttachmentContextId}
              mailAttachments={mailAttachments}
              setMailAttachments={setMailAttachments}
            />
          </Stack>
        ) : (
          <Stack direction="column" alignItems="flex-start" height="100%" gap={2}>
            <Alert severity="success">{'La pratica è stata inviata'}</Alert>
            <Card>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
                <Stack direction="row" alignItems="center" gap={1} flex={1}>
                  <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
                    {'PEC'}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="stretch" gap={1} flex={1} padding={1}>
                  {paperwork?.pecStatus?.sentAt ? (
                    <Stack direction="row" justifyContent="space-between">
                      {'PEC Inviata'}
                      <Chip
                        color="default"
                        variant="outlined"
                        label={
                          paperwork?.pecStatus?.sentAt ? formatDateTime(new Date(paperwork?.pecStatus?.sentAt)) : `—`
                        }
                      ></Chip>
                    </Stack>
                  ) : null}
                  {paperwork?.pecStatus?.receivedAt ? (
                    <Stack direction="row" justifyContent="space-between">
                      {'PEC Consegnata'}
                      <Chip
                        color="default"
                        variant="outlined"
                        label={
                          paperwork?.pecStatus?.receivedAt
                            ? formatDateTime(new Date(paperwork?.pecStatus?.receivedAt))
                            : `—`
                        }
                      ></Chip>
                    </Stack>
                  ) : null}
                  {paperwork?.pecStatus?.acceptedAt ? (
                    <Stack direction="row" justifyContent="space-between">
                      {'PEC Accettata'}
                      <Chip
                        color="default"
                        variant="outlined"
                        label={
                          paperwork?.pecStatus?.acceptedAt
                            ? formatDateTime(new Date(paperwork?.pecStatus?.acceptedAt))
                            : `—`
                        }
                      ></Chip>
                    </Stack>
                  ) : null}
                </Stack>
                {paperworkAttachment ? (
                  <SignedPaperworkAttachmentCard attachment={paperworkAttachment} disableDelete />
                ) : null}
              </CardContent>
            </Card>
          </Stack>
        )}
      </Container>
    </Layout>
  );
}

export function PaperworkSigningPageWrapper() {
  const { paperwork } = usePaperwork();
  const { route } = usePaperworkNavigation();

  if (!paperwork || !route) {
    return null;
  }
  return <PaperworkSigningPage />;
}
