import React, { Fragment, useMemo } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CountryFlag, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { FileCard } from '../../../../components/FileCard';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { ReviewGuard } from '../../../../components/review/ReviewGuard';
import { ReviewPopover } from '../../../../components/review/ReviewPopover';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { Attachment } from '../../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { formatDateTime } from '../../../../utils/dates';
import { OrderDeleteDialog } from './OrderDeleteDialog';

interface OrderCardProps extends CardProps {
  orderAttachment: Attachment;
  orderApprovalAttachment: Attachment;
  orderNumber: string;
  orderDate: string;
}

function OrderCardComponent(props: OrderCardProps) {
  const { orderNumber, orderDate, orderAttachment, orderApprovalAttachment, ...cardProps } = props;
  const { stakeholders } = useStakeholders();
  const { data: countries } = useReadCountriesQuery();
  const { fieldValues } = usePaperworkFields();
  const { reviews } = usePaperworkReviews();
  const { paperwork } = usePaperwork();

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && orderAttachment.id === review.attachmentId),
    [orderAttachment.id, paperwork?.id, reviews]
  );

  // Do not show the card if there is no ID (operations still in draft)
  if (!orderAttachment?.id) {
    return null;
  }

  return (
    <Card {...cardProps}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
            {orderNumber}
          </Typography>
          <OrderDeleteDialog orderNumber={orderNumber} attachments={[orderAttachment, orderApprovalAttachment]} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="body2" flex={1}>
            {`Ordine caricato il ${formatDateTime(new Date(orderDate))}`}
          </Typography>
        </Stack>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="bold" gutterBottom>
              {'Ordine'}
            </Typography>
            <FileCard file={new File([], orderAttachment.attachment.name)} variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold" gutterBottom>
              {'Accettazione dell’ordine'}
            </Typography>
            <FileCard file={new File([], orderApprovalAttachment.attachment.name)} variant="outlined" />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              lineHeight: 3,
              marginTop: 1,
            }}
          >
            {'Parti coinvolte'}
          </ListSubheader>
        }
      >
        {orderAttachment.stakeholderIdList?.map((stakeholderId) => {
          const stakeholder = Object.values(stakeholders).find((stakeholder) => stakeholder.id === stakeholderId);
          const country = stakeholder?.country ? countries?.byISO[stakeholder?.country] : undefined;

          let isMilitaryBase = false;
          if (stakeholder) {
            for (const [_, fieldValue] of Object.entries(fieldValues)) {
              if (fieldValue.stakeholderID === stakeholder.id) {
                if (fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE) {
                  isMilitaryBase = fieldValue.value === 'true';
                }
              }
            }
          }

          return (
            <Fragment key={stakeholderId}>
              <ListItem>
                <Stack direction="column" flex={1} gap={0.75}>
                  <Stack direction="row">
                    <ListItemText primary={stakeholder?.name} sx={{ margin: 0 }} />
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={'0.8ch'}
                      sx={{
                        '& img': {
                          height: '0.8em',
                          borderRadius: 0.5,
                        },
                      }}
                    >
                      <CountryFlag countryCode={stakeholder?.country ?? ''} />
                      {countries?.byISO[stakeholder?.country ?? ''].name}
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <ListItemText secondary={stakeholder?.address} sx={{ flex: 1, margin: 0 }} />
                    {country?.isNato ? <Chip color="secondary" size="small" variant="outlined" label={'NATO'} /> : null}
                    {isMilitaryBase ? (
                      <Chip color="secondary" size="small" variant="outlined" label={'Forza armata'} />
                    ) : null}
                  </Stack>
                </Stack>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
      <ReviewGuard>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
          <ReviewChip review={review} />
          <ReviewGuard editReview>
            <ReviewPopover
              paperworkReview={{
                attachmentId: orderAttachment.id,
              }}
              review={review}
            >
              {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
            </ReviewPopover>
          </ReviewGuard>
        </Stack>
      </ReviewGuard>
    </Card>
  );
}

export const OrderCard = React.memo(OrderCardComponent);
