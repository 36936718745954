import React, { useMemo } from 'react';
import { Link, Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { InfoTooltip } from '../../../../components/InfoTooltip/InfoTooltip';
import { NavigationButton } from '../../../../components/NavigationPanel/NavigationButtons';
import {
  StakeholdersSections,
  PaperworkPhases,
  DataEntryPages,
} from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkCompletedPagesQuery } from '../../../../services/paperwork';
import { BillToStep, BuyerStep, EndUsersStep, OrderFromStep, ShipToStep } from './StakeholderSteps';

const InfoTooltipPlaceholder = styled(Stack)(({ theme }) => ({ flex: `0 0 ${theme.spacing(4)}` }));

export default function PaperworkStakeholdersPage() {
  const navigate = useNavigate();
  const { paperwork } = usePaperwork();
  const { data: paperworkCompletedPages } = useReadPaperworkCompletedPagesQuery(
    { paperworkId: paperwork?.id ?? 1 },
    { skip: !paperwork }
  );

  const { routes, detailsPath: detailsUrl } = usePaperworkNavigation();

  const stakeholdersRoute = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders];
  const stakeholdersSections = stakeholdersRoute.sections;

  const stakeholdersSteps: Array<StakeholdersSections> = useMemo(() => {
    let stakeholdersSteps: Array<StakeholdersSections> = [];

    for (const [pageName, section] of Object.entries(stakeholdersRoute.sections)) {
      if (section.enabled) {
        stakeholdersSteps.push(pageName as StakeholdersSections);
      }
    }

    stakeholdersSteps = stakeholdersSteps.sort(
      (a, b) => (stakeholdersSections[a].order ?? 0) - (stakeholdersSections[b].order ?? 0)
    );

    return stakeholdersSteps;
  }, [stakeholdersRoute.sections, stakeholdersSections]);

  const activeStepName = useMatch(stakeholdersRoute.absoluteMatcher)?.params?.['*'] as StakeholdersSections | undefined;
  const activeStepIndex = activeStepName ? stakeholdersSteps.indexOf(activeStepName) : -1;
  const activeStep = activeStepName ? stakeholdersSections[activeStepName] : undefined;

  const handleForward = async () => {
    if (activeStepIndex > stakeholdersSteps.length - 2) {
      if (paperwork && paperworkCompletedPages) {
        navigate(detailsUrl);
      }
    } else {
      navigate(`./${stakeholdersSteps[activeStepIndex + 1]}`);
    }
  };

  const handleBack = () => {
    navigate(`./${stakeholdersSteps[activeStepIndex - 1]}`);
  };

  if (!activeStep) {
    if (stakeholdersSections && stakeholdersSteps[0] && stakeholdersSections[stakeholdersSteps[0]]) {
      return <Navigate to={stakeholdersSections[stakeholdersSteps[0]]?.path} replace />;
    }
    return null;
  }

  const leftActions = (
    <>
      <NavigationButton component={Link} to={detailsUrl}>
        {`Torna alla dashboard pratica`}
      </NavigationButton>
    </>
  );

  const stepper =
    stakeholdersSteps.length > 1 || activeStep?.tooltip ? (
      <Stack direction="row" alignItems="center" gap={4} sx={{ padding: 2, paddingTop: 1 }}>
        <InfoTooltipPlaceholder />
        {stakeholdersSteps.length > 1 ? (
          <Stepper nonLinear activeStep={activeStepIndex} sx={{ flex: 1 }}>
            {stakeholdersSteps.map((stepName) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={stepName} {...stepProps}>
                  <StepButton {...labelProps} onClick={() => navigate(`./${stepName}`)} sx={{ paddingY: 1 }}>
                    {stakeholdersSections[stepName].title}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <InfoTooltipPlaceholder>
          {activeStep?.tooltip ? <InfoTooltip>{activeStep.tooltip}</InfoTooltip> : null}
        </InfoTooltipPlaceholder>
      </Stack>
    ) : (
      <Box sx={{ padding: 1 }} />
    );

  return (
    <Routes>
      <Route
        path={stakeholdersSections[StakeholdersSections.ShipTo].matcher}
        element={<ShipToStep leftActions={leftActions} onForward={handleForward} stepper={stepper} />}
      />
      <Route
        path={stakeholdersSections[StakeholdersSections.OrderFrom].matcher}
        element={
          <OrderFromStep leftActions={leftActions} onForward={handleForward} onBack={handleBack} stepper={stepper} />
        }
      />
      <Route
        path={stakeholdersSections[StakeholdersSections.Buyer].matcher}
        element={
          <BuyerStep leftActions={leftActions} onForward={handleForward} onBack={handleBack} stepper={stepper} />
        }
      />
      <Route
        path={stakeholdersSections[StakeholdersSections.BillTo].matcher}
        element={
          <BillToStep leftActions={leftActions} onForward={handleForward} onBack={handleBack} stepper={stepper} />
        }
      />
      <Route
        path={stakeholdersSections[StakeholdersSections.EndUser].matcher}
        element={
          <EndUsersStep leftActions={leftActions} onForward={handleForward} onBack={handleBack} stepper={stepper} />
        }
      />
    </Routes>
  );
}
