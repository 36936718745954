import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PaperworkStatusChip } from '../../../../components/DataGrid/usePaperworkStatusColumn';
import { TagTextOutlineIcon } from '../../../../components/Icons';
import { UserName } from '../../../../components/User/UserName';
import { PaperworkHistoryEntry } from '../../../../entities/Paperwork';
import { BaseHistoryEntry, BaseHistoryEntryProps } from './BaseHistoryEntry';

function StatusChangeHistoryEntryComponent(
  props: Pick<BaseHistoryEntryProps, 'firstEntry' | 'lastEntry'> & { historyEntry: PaperworkHistoryEntry }
) {
  const { historyEntry, firstEntry, ...baseHistoryEntryProps } = props;
  return (
    <BaseHistoryEntry
      {...baseHistoryEntryProps}
      timestamp={new Date(historyEntry.updatedAt)}
      firstEntry={firstEntry}
      IconComponent={TagTextOutlineIcon}
    >
      <Typography variant="body1" fontWeight={500}>
        <UserName username={historyEntry.username} />
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body2">
          {firstEntry ? 'La richiesta è state creata:' : 'Lo stato è cambiato in:'}
        </Typography>
        <PaperworkStatusChip status={historyEntry.status.id} />
      </Stack>
      {historyEntry.note ? <Typography variant="body2">{`“${historyEntry.note}”`}</Typography> : null}
    </BaseHistoryEntry>
  );
}

export const StatusChangeHistoryEntry = React.memo(StatusChangeHistoryEntryComponent);
