import React, { useMemo, useState } from 'react';
import Autocomplete, { AutocompleteProps, autocompleteClasses } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { SearchPaperworkListPayload } from '../../../entities/Paperwork';
import { useReadEquipmentListQuery } from '../../../services/equipmentiApi';
import { useReadMaterialListQuery } from '../../../services/material';
import { CompactListItem } from './CompactListItem';

function pastedStringList(e: React.ClipboardEvent<HTMLDivElement>) {
  e.preventDefault();
  return e.clipboardData
    .getData('text')
    .split('\n')
    .map((pn) => pn.trim())
    .filter((pn) => pn.length > 0);
}

const renderTags: AutocompleteProps<string, true, true, true>['renderTags'] = (value: readonly string[], getTagProps) =>
  value.map((option, index) => (
    // eslint-disable-next-line react/jsx-key
    <Chip size="small" color="secondary" label={option} {...getTagProps({ index })} />
  ));

const AdvancedFiltersPanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [`.${autocompleteClasses.root}`]: {
    flex: '1 1 0',
  },
  [`.${autocompleteClasses.endAdornment}`]: {
    top: 'unset',
    bottom: '4px',
  },
  [`.${autocompleteClasses.inputRoot}`]: {
    minHeight: '50px',
  },
}));

function AutocompleteTextfield(props: TextFieldProps) {
  return <TextField InputLabelProps={{ shrink: true }} variant="filled" size="small" {...props} />;
}

interface AdvancedFiltersProps {
  open: boolean;
  advancedFilters: SearchPaperworkListPayload;
  onAdvancedFiltersChange: (advancedFilters: SearchPaperworkListPayload) => void;
}

function AdvancedFiltersComponent(props: AdvancedFiltersProps) {
  const { advancedFilters, onAdvancedFiltersChange } = props;

  const [partNumberInputValue, setPartNumberInputValue] = useState('');
  const [equipmentInputValue, setEquipmentInputValue] = useState('');
  const [eccnInputValue, setEccnInputValue] = useState('');

  const { data } = useReadMaterialListQuery();
  const { data: equipments } = useReadEquipmentListQuery();

  const partNumberOptions = useMemo(() => {
    if (partNumberInputValue.length > 1) {
      return data?.partNumberList ?? [];
    }
    return [];
  }, [data, partNumberInputValue.length]);

  return (
    <div>
      <Collapse in={props.open}>
        <AdvancedFiltersPanel>
          <Autocomplete
            multiple
            options={partNumberOptions}
            value={advancedFilters.pn}
            onChange={(e, value) =>
              onAdvancedFiltersChange({
                ...advancedFilters,
                pn: value,
              })
            }
            inputValue={partNumberInputValue}
            onInputChange={(_, value) => setPartNumberInputValue(value)}
            freeSolo
            renderTags={renderTags}
            renderOption={(props, option) => (
              <CompactListItem
                key={option}
                listItemProps={props}
                primary={option}
                secondary={data ? data.materialsByPN[option].description ?? ' ' : ''}
              />
            )}
            renderInput={(params) => (
              <AutocompleteTextfield
                {...params}
                label="Part Number"
                placeholder="P/N"
                onPaste={(e) => {
                  onAdvancedFiltersChange({
                    ...advancedFilters,
                    pn: [...advancedFilters.pn, ...pastedStringList(e)],
                  });
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            options={data?.equipmentList.map((option) => option) ?? []}
            value={advancedFilters.serniCode}
            onChange={(e, value) =>
              onAdvancedFiltersChange({
                ...advancedFilters,
                serniCode: value,
              })
            }
            inputValue={equipmentInputValue}
            onInputChange={(_, value) => setEquipmentInputValue(value)}
            freeSolo
            renderTags={renderTags}
            renderOption={(props, option) => (
              <CompactListItem
                key={option}
                listItemProps={props}
                primary={option}
                secondary={equipments ? equipments.bySerniCode[option].description ?? ' ' : ''}
              />
            )}
            renderInput={(params) => (
              <AutocompleteTextfield
                {...params}
                label="Equipaggiamenti"
                placeholder="Descrizione o codice SERNI"
                onPaste={(e) => {
                  onAdvancedFiltersChange({
                    ...advancedFilters,
                    serniCode: [...advancedFilters.serniCode, ...pastedStringList(e)],
                  });
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            options={data?.eccnList.map((option) => option) ?? []}
            value={advancedFilters.eccn}
            onChange={(e, value) =>
              onAdvancedFiltersChange({
                ...advancedFilters,
                eccn: value,
              })
            }
            inputValue={eccnInputValue}
            onInputChange={(_, value) => setEccnInputValue(value)}
            freeSolo
            renderTags={renderTags}
            renderInput={(params) => (
              <AutocompleteTextfield
                {...params}
                label="Classifiche"
                placeholder="Descrizione o ECCN"
                onPaste={(e) => {
                  onAdvancedFiltersChange({
                    ...advancedFilters,
                    eccn: [...advancedFilters.eccn, ...pastedStringList(e)],
                  });
                }}
              />
            )}
          />
          <Stack direction="column" justifyContent="center">
            <InfoTooltip>
              {
                'In caso di filtri avanzati multipli, verranno mostrate tutte le Richieste Autorizzazione che corrispondono ad '
              }
              <b>{'almeno'}</b>
              {' un criterio inserito'}
            </InfoTooltip>
          </Stack>
        </AdvancedFiltersPanel>
      </Collapse>
    </div>
  );
}

export const AdvancedFilters = React.memo(AdvancedFiltersComponent);
