import React, { useId, useMemo } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/x-date-pickers-pro';
import { DecimalTextField, IntegerTextField } from '@top-solution/microtecnica-mui';
import CurrencySelect from '../../../../../components/Form/CurrencySelect/CurrencySelect';
import { Material } from '../../../../../entities/Material';
import {
  PAPERWORK_FIELD_DATATYPE,
  PaperworkFieldValueDraft,
  addDraftIdToPaperworkFieldValue,
} from '../../../../../entities/Paperwork';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { ContextField, usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';

import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { capitalize } from '../../../../../utils/strings';
import { Currency } from '../../../../Currency/currency';
import { DetailPanel, DetailPanelRow } from '../../../PaperworkDetail/DetailPanel';
import { SerialNumberSchema } from './SerialNumberInputComponent/SerialNumber';
import SerialNumberComponent from './SerialNumberInputComponent/SerialNumberComponent';
import { MaterialTransferModeValues } from './useMaterialTransferModeColumn';
import { UNITY_OF_MEASURE } from './useMaterialUnityOfMeasureColumn';

interface EditMaterialDialogFormProps {
  formId: string;
  onSubmit: (v: Record<string, unknown>) => void;
  onReset: () => void;
  row: Material & Partial<Record<string, unknown>>;
}

type ContextFieldsMap = {
  [key in PAPERWORK_FIELD_DATATYPE]?: ContextField;
};

interface FormValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface DynamicInputProps {
  name: string;
  children: (field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => React.ReactNode;
}

const DynamicInput: React.FC<DynamicInputProps> = ({ name, children }) => {
  const { control } = useFormContext<FormValues>();

  return (
    <Controller name={name} control={control} render={({ field, fieldState }) => <>{children(field, fieldState)}</>} />
  );
};

function EditMaterialFieldsDialogFormComponent(props: EditMaterialDialogFormProps) {
  const { formId, row } = props;

  const fieldsKeys = useMemo(() => {
    return new Set(Object.keys(row));
  }, [row]);

  const { contextFields } = usePaperworkFields();
  const { contextFields: techDataContextFields } = usePaperworkFields({
    subsection: 'tech-data',
  });

  const { data: currencies } = useReadCurrencyListQuery();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const getSelectedCurrencySymbol = (c: Currency['id']) => {
    if (!c || !currenciesById?.has(c)) return '-';
    return currenciesById.get(c)?.symbol;
  };

  const contextFieldsMap: ContextFieldsMap = [...contextFields, ...techDataContextFields].reduce(
    (acc: ContextFieldsMap, field) => {
      acc[field.datatype] = field;
      return acc;
    },
    {}
  );

  const resolver = z
    .object({
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]
          .isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA]
          .isRequired
          ? z.boolean()
          : z.boolean().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION
        ].isRequired
          ? z.string().min(1, 'Campo obbligatorio')
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI
        ].isRequired
          ? z.string()
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN
        ].isRequired
          ? z.string()
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER
        ].isRequired
          ? z.string().min(1, 'Campo obbligatorio')
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]
          .isRequired
          ? z.string().datetime()
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW]
          .isRequired
          ? z.string().min(1, 'Campo obbligatorio')
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE
        ].isRequired
          ? z.nativeEnum(UNITY_OF_MEASURE)
          : z.nativeEnum(UNITY_OF_MEASURE).nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]
          .isRequired
          ? z.boolean()
          : z.boolean().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]
          .isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]
          .isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION
        ].isRequired
          ? z.string()
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE
        ].isRequired
          ? z.string()
          : z.string().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      // it's a FE field to enable the selection of order if the user want to insert it
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL] && {
        showOrder: z.boolean(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL
        ].isRequired
          ? z.boolean()
          : z.boolean().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER
        ].isRequired
          ? z.array(SerialNumberSchema)
          : z.array(SerialNumberSchema).nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
      ...(contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN] && {
        [PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN]: contextFieldsMap[
          PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN
        ].isRequired
          ? z.number()
          : z.number().nullable().optional(),
      }),
    })
    .superRefine((val, ctx) => {
      if (val[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]) {
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE])
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE],
            message: 'Campo obbligatorio',
            expected: 'number',
            received: 'undefined',
          });
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION])
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION],
            message: 'Campo obbligatorio',
            expected: 'string',
            received: 'undefined',
          });
      }
      if (
        !val[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE] &&
        !!contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]
      ) {
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE])
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE],
            message: 'Campo obbligatorio',
            expected: 'number',
            received: 'undefined',
          });
        if (
          !val[PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE] &&
          contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE]
        )
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE],
            message: 'Campo obbligatorio',
            expected: 'string',
            received: 'undefined',
          });
        if (
          !val[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE] &&
          contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE]
        )
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE],
            message: 'Campo obbligatorio',
            expected: 'string',
            received: 'undefined',
          });
      }
      if (
        val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA] &&
        contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE] &&
        contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE]
      ) {
        if (
          !val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE] &&
          !val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE]
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE],
            message: 'Obbligatorio inserire uno dei due valori',
            expected: 'number',
            received: 'undefined',
          });
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE],
            message: 'Obbligatorio inserire uno dei due valori',
            expected: 'number',
            received: 'undefined',
          });
        }
      }
      if (
        val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL] &&
        contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]
      ) {
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE],
            message: 'Campo obbligatorio',
            expected: 'number',
            received: 'undefined',
          });
        }
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY],
            message: 'Campo obbligatorio',
            expected: 'number',
            received: 'undefined',
          });
        }
      }
      if (val['showOrder'] && contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]) {
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER],
            message: 'Campo obbligatorio',
            expected: 'string',
            received: 'undefined',
          });
        }
        if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE],
            message: 'Campo obbligatorio',
            expected: 'string',
            received: 'undefined',
          });
        }
      }
    });

  const methods = useForm({
    defaultValues: {
      ...row,
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA]: row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA] || false,
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]: row[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE] || false,
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN]:
        row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN] || row['techDataClassification'] || '',
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI]:
        row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI] || row['techDataSerniCode'] || '',
      showOrder: contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]
        ? !!row[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]
        : true,
    } as Material & Partial<Record<string, unknown>>,
    resolver: zodResolver(resolver),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(v: any) {
    delete v.showOrder; // Remove FE input
    props.onSubmit(v);
  }

  // console.log(contextFieldsMap);
  // console.log(methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY));
  // console.log(methods.formState.errors);
  // console.log(row);

  return (
    <FormProvider {...methods}>
      <Stack direction={{ md: 'row', xs: 'column' }} gap={3} sx={{ mt: 1 }}>
        <Stack
          id={formId}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          direction="column"
          gap={2}
          sx={{ flex: 1 }}
        >
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE) && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE}>
                {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                  <TextField
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]?.label}
                    variant="outlined"
                    select
                    {...field}
                    error={Boolean(fieldState.error)}
                    value={field.value ?? ''}
                    helperText={fieldState.error?.message ?? ' '}
                    sx={{ '& .MuiInputBase-input': { display: 'flex', justifyContent: 'start' }, minWidth: '300px' }}
                  >
                    {Object.values(MaterialTransferModeValues).map((v) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL) && (
            <>
              <Stack direction="row" sx={{ marginBottom: 1 }}>
                <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL}>
                  {(field: ControllerRenderProps<FormValues, string>) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(field.value)}
                          onChange={(_, checked) => {
                            field.onChange(checked);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY, null);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE, null);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER, '');
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE, undefined);
                          }}
                        />
                      }
                      label={'Sono previsti pagamenti per la transazione?'}
                      labelPlacement="start"
                    />
                  )}
                </DynamicInput>
              </Stack>
              {!!methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL) &&
                fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE) && (
                  <>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={7}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <DecimalTextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              error={Boolean(fieldState.error)}
                              value={field.value ?? ('' as unknown as number)}
                              onChange={(_, value) => field.onChange(value)}
                              helperText={fieldState.error?.message ?? ' '}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {getSelectedCurrencySymbol(
                                      methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                      <Grid item xs={3}>
                        {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) && (
                          <>
                            <Stack direction="row" sx={{ marginBottom: 1 }}>
                              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY}>
                                {(
                                  field: ControllerRenderProps<FormValues, string>,
                                  fieldState: ControllerFieldState
                                ) => (
                                  <CurrencySelect
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    value={field.value ?? ''}
                                    helperText={fieldState.error?.message ?? ' '}
                                  />
                                )}
                              </DynamicInput>
                            </Stack>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Stack direction="row" sx={{ marginBottom: 1 }}>
                      <DynamicInput name={'showOrder'}>
                        {(field: ControllerRenderProps<FormValues, string>) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Boolean(field.value)}
                                onChange={(_, checked) => {
                                  field.onChange(checked);
                                  methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER, '');
                                  methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE, '');
                                }}
                              />
                            }
                            label={'É presente un ordine?'}
                            labelPlacement="start"
                          />
                        )}
                      </DynamicInput>
                    </Stack>
                    {!methods.watch('showOrder') && (
                      <Alert
                        severity="warning"
                        sx={{
                          marginTop: 2,
                        }}
                      >
                        {`Bisognerà fornire l'ordine direttamente a global trade appena disponibile`}
                      </Alert>
                    )}
                  </>
                )}
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER) && !!methods.watch('showOrder') && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER}>
                {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                  <TextField
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]?.label}
                    variant="outlined"
                    fullWidth
                    {...field}
                    error={Boolean(fieldState.error)}
                    value={field.value ?? ''}
                    helperText={fieldState.error?.message ?? ' '}
                  />
                )}
              </DynamicInput>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE}>
                {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                  <DatePicker
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]?.label}
                    {...field}
                    value={new Date(field.value)}
                    onChange={(date) => field.onChange(date?.toISOString())}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        helperText: fieldState.error?.message ?? ' ',
                        error: Boolean(fieldState.error),
                      },
                    }}
                    sx={{ marginTop: 1 }}
                  />
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW) && (
            <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW}>
              {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                <TextField
                  label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW]?.label}
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={Boolean(fieldState.error)}
                  value={field.value ?? ''}
                  helperText={fieldState.error?.message ?? ' '}
                />
              )}
            </DynamicInput>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY) && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY}>
                {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                  <IntegerTextField
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]?.label}
                    variant="outlined"
                    {...field}
                    value={field.value ?? ('' as unknown as number)}
                    error={Boolean(fieldState.error)}
                    onChange={(_, value) => field.onChange(value)}
                    helperText={fieldState.error?.message ?? ' '}
                  />
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER) && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER}>
                {(field: ControllerRenderProps<FormValues, string>) => (
                  <SerialNumberComponent value={field.value || []} onChange={(v) => field.onChange(v)} />
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE) && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE}>
                {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                  <TextField
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE]?.label}
                    variant="outlined"
                    select
                    fullWidth
                    {...field}
                    error={Boolean(fieldState.error)}
                    value={field.value ?? ''}
                    helperText={fieldState.error?.message ?? ' '}
                  >
                    {Object.values(UNITY_OF_MEASURE).map((um) => (
                      <MenuItem key={um} value={um}>
                        {capitalize(um)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN) && (
            <>
              <Stack direction="row" sx={{ marginBottom: 1 }}>
                <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN}>
                  {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                    <DecimalTextField
                      label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN]?.label}
                      variant="outlined"
                      fullWidth
                      {...field}
                      error={Boolean(fieldState.error)}
                      value={field.value ?? ('' as unknown as number)}
                      onChange={(_, value) => field.onChange(value)}
                      helperText={fieldState.error?.message ?? ' '}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getSelectedCurrencySymbol(
                              methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </DynamicInput>
              </Stack>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN) && (
            <>
              <Stack direction="row" sx={{ marginBottom: 1 }}>
                <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN}>
                  {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                    <DecimalTextField
                      label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN]?.label}
                      variant="outlined"
                      fullWidth
                      {...field}
                      error={Boolean(fieldState.error)}
                      value={field.value ?? ('' as unknown as number)}
                      onChange={(_, value) => field.onChange(value)}
                      helperText={fieldState.error?.message ?? ' '}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getSelectedCurrencySymbol(
                              methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </DynamicInput>
              </Stack>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) &&
            !fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL) && (
              <>
                <Stack direction="row" sx={{ marginBottom: 1 }}>
                  <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY}>
                    {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                      <CurrencySelect
                        {...field}
                        error={Boolean(fieldState.error)}
                        value={field.value ?? ''}
                        helperText={fieldState.error?.message ?? ' '}
                      />
                    )}
                  </DynamicInput>
                </Stack>
              </>
            )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) && (
            <>
              <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE}>
                {(field: ControllerRenderProps<FormValues, string>) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(field.value)}
                        onChange={(_, checked) => {
                          field.onChange(checked);
                          methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE, null);
                          methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION, '');
                          methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE, null);
                          methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE, null);
                          methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE, null);
                        }}
                      />
                    }
                    label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]?.label}
                  />
                )}
              </DynamicInput>
            </>
          )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) &&
            !!methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) && (
              <>
                {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE) && (
                  <>
                    <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE}>
                      {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                        <DecimalTextField
                          label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE]?.label}
                          variant="outlined"
                          fullWidth
                          {...field}
                          error={Boolean(fieldState.error)}
                          value={field.value ?? ('' as unknown as number)}
                          onChange={(_, value) => field.onChange(value)}
                          helperText={fieldState.error?.message ?? ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getSelectedCurrencySymbol(
                                  methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </DynamicInput>
                  </>
                )}
                {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION) && (
                  <>
                    <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION}>
                      {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                        <TextField
                          label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_JUSTIFICATION]?.label}
                          variant="outlined"
                          fullWidth
                          {...field}
                          error={Boolean(fieldState.error)}
                          value={field.value ?? ''}
                          helperText={fieldState.error?.message ?? ' '}
                        />
                      )}
                    </DynamicInput>
                  </>
                )}
              </>
            )}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) &&
            !methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) && (
              <>
                {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE) && (
                  <>
                    <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE}>
                      {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                        <DecimalTextField
                          label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]?.label}
                          variant="outlined"
                          fullWidth
                          {...field}
                          error={Boolean(fieldState.error)}
                          value={field.value ?? ('' as unknown as number)}
                          onChange={(_, value) => field.onChange(value)}
                          helperText={fieldState.error?.message ?? ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getSelectedCurrencySymbol(
                                  methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </DynamicInput>
                  </>
                )}
                {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE) && (
                  <>
                    <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE}>
                      {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                        <DecimalTextField
                          label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE]?.label}
                          variant="outlined"
                          fullWidth
                          {...field}
                          error={Boolean(fieldState.error)}
                          value={field.value ?? ('' as unknown as number)}
                          onChange={(_, value) => field.onChange(value)}
                          helperText={fieldState.error?.message ?? ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getSelectedCurrencySymbol(
                                  methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </DynamicInput>
                  </>
                )}
                {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE) && (
                  <>
                    <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE}>
                      {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                        <DecimalTextField
                          label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE]?.label}
                          variant="outlined"
                          fullWidth
                          {...field}
                          error={Boolean(fieldState.error)}
                          value={field.value ?? ('' as unknown as number)}
                          onChange={(_, value) => field.onChange(value)}
                          helperText={fieldState.error?.message ?? ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getSelectedCurrencySymbol(
                                  methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </DynamicInput>
                  </>
                )}
              </>
            )}
          {/* Tech Data Section */}
          {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA) && (
            <>
              <Stack direction="row" sx={{ marginBottom: 1 }}>
                <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA}>
                  {(field: ControllerRenderProps<FormValues, string>) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(field.value)}
                          onChange={(_, checked) => {
                            field.onChange(checked);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION, '');
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY, null);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE, null);
                            methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE, null);
                          }}
                        />
                      }
                      label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA]?.label}
                    />
                  )}
                </DynamicInput>
              </Stack>
              {!!methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA) && (
                <Box>
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI) && (
                      <Grid item xs={3}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <TextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              disabled={!!row['techDataSerniCode']}
                              error={Boolean(fieldState.error)}
                              value={field.value ?? ''}
                              helperText={fieldState.error?.message ?? ' '}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN) && (
                      <Grid item xs={3}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <TextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_ECCN]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              disabled={!!row['techDataClassification']}
                              error={Boolean(fieldState.error)}
                              value={field.value ?? ''}
                              helperText={fieldState.error?.message ?? ' '}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION) && (
                      <Grid item xs={6}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <TextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              error={Boolean(fieldState.error)}
                              value={field.value ?? ''}
                              helperText={fieldState.error?.message ?? ' '}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY) && (
                      <Grid item xs={4}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <IntegerTextField
                              label={
                                contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY]?.label
                              }
                              variant="outlined"
                              fullWidth
                              {...field}
                              value={field.value ?? ('' as unknown as number)}
                              error={Boolean(fieldState.error)}
                              onChange={(_, value) => field.onChange(value)}
                              helperText={fieldState.error?.message ?? ' '}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE) && (
                      <Grid item xs={4}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <DecimalTextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              error={Boolean(fieldState.error)}
                              value={field.value ?? ('' as unknown as number)}
                              onChange={(_, value) => field.onChange(value)}
                              helperText={fieldState.error?.message ?? ' '}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                              }}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                    {fieldsKeys.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE) && (
                      <Grid item xs={4}>
                        <DynamicInput name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE}>
                          {(field: ControllerRenderProps<FormValues, string>, fieldState: ControllerFieldState) => (
                            <DecimalTextField
                              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE]?.label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              value={field.value ?? ('' as unknown as number)}
                              error={Boolean(fieldState.error)}
                              onChange={(_, value) => field.onChange(value)}
                              helperText={fieldState.error?.message ?? ' '}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                              }}
                            />
                          )}
                        </DynamicInput>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Stack>
        <Stack direction="column">
          <Typography variant="h6" gutterBottom>
            {'Dati inseriti'}
          </Typography>
          <DetailPanel>
            <DetailPanelRow rowTitle={'P/N'} rowValue={row.pn ?? '—'}></DetailPanelRow>
            <DetailPanelRow rowTitle={'Descrizione'} rowValue={row.description} ellipsis></DetailPanelRow>
            <DetailPanelRow rowTitle={'Programma'} rowValue={row.program ?? '—'}></DetailPanelRow>
            <DetailPanelRow
              rowTitle={'Serni'}
              rowValue={`${row.serniCode} - ${row.serniCodeDescription}`}
            ></DetailPanelRow>
            <DetailPanelRow rowTitle={'Classifica'} rowValue={row.eccn ?? '—'}></DetailPanelRow>
            <DetailPanelRow rowTitle={'HTS'} rowValue={row.hts ?? '—'}></DetailPanelRow>
          </DetailPanel>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

interface EditMaterialDialogProps {
  open: boolean;
  onClose: () => void;
  row: Material & Partial<Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>>;
}

function EditMaterialFieldsDialogComponent(props: EditMaterialDialogProps) {
  const { open, onClose, row } = props;
  const titleId = useId();
  const formId = useId();

  const { updateValue } = usePaperworkFields();

  const { pushNotification } = useNotifications();

  const handleClose = () => {
    onClose();
  };

  const parseNumberOrNull = (v: string | null | undefined) => (v === null || v === undefined ? null : Number(v));

  // Convert the fieldValues into some primitive values to be handled by the form
  const updatedRow = useMemo(() => {
    const convertFromPaperworkFieldValueDraftToValue = (
      k: PAPERWORK_FIELD_DATATYPE,
      v: PaperworkFieldValueDraft
    ): unknown => {
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA) return v.value === 'true';
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL) return v.value === 'true';
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_DESCRIPTION) return v.value;
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SERNI) {
        return v.value;
      }
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_SALE_VALUE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA_CUSTOMS_VALUE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER) return v.value;
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW) return v.value;
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE) return v.value;
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE) return v.value;
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE_CUSTOM_VALUE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE) {
        return parseNumberOrNull(v.value);
      }
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) return parseNumberOrNull(v.value);
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE) return v.value === 'true';
      if (k === PAPERWORK_FIELD_DATATYPE.MATERIAL_SERIAL_NUMBER) {
        if (v.value) {
          try {
            const result = SerialNumberSchema.array().safeParse(JSON.parse(v.value));
            if (!result.success) {
              return [];
            }
            return result.data;
          } catch (error) {
            return [];
          }
        }
        return [];
      }
      return v.value;
    };

    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        return [
          key,
          (value as unknown) && Object.prototype.hasOwnProperty.call(value, 'value')
            ? convertFromPaperworkFieldValueDraftToValue(
                key as PAPERWORK_FIELD_DATATYPE,
                value as PaperworkFieldValueDraft
              )
            : value && Object.prototype.hasOwnProperty.call(value, 'fieldDatatype') //If the property value is missing from the object use the null instead
              ? null
              : value,
        ];
      })
    ) as Material & Partial<Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>>;
  }, [row]);

  const onSubmit = (v: Record<string, unknown>) => {
    Object.entries(v).forEach(([key, value]) => {
      updateValue(
        addDraftIdToPaperworkFieldValue({
          fieldDatatype: key as PAPERWORK_FIELD_DATATYPE,
          progIndex: 0,
          materialID: row.id,
          value:
            typeof value === 'string' ? value : value === null || value === undefined ? null : JSON.stringify(value),
          id: (row[key as PAPERWORK_FIELD_DATATYPE] as PaperworkFieldValueDraft)?.id,
        })
      );
    });

    pushNotification(`Dati del P/N ${row.pn} aggiornati`);
    onClose();
  };

  return (
    <Dialog open={open} aria-labelledby={titleId} fullWidth maxWidth="lg">
      <DialogTitle id={titleId}>{`Modifica campi materiale${row.pn ? ` (P/N: ${row.pn})` : ''}`}</DialogTitle>
      <DialogContent>
        {row && (
          <EditMaterialFieldsDialogFormComponent
            formId={formId}
            onSubmit={onSubmit}
            onReset={handleClose}
            row={updatedRow}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="reset" form={formId} color="inherit" onClick={() => handleClose()}>
          {'Annulla'}
        </Button>
        <Button type="submit" form={formId}>
          {'Modifica'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export const EditMaterialFieldsDialog = React.memo(EditMaterialFieldsDialogComponent);
