import React from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { SerniListUpdateStatusChip } from '../../../components/DataGrid/useSerniListUpdateStatusColumn';
import { DetailSection } from '../../../components/DetailSection';
import { PriorityInlineBadgeLabel } from '../../../components/InlineBadgeLabel/PriorityInlineBadgeLabel';
import { Layout } from '../../../components/Layout';
import { PageTitle } from '../../../components/PageTitle';
import { UserName } from '../../../components/User';
import { SERNI_LIST_UPDATE_STATUS } from '../../../entities/SerniListUpdate';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { formatDateTime } from '../../../utils/dates';
import { DetailPanel, DetailPanelRow } from '../../Paperwork/PaperworkDetail/DetailPanel';
import { Navigation } from './Navigation';
import { SerniListUpdateMenu } from './SerniListUpdateMenu';

function SerniListUpdateDetailPageComponent() {
  const params = useParams<{ id: string }>();

  const { serniListUpdate } = useSerniListUpdate();

  const assignee = serniListUpdate?.assignee;

  const pageTitle = `Richiesta aggiornamento lista Serni #${params.id}`;

  return (
    <Layout
      title={pageTitle}
      header={
        <PageTitle
          pageTitle={pageTitle}
          rightItems={
            <Stack direction="row" gap={2}>
              {serniListUpdate ? <SerniListUpdateMenu serniListUpdate={serniListUpdate} /> : null}
              {/* TODO:  */}
              {/* {serniListUpdate ? <HistoryDrawer serniListUpdate={serniListUpdate} /> : null} */}
            </Stack>
          }
          sx={{ marginBottom: 0 }}
        />
      }
      maxWidth={false}
    >
      <Stack direction="column" gap={2}>
        <DetailSection sectionTitle="Informazioni pratica">
          <Stack direction="row" alignItems="flex-start" flexWrap="wrap" gap={2}>
            <DetailPanel>
              <DetailPanelRow rowTitle="ID Pratica" rowValue={`#${params.id}`} />
              <DetailPanelRow rowTitle="Data creazione" rowValue={formatDateTime(serniListUpdate?.createdAt)} />
              <DetailPanelRow rowTitle="Data ultima modifica" rowValue={formatDateTime(serniListUpdate?.updatedAt)} />
              <DetailPanelRow
                rowTitle="Priorità"
                rowValue={<PriorityInlineBadgeLabel priority={serniListUpdate?.priority} />}
              />
              {assignee ? (
                <DetailPanelRow
                  rowTitle={
                    serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.DRAFT ||
                    serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.RETURNED
                      ? 'Assegnata a'
                      : 'In carico a'
                  }
                  rowValue={<UserName username={assignee} />}
                />
              ) : null}

              <DetailPanelRow
                direction="row"
                rowTitle="Stato"
                rowValue={
                  serniListUpdate?.status ? <SerniListUpdateStatusChip status={serniListUpdate?.status.id} /> : '—'
                }
                sx={{ paddingTop: 1 }}
              />
            </DetailPanel>
          </Stack>
        </DetailSection>
        <Navigation />
      </Stack>
    </Layout>
  );
}

export const SerniListUpdateDetailPage = React.memo(SerniListUpdateDetailPageComponent);
