import { useMemo } from 'react';
import { z } from 'zod';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';

export const FreeValueSchema = z.object({
  freeValue: z.boolean(),
  motivation: z.string(),
});

export function useMaterialFreeSaleColumn(): GridColDef {
  return useMemo(
    () => ({
      headerName: 'Vendita a titolo gratuito',
      type: 'boolean',
      width: 140,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]?.value;
      },
    }),
    []
  );
}
