import React from 'react';
import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface PageTitleProps extends StackProps {
  rightItems?: React.ReactNode | React.ReactNode[];
  pageTitle: string;
}

const PageTitleClasses = {
  root: 'PageTitle-root',
  title: 'PageTitle-title',
};

function PageTitleComponent(props: PageTitleProps) {
  const { children, pageTitle, rightItems, ...stackProps } = props;
  return (
    <Stack
      className={PageTitleClasses.root}
      direction="row"
      alignItems="baseline"
      {...stackProps}
      gap={1}
      sx={{
        marginBottom: 1,
        ...stackProps.sx,
      }}
    >
      <Typography className={PageTitleClasses.title} component="h1" variant="h5">
        {pageTitle}
      </Typography>
      {children}
      <Box flex={1} />
      {rightItems}
    </Stack>
  );
}

export const PageTitle = React.memo(PageTitleComponent);
