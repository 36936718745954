import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GridActionsCellItem,
  GridPinnedColumnFields,
  GridRowModel,
  GridRowParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { DataGrid, DataGridProps, ErrorAlert } from '@top-solution/microtecnica-mui';
import { DeleteIcon } from '../../components/Icons';
import { PAPERWORK_STATUS, SearchPaperworkListPayload, Paperwork } from '../../entities/Paperwork';
import { useSearchPaperworkListQuery } from '../../services/paperwork';
import { useColumns } from './columns';
import { PaperworkDeleteDialog } from './PaperworkDeleteDialog';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

type PaperworkListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
  advancedFilters: SearchPaperworkListPayload;
};

export default function PaperworkListGrid(props: PaperworkListGridProps): JSX.Element {
  const { columns, ...gridProps } = props;
  const navigate = useNavigate();
  const paperworkColumns = useColumns();
  const { isLoading, data, error } = useSearchPaperworkListQuery(props.advancedFilters);
  const [paperworkIdToDelete, setPaperworkIdToDelete] = useState<number | null>(null);

  const rows = useMemo<GridRowModel[]>(() => data?.map((item) => ({ ...item })) ?? [], [data]);

  const dataGridColumns = useMemo(
    () => [
      ...(columns || paperworkColumns),
      {
        field: 'actions',
        type: 'actions' as const,
        headerName: 'Azioni',
        hideable: false,
        width: 90,
        getActions: ({ row }: GridRowParams<Paperwork>) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina Pratica"
            onClick={() => setPaperworkIdToDelete(row.id)}
            disabled={row.status.id !== PAPERWORK_STATUS.DRAFT}
            showInMenu
          />,
        ],
      },
    ],
    [columns, paperworkColumns]
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <DataGrid
        density="compact"
        rows={rows}
        columns={dataGridColumns}
        loading={isLoading}
        pagination
        pinnedColumns={pinnedColumns}
        disableRowGrouping
        disableAggregation
        onRowClick={(row) => navigate(`/paperworks/${row.id}`)}
        {...gridProps}
        sx={{
          border: 0,
          [`.${gridClasses.row}`]: {
            cursor: 'pointer',
          },
        }}
      />
      {paperworkIdToDelete && (
        <PaperworkDeleteDialog
          open={!!paperworkIdToDelete}
          paperworkId={paperworkIdToDelete}
          handleClose={() => {
            setPaperworkIdToDelete(null);
          }}
          handleConfirm={() => {
            setPaperworkIdToDelete(null);
          }}
        />
      )}
    </>
  );
}
