import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';

export const materialDescriptionColumn: GridColDef<GridValidRowModel, string> = {
  headerName: 'Descrizione',
  type: 'string',
  field: 'description',
  flex: 2,
  minWidth: 200,
  renderCell: ({ value }) => value ?? '—',
};
