import { useCallback, useMemo, useState } from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridEventListener,
  GridPinnedColumnFields,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-premium';
import { DataGrid, DataGridProps, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { Country, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { CancelIcon, EditIcon, SaveIcon } from '../../components/Icons';
import { CountryCategorySchema, EnabledCountry } from '../../entities/Country';
import {
  useDisableCountryMutation,
  useEnableCountryMutation,
  useUpdateEnabledCountryMutation,
} from '../../services/countries';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

type CurrencyListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'error'
  | 'columns'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & { enabledCountryIdList: Array<EnabledCountry> };

type CountryListRow = Country & { enabled: boolean; countryCategory: EnabledCountry['category'] };

export default function CountryListGrid(props: CurrencyListGridProps): JSX.Element {
  const { enabledCountryIdList, ...gridProps } = props;
  const countryColumn = useCountryGridColDef({ format: 'png', hideLabel: true });
  const { data: countries } = useReadCountriesQuery();
  const rows = useMemo<GridRowModel<Country>[]>(
    () =>
      countries?.list.map((item) => {
        const enabledCountry = enabledCountryIdList.find((c) => c.code === item.id);
        return { ...item, enabled: !!enabledCountry, countryCategory: enabledCountry?.category };
      }) ?? [],
    [countries?.list, enabledCountryIdList]
  );
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [enable] = useEnableCountryMutation();
  const [updateEnabledCountry] = useUpdateEnabledCountryMutation();
  const [disable] = useDisableCountryMutation();

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const processRowUpdate = async (newRow: GridRowModel<CountryListRow>, oldRow: GridRowModel<CountryListRow>) => {
    if (newRow.enabled && !oldRow.enabled) {
      await enable({ code: newRow.id, category: newRow.countryCategory }).unwrap();
    }
    if (newRow.enabled && oldRow.enabled) {
      await updateEnabledCountry({ code: newRow.id, category: newRow.countryCategory }).unwrap();
    }
    if (!newRow.enabled && oldRow.enabled) {
      await disable({ id: newRow.id }).unwrap();
    }
    return { ...newRow, isNew: false };
  };

  const handleProcessRowUpdateError = useCallback((error: { data: { message: string } }) => {
    setSnackbar({ children: error.data.message, severity: 'error' });
  }, []);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'id', headerName: 'ID', filterable: true, width: 80 },
      {
        ...countryColumn,
        field: 'flag',
        valueGetter: (value, row: Country) => row.id,
        headerName: 'Bandiera',
        filterable: false,
        sortable: false,
        disableExport: true,
        width: 80,
      },
      { field: 'name', headerName: 'Nome', filterable: true, flex: 1 },
      {
        field: 'countryCategory',
        headerName: 'Categoria paese',
        filterable: true,
        width: 140,
        editable: true,
        type: 'singleSelect',
        valueOptions: Object.values(CountryCategorySchema.Values),
      },
      { field: 'geonomCode', headerName: 'Codice Geonom', filterable: true, width: 120 },
      { field: 'enabled', headerName: 'Abilitata', filterable: true, width: 180, type: 'boolean', editable: true },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Azioni',
        hideable: false,
        width: 90,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Salva"
                key={'save'}
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                key={'Cancella'}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modifica"
              key={'edit'}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [countryColumn, handleCancelClick, handleEditClick, handleSaveClick, rowModesModel]
  );

  return (
    <>
      <DataGrid
        density="compact"
        columns={columns}
        pinnedColumns={pinnedColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableRowGrouping
        disableAggregation
        {...gridProps}
        rows={rows}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
