import { z } from 'zod';

export const BillingYearSchema = z.object({
  billingYear: z.number(),
  supplierValue: z.number(),
  customerValue: z.number(),
});

export const AddBillingYearSchema = z.object({
  billingYear: z.date(),
  supplierValue: z.coerce.number(),
  customerValue: z.coerce.number(),
});

export const BillingYearValueSchema = BillingYearSchema.extend({
  progIndex: z.number(),
  id: z.number().optional(),
});

export type BillingYear = z.infer<typeof BillingYearSchema>;
export type AddBillingYear = z.infer<typeof AddBillingYearSchema>;
export type BillingYearValue = z.infer<typeof BillingYearValueSchema>;
