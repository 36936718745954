import { z } from 'zod';

export const PecSettingsSchema = z.object({
  to: z.array(z.string().email()),
});

export const PecStatusSchema = z.object({
  acceptedAt: z.string().datetime().optional(),
  acceptedStatus: z.string().optional(),
  pecReference: z.string(),
  receivedAt: z.string().datetime().optional(),
  receivedStatus: z.string().optional(),
  sentAt: z.string().datetime(),
  sentBy: z.string(),
});

export type PecSettings = z.infer<typeof PecSettingsSchema>;
export type PecStatus = z.infer<typeof PecStatusSchema>;
