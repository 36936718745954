import { useCallback, useMemo, useState } from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridEventListener,
  GridPinnedColumnFields,
  GridPreProcessEditCellProps,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-premium';
import { ConfirmDialog, DataGrid, DataGridProps } from '@top-solution/microtecnica-mui';
import { AddIcon, CancelIcon, DeleteIcon, EditIcon, SaveIcon } from '../../components/Icons';
import {
  useCreateCurrencyMutation,
  useDeleteCurrencyMutation,
  useUpdateCurrencyMutation,
} from '../../services/currency';
import { Currency, CurrencySchema } from './currency';
import { CURRENCY_VALUES } from './currencyValues';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

type CurrencyListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'error'
  | 'columns'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & { currencies: Array<Currency> };

export default function CurrencyListGrid(props: CurrencyListGridProps): JSX.Element {
  const { currencies, ...gridProps } = props;
  const rows = useMemo<GridRowModel<Currency>[]>(() => currencies?.map((item) => ({ ...item })) ?? [], [currencies]);
  const [tempRow, setTempRow] = useState<GridRowModel | null>(null);

  const [idCurrencyToDelete, setIdCurrencyToDelete] = useState<number | null>();

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [create] = useCreateCurrencyMutation();
  const [deleteCurrency] = useDeleteCurrencyMutation();
  const [update] = useUpdateCurrencyMutation();

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleCreateClick = useCallback(() => {
    const id = Math.max(...currencies.map((r) => r.id)) + 1;
    setTempRow({ id, name: '', code: '', symbol: '', isNew: true });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  }, [currencies]);

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => async () => {
      setIdCurrencyToDelete(id as number);
    },
    []
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      if (tempRow && tempRow.id === id) {
        setTempRow(null);
      }
    },
    [rowModesModel, tempRow]
  );

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.isNew) {
      const newCurrency = CurrencySchema.parse(newRow);
      await create(newCurrency).unwrap();
      setTempRow(null);
    } else {
      const updatedCurrency = CurrencySchema.parse(newRow);
      await update(updatedCurrency).unwrap();
    }
    return { ...newRow, isNew: false };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleProcessRowUpdateError = useCallback((error: any) => {
    setSnackbar({ children: error.data.message || error.message, severity: 'error' });
  }, []);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 90, type: 'number' },
      {
        field: 'code',
        headerName: 'Codice',
        width: 90,
        editable: true,
        type: 'singleSelect',
        valueOptions: CURRENCY_VALUES.map((v) => v.alpha),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = params.props.value.length < 3;
          return { ...params.props, error: hasError };
        },
      },
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        type: 'string',
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = params.props.value.length < 3;
          return { ...params.props, error: hasError };
        },
        cellClassName: () => 'currency-name',
      },
      {
        field: 'symbol',
        headerName: 'Simbolo',
        width: 90,
        editable: true,
        type: 'string',
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = params.props.value.length < 1;
          return { ...params.props, error: hasError };
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        hideable: false,
        width: 90,
        renderHeader: () => (
          <Tooltip title={'Aggiungi valuta'} placement="left">
            <IconButton color="primary" onClick={handleCreateClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        ),
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                key={'save'}
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                key={'cancel'}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              key={'edit'}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              key={'delete'}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [handleCancelClick, handleCreateClick, handleDeleteClick, handleEditClick, handleSaveClick, rowModesModel]
  );

  const renderConfirmDialog = () => {
    if (!idCurrencyToDelete) {
      return null;
    }

    return (
      <ConfirmDialog
        maxWidth="xs"
        open={!!idCurrencyToDelete}
        title={'Sei sicuro di voler eliminare la valuta?'}
        confirmText="confermo"
        onConfirm={async function () {
          await deleteCurrency({ id: idCurrencyToDelete });
          setIdCurrencyToDelete(null);
        }}
        onClose={() => setIdCurrencyToDelete(null)}
      ></ConfirmDialog>
    );
  };

  return (
    <>
      {renderConfirmDialog()}
      <DataGrid
        density="compact"
        columns={columns}
        pinnedColumns={pinnedColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableRowGrouping
        disableAggregation
        {...gridProps}
        rows={[...rows, ...(tempRow ? [tempRow] : [])]}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={{
          '& .currency-name .Mui-error': {
            backgroundColor: 'error.main',
            height: '100%',
          },
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
