import React, { useMemo } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertCircleIcon } from '../../../components/Icons';
import { Attachment } from '../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE, PAPERWORK_STATUS } from '../../../entities/Paperwork';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { usePatchPaperworkMutation } from '../../../services/paperwork';
import { formatDateTime } from '../../../utils/dates';
import { AttachmentDeleteDialog } from '../DataEntry/Documents/AttachmentsDeleteDialog';
import { DownloadButton } from '../DataEntry/Documents/DownloadButton';

interface AttachmentCardProps extends CardProps {
  attachment: Attachment;
  disableDelete?: boolean;
}

function SignedPaperworkAttachmentCardComponent(props: AttachmentCardProps) {
  const { attachment, disableDelete, ...cardProps } = props;

  const { paperwork } = usePaperwork();
  const { searchFieldValue } = usePaperworkFields();
  const [patchPaperwork] = usePatchPaperworkMutation();

  const digitallySigned = useMemo(() => {
    const fieldValue = searchFieldValue({
      fieldDatatype: PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED,
      attachmentID: attachment.attachment.id,
    });

    return fieldValue?.value === 'yes';
  }, [attachment.attachment.id, searchFieldValue]);

  return (
    <Card
      {...cardProps}
      sx={{
        flex: 1,
        minWidth: 640,
        maxWidth: 736,
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
            {attachment.attachment.name}
          </Typography>
          <DownloadButton attachment={attachment} />
          {disableDelete ? null : (
            <AttachmentDeleteDialog
              attachment={attachment}
              onDelete={async () => {
                if (paperwork) {
                  await patchPaperwork({
                    paperworkId: paperwork.id,
                    statusId: PAPERWORK_STATUS.PREPARED,
                  }).unwrap();
                }
              }}
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="body2" flex={1}>
            {`Documento caricato il ${formatDateTime(new Date(attachment.attachment.uploadDate))}`}
          </Typography>
          {digitallySigned ? (
            <Chip variant="outlined" color="primary" size="small" label="Firmato digitalmente" />
          ) : (
            <Chip variant="outlined" color="warning" size="small" label="Senza firma" icon={<AlertCircleIcon />} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export const SignedPaperworkAttachmentCard = React.memo(SignedPaperworkAttachmentCardComponent);
