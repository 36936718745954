import { Equipment, EquipmentSchema } from '../entities/Equipment';
import { TAG_TYPES, api } from './baseApi';

const url = 'equipments';

const equipmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEquipmentList: builder.query<{ list: Array<Equipment>; bySerniCode: Record<string, Equipment> }, void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        const array = EquipmentSchema.array().parse(data);
        const map: Record<string, Equipment> = {};
        array.forEach((s) => (map[s.serniCode] = s));
        return { list: array, bySerniCode: map };
      },
      providesTags: () => [{ type: TAG_TYPES.SERNI, id: 'LIST' }],
    }),
    createEquipment: builder.query<{ id: Equipment['id'] }, Omit<Equipment, 'id'>>({
      query: () => ({ url }),
      transformResponse: (data) => EquipmentSchema.parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI, id: 'LIST' }],
    }),
  }),
});

export const { useReadEquipmentListQuery, useCreateEquipmentQuery } = equipmentApi;
