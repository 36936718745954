import React, { useMemo } from 'react';
import { z } from 'zod';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { DecimalTextField } from '@top-solution/microtecnica-mui';
import { PAPERWORK_FIELD_DATATYPE } from '../../../entities/Paperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { Currency } from '../../../pages/Currency/currency';
import { useReadCurrencyListQuery } from '../../../services/currency';
import { AddIcon, DeleteIcon } from '../../Icons';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

const MultipleValueSchema = z.object({
  flag: z.boolean(),
  values: z.number().array(),
});

function MaterialMultipleNumericValueInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);
  const { data: currencies } = useReadCurrencyListQuery();
  const { fieldValues } = usePaperworkFields();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const getSelectedCurrencySymbol = (c: Currency['id']) => {
    if (!c || !currenciesById?.has(c)) return '-';
    return currenciesById.get(c)?.symbol;
  };

  const currencyValue = useMemo(() => {
    const value = Object.values(fieldValues).find(
      (v) => v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY
    )?.value;
    return Number(value);
  }, [fieldValues]);

  const value = useMemo(() => {
    if (!fieldValue?.value) return { flag: false, values: [] };
    return MultipleValueSchema.parse(JSON.parse(fieldValue.value));
  }, [fieldValue]);

  return (
    <Grid container rowSpacing={3} columnSpacing={2} flex={1} paddingTop={3}>
      <Grid item xs={5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.flag}
              onChange={(_, checked) => {
                updateValue(JSON.stringify({ values: checked ? [0] : [], flag: checked }));
              }}
            />
          }
          label={field.label}
        />
      </Grid>
      {value.flag && (
        <Grid item xs={7}>
          {value.values.map((v, i) => (
            <Box key={i} sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>
              <DecimalTextField
                sx={{ width: 'calc(100% - 80px)' }}
                label={`${field.label} nr° ${i + 1}`}
                value={v}
                onChange={(e, v) => {
                  const newValues = [...value.values];
                  newValues[i] = v || 0;
                  return updateValue(JSON.stringify({ flag: value.flag, values: newValues }));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{getSelectedCurrencySymbol(currencyValue)}</InputAdornment>
                  ),
                }}
              />
              {i + 1 === value.values.length && i !== 0 && (
                <IconButton
                  onClick={() => {
                    const newValues = [...value.values.slice(0, -1)];
                    return updateValue(JSON.stringify({ flag: value.flag, values: newValues }));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {i + 1 === value.values.length && (
                <IconButton
                  onClick={() => {
                    const newValues = [...value.values, 0];
                    return updateValue(JSON.stringify({ flag: value.flag, values: newValues }));
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export const MaterialMultipleNumericValueInput = React.memo(MaterialMultipleNumericValueInputComponent);
