import React, { useMemo } from 'react';
import { GridActionsColDef, GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { DataGridWrapper, DataGrid } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { useAvailableCountriesColumn } from '../../components/DataGrid/useAvailableCountriesColumn';
import { useMovementTypeColumn } from '../../components/DataGrid/useMovementTypeColumn';
import { usePaperworkTypeColumn } from '../../components/DataGrid/usePaperworkTypeColumn';
import { useSaleTypeColumn } from '../../components/DataGrid/useSaleTypeColumn';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../entities/User';
import { useReadPaperworkTemplatesListQuery } from '../../services/paperwork';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { paperworkTemplateSection } from './sections';

const PAGE_TITLE = paperworkTemplateSection.title;

export default function PaperworkTemplateList(): React.ReactElement {
  const saleTypeColumn = useSaleTypeColumn();
  const movementTypeColumn = useMovementTypeColumn();
  const typeColumn = usePaperworkTypeColumn();
  const countriesColumn = useAvailableCountriesColumn();

  const { isLoading, data, error } = useReadPaperworkTemplatesListQuery();

  const rows = useMemo<GridRowModel[]>(() => data?.map((item) => ({ ...item })) ?? [], [data]);

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'id', headerName: 'ID', filterable: true, width: 80 },
      { field: 'countryCategory', type: 'string', headerName: 'Categoria paese', filterable: true, width: 100 },
      {
        ...saleTypeColumn,
        width: 150,
      },
      { ...typeColumn, width: 240 },
      { ...movementTypeColumn, width: 330 },
      { ...countriesColumn, flex: 1, maxWidth: 300 },
    ],
    [countriesColumn, movementTypeColumn, saleTypeColumn, typeColumn]
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout title={PAGE_TITLE} header={<PageTitle pageTitle={PAGE_TITLE} />} maxWidth={false} error={error}>
        <DataGridWrapper>
          <DataGrid
            columnVisibilityModel={{
              id: false,
            }}
            density="compact"
            rows={rows}
            columns={columns}
            loading={isLoading}
            pagination
            disableAggregation
            initialState={{
              rowGrouping: {
                model: ['paperworkType', 'saleType', 'movementType'],
              },
            }}
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
