import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { lighten } from '@mui/material/styles';

interface TabPanelProps extends BoxProps {
  index: number;
  value: number;
}

function AttachmentsSectionTabPanelComponent(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`attachment-tabpanel-${index}`}
      aria-labelledby={`attachment-tab-${index}`}
      sx={{ flex: '1 1 0', minHeight: 0, overflow: 'auto', ...sx }}
      {...other}
    >
      {value === index && (
        <Stack direction="column" gap={3} sx={{ backgroundColor: lighten(grey[50], 0.5), minHeight: '100%' }}>
          {children}
        </Stack>
      )}
    </Box>
  );
}

export const AttachmentsSectionTabPanel = React.memo(AttachmentsSectionTabPanelComponent);
