import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { useAuth } from '@top-solution/microtecnica-utils';
import { SERNI_LIST_UPDATE_STATUS, SerniListUpdate } from '../../../entities/SerniListUpdate';
import { serniListUpdateSection } from '../sections';
import { SerniListUpdateDeleteDialog } from '../SerniListUpdateDeleteDialog';

interface SerniListUpdateMenuProps {
  serniListUpdate: SerniListUpdate;
}

function SerniListUpdateMenuComponent(props: SerniListUpdateMenuProps) {
  const { serniListUpdate } = props;
  const { username, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {'Gestisci pratica'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.DRAFT &&
        (serniListUpdate.assignee === username || isAdmin) ? (
          <MenuItem
            onClick={() => {
              setDeleteDialogOpen(true);
              handleClose();
            }}
          >
            {'Elimina richiesta in bozza'}
          </MenuItem>
        ) : null}
      </Menu>
      <SerniListUpdateDeleteDialog
        open={deleteDialogOpen}
        serniListUpdateId={serniListUpdate.id}
        handleClose={() => {
          setDeleteDialogOpen(false);
        }}
        handleConfirm={() => {
          navigate(serniListUpdateSection.path);
        }}
      />
    </div>
  );
}

export const SerniListUpdateMenu = React.memo(SerniListUpdateMenuComponent);
