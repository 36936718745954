import { createListenerMiddleware } from '@reduxjs/toolkit';

import { PatchAttachmentListRequest } from '../../entities/Attachment';
import { DraftOperationEnum } from '../../entities/Drafts';
import { attachmentApi } from '../../services/attachment';
import { paperworksApi } from '../../services/paperwork';
import { AttachmentsStateSlice, replaceAttachments, setSavingState } from '../attachmentsStateSlice';

export const attachmentListenerMiddleware = createListenerMiddleware();

/**
 * Replace cached attachments after the GET query is successful
 */
attachmentListenerMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    if (!action.payload.attachmentList) {
      return;
    }

    listenerApi.dispatch(
      replaceAttachments({
        paperworkId: action.payload.id,
        attachments: action.payload.attachmentList,
      })
    );
  },
});

/**
 * Set the "saving" state to false after a successful save
 */
attachmentListenerMiddleware.startListening({
  matcher: attachmentApi.endpoints.patchAttachmentList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setSavingState({ saving: false }));
  },
});

/**
 * Save the attachments right after "saving"
 */
attachmentListenerMiddleware.startListening({
  actionCreator: setSavingState,
  effect: async (action, listenerApi) => {
    if (action.payload.saving === true) {
      const state = listenerApi.getState() as { attachmentsState: AttachmentsStateSlice };

      const paperworkId = state.attachmentsState.paperworkId;

      if (paperworkId !== undefined) {
        const patchPayload: PatchAttachmentListRequest = {
          paperworkId,
          add: [],
          delete: [],
        };

        for (const [draftId, operation] of Object.entries(state.attachmentsState.operations)) {
          if (operation === DraftOperationEnum.Values.Create) {
            const { attachment, savedInContextId, stakeholderIdList } = state.attachmentsState.attachments[draftId];
            if (attachment) {
              patchPayload.add.push({ attachmentId: attachment.id, stakeholderIdList, savedInContextId });
            }
          } else if (operation === DraftOperationEnum.Values.Delete) {
            const id = state.attachmentsState.originalAttachments[draftId].attachment.id;
            if (id) {
              patchPayload.delete.push({ id });
            }
          }
        }

        listenerApi.dispatch(attachmentApi.endpoints.patchAttachmentList.initiate(patchPayload));

        // Every time occurs a change in the attachment list, flag the attachment page as completed if there
        // is at least one document (create / delete operations are quite atomic in this case)
        listenerApi.dispatch(
          paperworksApi.endpoints.patchPaperworkCompletedPages.initiate({
            id: paperworkId,
            completedPages: {
              attachments: { dataEntryCompleted: Object.values(state.attachmentsState.attachments).length > 0 },
            },
          })
        );
      }
    }
  },
});
