import React from 'react';
import AccountTieOutline from '@mdi/svg/svg/account-tie-outline.svg?react';
import Account from '@mdi/svg/svg/account.svg?react';
import AlertCircle from '@mdi/svg/svg/alert-circle.svg?react';
import Alert from '@mdi/svg/svg/alert.svg?react';
import ArchiveCog from '@mdi/svg/svg/archive-cog.svg?react';
import ArchiveSync from '@mdi/svg/svg/archive-sync.svg?react';
import Archive from '@mdi/svg/svg/archive.svg?react';
import ArrowBottomLeftBoldBoxOutline from '@mdi/svg/svg/arrow-bottom-left-bold-box-outline.svg?react';
import BugOutline from '@mdi/svg/svg/bug-outline.svg?react';
import CashMultiple from '@mdi/svg/svg/cash-multiple.svg?react';
import CheckCircleOutline from '@mdi/svg/svg/check-circle-outline.svg?react';
import Check from '@mdi/svg/svg/check.svg?react';
import ChevronDown from '@mdi/svg/svg/chevron-down.svg?react';
import ChevronLeft from '@mdi/svg/svg/chevron-left.svg?react';
import ChevronRight from '@mdi/svg/svg/chevron-right.svg?react';
import ChevronUp from '@mdi/svg/svg/chevron-up.svg?react';
import ClipboardEditOutline from '@mdi/svg/svg/clipboard-edit-outline.svg?react';
import ClipboardText from '@mdi/svg/svg/clipboard-text.svg?react';
import CloseCircleOutline from '@mdi/svg/svg/close-circle-outline.svg?react';
import Close from '@mdi/svg/svg/close.svg?react';
import ContentDuplicate from '@mdi/svg/svg/content-duplicate.svg?react';
import ContentSave from '@mdi/svg/svg/content-save.svg?react';
import ImportData from '@mdi/svg/svg/database-import.svg?react';
import DotsVertical from '@mdi/svg/svg/dots-vertical.svg?react';
import Download from '@mdi/svg/svg/download.svg?react';
import Earth from '@mdi/svg/svg/earth.svg?react';
import EmailFastOutline from '@mdi/svg/svg/email-fast-outline.svg?react';
import Eye from '@mdi/svg/svg/eye.svg?react';
import FileCogOutline from '@mdi/svg/svg/file-cog-outline.svg?react';
import FileDocumentOutline from '@mdi/svg/svg/file-document-outline.svg?react';
import ExcelFile from '@mdi/svg/svg/file-excel.svg?react';
import File from '@mdi/svg/svg/file.svg?react';
import FormatListCheckbox from '@mdi/svg/svg/format-list-checkbox.svg?react';
import HandshakeOutline from '@mdi/svg/svg/handshake-outline.svg?react';
import InformationOutline from '@mdi/svg/svg/information-outline.svg?react';
import KeyboardOutline from '@mdi/svg/svg/keyboard-outline.svg?react';
import Search from '@mdi/svg/svg/magnify.svg?react';
import Menu from '@mdi/svg/svg/menu.svg?react';
import Message from '@mdi/svg/svg/message.svg?react';
import Minus from '@mdi/svg/svg/minus.svg?react';
import ExternalUrl from '@mdi/svg/svg/open-in-new.svg?react';
import Pencil from '@mdi/svg/svg/pencil.svg?react';
import Add from '@mdi/svg/svg/plus-circle.svg?react';
import ScrewFlatTop from '@mdi/svg/svg/screw-flat-top.svg?react';
import TagTextOutline from '@mdi/svg/svg/tag-text-outline.svg?react';
import TextBoxEditOutline from '@mdi/svg/svg/text-box-edit-outline.svg?react';
import TrashCan from '@mdi/svg/svg/trash-can.svg?react';
import TrayArrowDown from '@mdi/svg/svg/tray-arrow-down.svg?react';
import Upload from '@mdi/svg/svg/upload.svg?react';
import Valve from '@mdi/svg/svg/valve.svg?react';
import ViewDashboardOutline from '@mdi/svg/svg/view-dashboard-outline.svg?react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ClipboardCheck from '../../assets/clipboard-check.svg?react';

const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const AccountTieOutlineIcon = createIcon(<AccountTieOutline />);
export const AddIcon = createIcon(<Add />);
export const AlertCircleIcon = createIcon(<AlertCircle />);
export const AlertIcon = createIcon(<Alert />);
export const ArchiveCogIcon = createIcon(<ArchiveCog />);
export const ArchiveSyncIcon = createIcon(<ArchiveSync />);
export const ArchiveIcon = createIcon(<Archive />);
export const ArrowBottomLeftBoldBoxOutlineIcon = createIcon(<ArrowBottomLeftBoldBoxOutline />);
export const BugOutlineIcon = createIcon(<BugOutline />);
export const CancelIcon = createIcon(<Close />);
export const CashMultipleIcon = createIcon(<CashMultiple />);
export const CheckCircleOutlineIcon = createIcon(<CheckCircleOutline />);
export const CheckIcon = createIcon(<Check />);
export const ChevronDownIcon = createIcon(<ChevronDown />);
export const ChevronLeftIcon = createIcon(<ChevronLeft />);
export const ChevronRightIcon = createIcon(<ChevronRight />);
export const ChevronUpIcon = createIcon(<ChevronUp />);
export const ClipboardCheckIcon = createIcon(<ClipboardCheck />);
export const ClipboardEditOutlineIcon = createIcon(<ClipboardEditOutline />);
export const ClipboardTextIcon = createIcon(<ClipboardText />);
export const CloseCircleOutlineIcon = createIcon(<CloseCircleOutline />);
export const DeleteIcon = createIcon(<TrashCan />);
export const DotsVerticalIcon = createIcon(<DotsVertical />);
export const DownloadIcon = createIcon(<Download />);
export const DuplicateIcon = createIcon(<ContentDuplicate />);
export const EarthIcon = createIcon(<Earth />);
export const EditIcon = createIcon(<Pencil />);
export const EmailFastOutlineIcon = createIcon(<EmailFastOutline />);
export const ExcelFileIcon = createIcon(<ExcelFile />);
export const ExternalUrlIcon = createIcon(<ExternalUrl />);
export const EyeIcon = createIcon(<Eye />);
export const FileCogOutlineIcon = createIcon(<FileCogOutline />);
export const FileDocumentOutlineIcon = createIcon(<FileDocumentOutline />);
export const FileIcon = createIcon(<File />);
export const FormatListCheckboxIcon = createIcon(<FormatListCheckbox />);
export const HandshakeOutlineIcon = createIcon(<HandshakeOutline />);
export const ImportDataIcon = createIcon(<ImportData />);
export const InformationOutlineIcon = createIcon(<InformationOutline />);
export const KeyboardOutlineIcon = createIcon(<KeyboardOutline />);
export const MenuIcon = createIcon(<Menu />);
export const MessageIcon = createIcon(<Message />);
export const MinusIcon = createIcon(<Minus />);
export const SaveIcon = createIcon(<ContentSave />);
export const ScrewFlatTopIcon = createIcon(<ScrewFlatTop />);
export const SearchIcon = createIcon(<Search />);
export const TagTextOutlineIcon = createIcon(<TagTextOutline />);
export const TextBoxEditOutlineIcon = createIcon(<TextBoxEditOutline />);
export const TrayArrowDownIcon = createIcon(<TrayArrowDown />);
export const UploadIcon = createIcon(<Upload />);
export const UserIcon = createIcon(<Account />);
export const ValveIcon = createIcon(<Valve />);
export const ViewDashboardOutlineIcon = createIcon(<ViewDashboardOutline />);
