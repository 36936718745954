import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { serniListUpdateCreateSection, serniListUpdateSection } from './sections';
import SerniListUpdateListGrid from './SerniListUpdateListGrid';

const PAGE_TITLE = serniListUpdateSection.title;

export default function SerniListUpdateList(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <Layout
      title={PAGE_TITLE}
      header={
        <PageTitle
          pageTitle={PAGE_TITLE}
          rightItems={
            <Button
              color="primary"
              onClick={() => {
                navigate(`${serniListUpdateCreateSection.path}`);
              }}
              size="small"
              variant="contained"
            >
              Crea Pratica
            </Button>
          }
        />
      }
      maxWidth={false}
    >
      <Stack direction="column" minHeight={0}>
        <DataGridWrapper flex={'1 1 auto'}>
          <SerniListUpdateListGrid />
        </DataGridWrapper>
      </Stack>
    </Layout>
  );
}
