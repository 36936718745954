import { BottomUpSearchParams, BottomUpSearchResult, BottomUpSearchResultSchema } from '../entities/BottomUpSearch';
import { procurementToolApi } from './procurementToolApi';

const cleanStringFilters = (list?: string[]): string[] => {
  return list?.filter((list) => Boolean(list)).map((value) => value.trim()) ?? [];
};

const partNumberApi = procurementToolApi.injectEndpoints({
  endpoints: (builder) => ({
    bottomUpSearch: builder.query<BottomUpSearchResult[], BottomUpSearchParams>({
      query: (searchParams) => ({
        url: `search/bottom-up`,
        method: 'POST',
        body: {
          fields: searchParams.fields,
          filters: {
            pn: cleanStringFilters(searchParams.filters.pn),
            vendor: cleanStringFilters(searchParams.filters.vendor),
            pgr: cleanStringFilters(searchParams.filters.pgr),
            eccn: cleanStringFilters(searchParams.filters.eccn),
          },
        },
      }),
      transformResponse: (data) => BottomUpSearchResultSchema.array().parse(data),
    }),
  }),
});

export const { useLazyBottomUpSearchQuery } = partNumberApi;
