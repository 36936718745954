import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledPanel = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  gap: theme.spacing(1.5),
  minHeight: 64,
  borderTop: '1px solid',
  borderTopColor: theme.palette.divider,
  zIndex: theme.zIndex.appBar - 10,
}));

const FlexDivider = styled(Stack)(() => ({
  flex: 1,
}));

const ActionsDivider = styled(Stack)(({ theme }) => ({
  width: theme.spacing(3),
}));

export interface NavigationPanelProps extends StackProps {
  leftActions?: React.ReactNode | React.ReactNode[];
  primaryActions?: React.ReactNode | React.ReactNode[];
  secondaryActions?: React.ReactNode | React.ReactNode[];
  operationsCount?: number;
}

function NavigationPanelComponent(props: NavigationPanelProps) {
  const { leftActions, secondaryActions, primaryActions, operationsCount } = props;

  useEffect(() => {
    if (operationsCount) {
      window.onbeforeunload = function () {
        return true;
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [operationsCount]);

  return (
    <StyledPanel direction="row" alignItems="center">
      {leftActions}
      <FlexDivider />
      {operationsCount ? (
        <Alert variant="standard" severity="warning" sx={{ paddingY: 0 }}>
          <b>{operationsCount}</b>
          {` modifiche non salvate`}
        </Alert>
      ) : null}
      <FlexDivider />
      {secondaryActions}
      <ActionsDivider />
      {primaryActions}
    </StyledPanel>
  );
}

export const NavigationPanel = React.memo(NavigationPanelComponent);
