import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';

export function useTechDataColumn(): GridColDef {
  return useMemo(
    () => ({
      headerName: 'Dato tecnico',
      type: 'boolean',
      width: 140,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) =>
        row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA]?.value === 'true',
    }),
    []
  );
}
