import React, { useId } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { ChevronDownIcon } from '@top-solution/microtecnica-mui/src/components/Icons/Icons';

interface DropDownMenuProps {
  slotProps?: {
    button?: Partial<ButtonProps>;
    menu?: Partial<MenuProps>;
  };
  label?: string;
  direction: 'down' | 'up';
  children: (onClose: () => void) => React.ReactNode | React.ReactNode[];
}

function DropDownMenuComponent(props: DropDownMenuProps) {
  const { slotProps, direction, label, children } = props;

  const buttonId = useId();
  const menuId = useId();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...slotProps?.button}
        endIcon={
          <ChevronDownIcon
            sx={{
              transition: (theme) => theme.transitions.create('transform'),
              transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            }}
          />
        }
      >
        {label}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: direction === 'up' ? 'top' : 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: direction === 'up' ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        slotProps={{
          paper: {
            variant: 'elevation',
          },
          ...slotProps?.menu?.slotProps,
        }}
        {...slotProps?.menu}
      >
        <div>{children(handleClose)}</div>
      </Menu>
    </>
  );
}

export const DropDownMenu = React.memo(DropDownMenuComponent);
