import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'rooks';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { FilterOperator, PagedRequestParams } from '@top-solution/microtecnica-utils';
import { CompactListItem } from '../../pages/Paperwork/AdvancedFilters/CompactListItem';
import { useLazySearchItemQuery } from '../../services/itemApi';

const renderTags: AutocompleteProps<string, true, true, true, 'div'>['renderTags'] = (
  value: readonly string[],
  getTagProps
) =>
  value.map((option, index) => (
    // eslint-disable-next-line react/jsx-key
    <Chip size="small" color="secondary" label={option} {...getTagProps({ index })} />
  ));

function pastedStringList(e: React.ClipboardEvent<HTMLDivElement>) {
  e.preventDefault();
  return e.clipboardData
    .getData('text')
    .split('\n')
    .map((pn) => pn.trim())
    .filter((pn) => pn.length > 0);
}

interface PartNumberListAutocompleteProps {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

function PartNumberListAutocompleteComponent(props: PartNumberListAutocompleteProps): JSX.Element {
  const { value, onChange } = props;
  const [needle, setNeedle] = useState('');
  const [searchPartNumber, { data: response, isLoading }] = useLazySearchItemQuery();

  const search = useDebounce(
    (needle: string) => {
      const params: PagedRequestParams & { isEnditem?: boolean } = {
        offset: 0,
        limit: 10,
        sort: ['pn'],
        filters: [{ field: 'pn', operator: FilterOperator.like, value: `${needle}%` }],
      };
      searchPartNumber(params);
    },
    300,
    { leading: false, trailing: true }
  );

  const options = useMemo<string[]>(() => {
    let options: string[] = [];
    if (typeof value === 'string') {
      options = [value as string];
    } else if (Array.isArray(value)) {
      options = [...value];
    }
    if (needle.length >= 3 && response?.data) {
      options = [...options, ...response.data.map(({ pn }) => pn)];
    }
    return options;
  }, [needle, response, value]);

  const handleInputChange = useCallback(
    (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
      setNeedle(value);
      if (reason === 'input' && value.length >= 3) {
        search(value);
      }
    },
    [search]
  );

  return (
    <Autocomplete
      multiple
      autoComplete
      freeSolo
      value={value}
      onChange={(e, value) => onChange(value)}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Part Numbers"
          onPaste={(e) => {
            onChange?.([...value, ...pastedStringList(e)]);
          }}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: props.placeholder ? true : undefined }}
        />
      )}
      renderTags={renderTags}
      renderOption={(props, option) => <CompactListItem key={option} listItemProps={props} primary={option} />}
      noOptionsText={needle.length < 3 ? 'Digita almeno 3 caratteri' : undefined}
      loading={isLoading}
    />
  );
}

export const PartNumberListAutocomplete = forwardRef(
  PartNumberListAutocompleteComponent
) as typeof PartNumberListAutocompleteComponent;
