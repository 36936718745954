export enum SerniListUpdatePhases {
  Configuration = 'configuration',
  DataEntry = 'data-entry',
  PreparationSigning = 'preparation-signing',
  IssuingDeduction = 'issuing-deduction',
}

export enum DataEntryPages {
  DataEntry = 'data-entry',
}

export enum DataEntrySections {
  Equipments = 'equipments',
  Attachments = 'attachments',
}

export enum PreparationSigningPages {
  Preparation = 'preparation',
  Signing = 'signing',
}

export enum IssuingDeductionPages {
  Issuing = 'issuing',
  Deduction = 'deduction', // FIXME: the worst translation in this file.
}

// TODO: Add all Serni-list sections
export type SerniListUpdateNavigationStructure<T> = {
  [SerniListUpdatePhases.Configuration]: T;
  [SerniListUpdatePhases.DataEntry]: T & {
    pages: {
      [DataEntryPages.DataEntry]: T & {
        sections: {
          [DataEntrySections.Equipments]: T;
          [DataEntrySections.Attachments]: T;
        };
      };
    };
  };
  [SerniListUpdatePhases.PreparationSigning]: T & {
    pages: {
      [PreparationSigningPages.Preparation]: T; // TODO: TBD
      [PreparationSigningPages.Signing]: T;
    };
  };
  [SerniListUpdatePhases.IssuingDeduction]: T & {
    pages: {
      [IssuingDeductionPages.Issuing]: T;
      [IssuingDeductionPages.Deduction]: T;
    };
  };
};
