import React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { ServerErrorBox } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { AppDrawer } from './AppDrawer';
import FullPageProgress from './FullPageProgress';

interface LayoutProps extends ContainerProps {
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  inProgress?: boolean;
  error?: ApiError | Error;
}

const APP_NAME = import.meta.env.VITE_APP_NAME as string;

function LayoutComponent(props: LayoutProps) {
  const { children, title, inProgress, error, sx, maxWidth, header, footer, ...containerProps } = props;

  const pageTitle = `${title ? `${title} | ` : ''}${APP_NAME}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Stack direction="row" height="100%" width="100%">
        <AppDrawer />
        <Stack direction="column" minWidth={0} height="100%" width="100%">
          <Box paddingX={2} paddingY={1.5}>
            {header}
          </Box>
          <Container
            sx={{
              flex: '1 1 auto',
              minHeight: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              paddingTop: header ? 0 : 1.5,
              paddingX: 2,
              height: '100%',
              overflowY: 'auto',
              paddingBottom: footer ? '65px' : '0',
              gap: 2,
              ...sx,
            }}
            maxWidth={error ? 'sm' : maxWidth ?? false}
            disableGutters
            {...containerProps}
          >
            {inProgress ? <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
          </Container>
          {footer}
        </Stack>
      </Stack>
    </>
  );
}

export const Layout = React.memo(LayoutComponent);
