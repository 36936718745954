import React from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';
import { CheckCircleOutlineIcon, ClipboardCheckIcon, TextBoxEditOutlineIcon } from '../../../components/Icons';

interface WizardListItemProps {
  completed: boolean;
  reviewCompleted: boolean;
  text: string;
  commentCount?: number | undefined;
  linkTo: string;
  disabled?: boolean;
  mode: 'DATA_ENTRY' | 'REVIEW';
}

function WizardListItemComponent(props: WizardListItemProps) {
  const { completed, reviewCompleted, commentCount, text, linkTo, disabled, mode } = props;
  const navigate = useNavigate();

  return (
    <ListItem
      disablePadding
      secondaryAction={
        mode === 'REVIEW' && !disabled ? (
          <Badge badgeContent={commentCount ?? 0} color="secondary">
            <TextBoxEditOutlineIcon color="action" />
          </Badge>
        ) : null
      }
      sx={{ backgroundColor: (theme) => (commentCount ? alpha(theme.palette.error.main, 0.1) : undefined) }}
    >
      <ListItemButton onClick={() => navigate(linkTo)} disabled={disabled}>
        <ListItemIcon>
          {mode === 'DATA_ENTRY' && completed ? (
            <CheckCircleOutlineIcon />
          ) : mode === 'REVIEW' && reviewCompleted ? (
            <ClipboardCheckIcon />
          ) : null}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

export const WizardListItem = React.memo(WizardListItemComponent);
