import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadSerniListUpdatePriorityListQuery } from '../../../services/serniListUpdate';
import { capitalize } from '../../../utils/strings';

function SerniListUpdatePrioritySelectComponent(props: TextFieldProps, ref: React.Ref<HTMLDivElement>) {
  const { label, ...otherProps } = props;

  const priorityList = useReadSerniListUpdatePriorityListQuery();

  return (
    <TextField label={label} variant="outlined" select {...otherProps} ref={ref} disabled={!priorityList.data}>
      {priorityList.data ? (
        priorityList.data.map((priority) => (
          <MenuItem key={priority.id} value={priority.id}>
            {capitalize(priority.name)}
          </MenuItem>
        ))
      ) : (
        <MenuItem value={1}>-</MenuItem>
      )}
    </TextField>
  );
}

export default React.forwardRef(SerniListUpdatePrioritySelectComponent);
