import React from 'react';
import Divider from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface DetailSectionProps extends StackProps {
  sectionTitle: string;
  gutterBottom?: boolean;
  disabled?: boolean;
}

function DetailSectionComponent(props: DetailSectionProps) {
  const { sectionTitle, children, gutterBottom, disabled, ...stackProps } = props;
  return (
    <Stack direction="column" {...stackProps} gap={2} marginBottom={gutterBottom ? 1 : undefined} marginTop={3}>
      <Divider textAlign="left" sx={{ opacity: disabled ? 0.6 : 1 }}>
        <Typography color="primary.main" component="h2" variant="body2" textTransform="uppercase" fontWeight={500}>
          {sectionTitle}
        </Typography>
      </Divider>
      {children}
    </Stack>
  );
}

export const DetailSection = React.memo(DetailSectionComponent);
