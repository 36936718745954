import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { Program } from '@top-solution/microtecnica-utils';

interface ProgramChipProps extends ChipProps {
  program: Program;
}

function ProgramChipComponent(props: ProgramChipProps) {
  const { program, ...chipProps } = props;
  return (
    <Chip
      label={program.name}
      size="small"
      {...chipProps}
      sx={{
        textTransform: 'capitalize',
        bgcolor: 'common.white',
        color: 'common.black',
        border: 1,
        ...chipProps.sx,
      }}
    />
  );
}

export const ProgramChip = React.memo(ProgramChipComponent);
