import { GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { nonEmptyGridDateOperators } from '../../utils/datagrid';

export const stringDateColumn: GridColDef<GridRowModel, Date, string> = {
  field: 'date',
  headerName: 'Data',
  type: 'date',
  valueGetter: (value) => (value ? new Date(value) : new Date(0)),
  valueFormatter: (value: Date) =>
    value && value.valueOf() !== 0
      ? value.toLocaleDateString('it-IT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : '-',
  filterOperators: nonEmptyGridDateOperators(),
  minWidth: 150,
};
