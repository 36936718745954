import React from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { useNotifications } from '../../hooks/useNotifications';
import { useDeleteSerniListUpdateMutation } from '../../services/serniListUpdate';

type SerniListUpdateDeleteDialogProps = Omit<ConfirmDialogProps, 'title' | 'onClose' | 'onConfirm' | 'inProgress'> & {
  serniListUpdateId: number;
  handleClose: () => void;
  handleConfirm: () => void;
};

function SerniListUpdateDeleteDialogComponent(props: SerniListUpdateDeleteDialogProps): JSX.Element {
  const { serniListUpdateId, handleClose, handleConfirm, ...dialogProps } = props;
  const { pushNotification } = useNotifications();
  const [deleteSerniListUpdate, deleteSerniListUpdateRequest] = useDeleteSerniListUpdateMutation();

  return (
    <DeleteConfirmDialog
      {...dialogProps}
      onClose={() => {
        deleteSerniListUpdateRequest.reset();
        handleClose();
      }}
      title={'Elimina richiesta in bozza'}
      description={"Questa richiesta, attualmente in bozza, sarà eliminata. Quest'operazione è irreversibile."}
      confirmText="elimina"
      inProgress={deleteSerniListUpdateRequest.isLoading}
      error={deleteSerniListUpdateRequest.error}
      onConfirm={async () => {
        await deleteSerniListUpdate({ serniListUpdateId: serniListUpdateId }).unwrap();
        pushNotification(`Richiesta autorizzazione #${serniListUpdateId} eliminata`);
        handleConfirm();
      }}
    />
  );
}

export const SerniListUpdateDeleteDialog = React.memo(SerniListUpdateDeleteDialogComponent);
