import React, { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Attachment, getAttachmentDraftId } from '../../../../entities/Attachment';
import { DraftOperationEnum } from '../../../../entities/Drafts';
import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { useAttachments } from '../../../../hooks/useAttachments';
import { OrderSubsections } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { AddOrderDialog } from './AddOrderDialog';
import { OrderCard } from './OrderCard';

function OrdersSectionPageComponent() {
  const { route } = usePaperworkNavigation();

  const { attachments, operations } = useAttachments();
  const { fieldValues } = usePaperworkFields();

  const attachmentsByOrderNumber = useMemo(() => {
    const byOrderNumber: Record<string, { attachment: Attachment; orderNumber: string; orderDate: string }[]> = {};

    for (const [_, attachment] of Object.entries(attachments)) {
      const orderNumber = Object.values(fieldValues).find(
        (fieldValue) =>
          fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_NUMBER &&
          fieldValue.attachmentID === attachment.attachment.id
      )?.value;
      const orderDate = Object.values(fieldValues).find(
        (fieldValue) =>
          fieldValue.fieldDatatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_DATE &&
          fieldValue.attachmentID === attachment.attachment.id
      )?.value;

      if (orderNumber && orderDate) {
        byOrderNumber[orderNumber] = byOrderNumber[orderNumber] ?? [];
        byOrderNumber[orderNumber].push({ attachment, orderNumber, orderDate });
      }
    }

    return byOrderNumber;
  }, [attachments, fieldValues]);

  if (!route?.context) {
    return <Alert severity="error">{`Missing context for route "${route?.path}"`}</Alert>;
  }

  const cards: React.ReactNode[] = [];

  for (const [orderId, attachments] of Object.entries(attachmentsByOrderNumber)) {
    const { attachment, orderNumber, orderDate } = attachments[0];
    const draftId = getAttachmentDraftId(attachment);
    let orderAttachment;
    let orderApprovalAttachment;

    for (const attachment of attachments) {
      if (attachment.attachment.savedInContextId === route.subsections?.[OrderSubsections.Order].context?.id) {
        orderAttachment = attachment;
      }
      if (attachment.attachment.savedInContextId === route.subsections?.[OrderSubsections.OrderApproval].context?.id) {
        orderApprovalAttachment = attachment;
      }
    }

    if (orderAttachment && orderApprovalAttachment) {
      if (route && draftId && operations[draftId] !== DraftOperationEnum.Values.Delete) {
        cards.push(
          <Grid item xs={12} xl={6} key={orderId}>
            <OrderCard
              orderNumber={orderNumber}
              orderDate={orderDate}
              orderAttachment={orderAttachment.attachment}
              orderApprovalAttachment={orderApprovalAttachment.attachment}
            />
          </Grid>
        );
      }
    }
  }

  return (
    <Stack direction="column" gap={2} sx={{ height: '100%' }}>
      <Stack direction="row">
        <Typography variant="h5" flex={1}>
          {'Ordini'}
        </Typography>
        <AddOrderDialog section={route} />
      </Stack>
      <Stack direction="column" width="100%" height="100%" minHeight={0} overflow="auto">
        <Stack direction="column" gap={1.5}>
          {cards.length ? (
            <Grid container rowSpacing={1} columnSpacing={1}>
              {cards}
            </Grid>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
              <Typography color="text.disabled" textAlign="center">
                {'Nessun documento aggiunto'}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export const OrdersSectionPage = React.memo(OrdersSectionPageComponent);
