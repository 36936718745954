import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { ClipboardTextIcon } from '../Icons';

interface ReviewButtonComponentProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  highlight: boolean;
}

function ReviewButtonComponent(props: ReviewButtonComponentProps) {
  const { onClick, highlight, ...otherProps } = props;

  return (
    <Button
      variant="contained"
      color={highlight ? 'primary' : 'secondary'}
      size="small"
      onClick={onClick}
      title="Revisiona"
      startIcon={<ClipboardTextIcon />}
      endIcon={<ArrowDropDownIcon />}
      {...otherProps}
    >
      {'Revisiona'}
    </Button>
  );
}

export const ReviewButton = React.memo(ReviewButtonComponent);
