import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { stringDateColumn } from '../../components/DataGrid/stringDateColumn';
import { useMovementTypeColumn } from '../../components/DataGrid/useMovementTypeColumn';
import { usePaperworkAgingColumn } from '../../components/DataGrid/usePaperworkAgingColumn';
import { usePaperworkPriorityColumn } from '../../components/DataGrid/usePaperworkPriorityColumn';
import { usePaperworkProgramsColumn } from '../../components/DataGrid/usePaperworkProgramsColumn';
import { usePaperworkShipToColumn } from '../../components/DataGrid/usePaperworkShipToColumn';
import { usePaperworkStatusColumn } from '../../components/DataGrid/usePaperworkStatusColumn';
import { usePaperworkTypeColumn } from '../../components/DataGrid/usePaperworkTypeColumn';
import { userColumn } from '../../components/DataGrid/userColumn';
import { useSaleTypeColumn } from '../../components/DataGrid/useSaleTypeColumn';

export function useColumns(): GridColDef[] {
  const countryColumn = useCountryGridColDef();
  const statusColumn = usePaperworkStatusColumn();
  const typeColumn = usePaperworkTypeColumn();
  const programColumn = usePaperworkProgramsColumn();
  const shipToColumn = usePaperworkShipToColumn();
  const saleTypeColumn = useSaleTypeColumn();
  const movementTypeColumn = useMovementTypeColumn();
  const priorityColumn = usePaperworkPriorityColumn();
  const agingColumn = usePaperworkAgingColumn();

  return useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
      },
      {
        ...stringDateColumn,
        headerName: 'Ultima Modifica',
        field: 'updatedAt',
      },
      {
        ...userColumn,
        headerName: 'Utente',
        field: 'assignee',
        width: 120,
      },
      { ...statusColumn },
      { ...agingColumn },
      { ...typeColumn, width: 240 },
      { ...programColumn },
      {
        ...shipToColumn,
      },
      { ...saleTypeColumn },
      { ...countryColumn, headerName: 'Paese destinatario' },
      { ...movementTypeColumn },
      {
        ...stringDateColumn,
        headerName: 'Data creazione',
        field: 'createdAt',
      },
      { ...priorityColumn },
    ],
    [
      agingColumn,
      countryColumn,
      movementTypeColumn,
      priorityColumn,
      programColumn,
      saleTypeColumn,
      shipToColumn,
      statusColumn,
      typeColumn,
    ]
  );
}
