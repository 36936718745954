import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useReadProgramsQuery } from '@top-solution/microtecnica-utils';
import { PaperworkStatusChip } from '../../../components/DataGrid/usePaperworkStatusColumn';
import { DetailSection } from '../../../components/DetailSection';
import { ChevronLeftIcon } from '../../../components/Icons';
import {
  AgingInlineBadgeLabel,
  agingFromPaperworkHistory,
} from '../../../components/InlineBadgeLabel/AgingInlineBadgeLabel';
import { CountryInlineBadgeLabel } from '../../../components/InlineBadgeLabel/CountryInlineBadgeLabel';
import { PriorityInlineBadgeLabel } from '../../../components/InlineBadgeLabel/PriorityInlineBadgeLabel';
import { Layout } from '../../../components/Layout';
import { NavigationButton } from '../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../components/PageTitle';
import { PaperworkDebugMenu } from '../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { ProgramChip } from '../../../components/ProgramChip';
import { UserName } from '../../../components/User';
import { PAPERWORK_STATUS } from '../../../entities/Paperwork';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { formatDateTime } from '../../../utils/dates';
import { SigningDetailPanel } from '../PreparationSigning/SigningDetailPanel';
import { DetailPanel, DetailPanelEmptyValue, DetailPanelRow } from './DetailPanel';
import { HistoryDrawer, HistoryDrawerContext, HistoryDrawerProvider } from './HistoryDrawer/HistoryDrawer';
import { Navigation } from './Navigation';
import { PaperworkDetailPrimaryActions } from './PaperworkDetailPrimaryActions';

function PaperworkDetailPageComponent() {
  const params = useParams<{ id: string }>();
  const programsQuery = useReadProgramsQuery();

  const { paperwork } = usePaperwork();

  const assignee = paperwork?.assignee;
  const template = paperwork?.paperworkTemplate;

  const pageTitle = `Richiesta autorizzazione #${params.id}`;

  const { historyDrawerOpen, setHistoryDrawerOpen } = useContext(HistoryDrawerContext);

  return (
    <Layout
      title={pageTitle}
      header={
        <PageTitle
          pageTitle={pageTitle}
          rightItems={
            <Stack direction="row" gap={2}>
              {paperwork ? (
                <Button startIcon={<ChevronLeftIcon />} onClick={() => setHistoryDrawerOpen(true)} color="secondary">
                  {'Storico pratica'}
                </Button>
              ) : null}
            </Stack>
          }
          sx={{ marginBottom: 0 }}
        />
      }
      maxWidth={false}
      sx={{ padding: 0 }}
    >
      <Stack direction="column" sx={{ minHeight: '100%', maxHeight: '100%' }}>
        <Stack
          direction="row"
          sx={{
            overflow: 'auto',
            marginRight: historyDrawerOpen ? `${420}px` : 0,
            transition: (theme) =>
              historyDrawerOpen
                ? theme.transitions.create(['marginRight', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  })
                : theme.transitions.create(['marginRight', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
          }}
        >
          <Stack
            direction="column"
            overflow="auto"
            maxHeight="100%"
            flex={1}
            sx={{
              paddingBottom: '65px',
              paddingX: 2,
            }}
          >
            <DetailSection sectionTitle="Informazioni pratica">
              <Stack direction="row" alignItems="flex-start" flexWrap="wrap" gap={2}>
                <DetailPanel>
                  <DetailPanelRow rowTitle="ID Pratica" rowValue={`#${params.id}`} />
                  <DetailPanelRow rowTitle="Data creazione" rowValue={formatDateTime(paperwork?.createdAt)} />
                  <DetailPanelRow rowTitle="Data ultima modifica" rowValue={formatDateTime(paperwork?.updatedAt)} />
                  <DetailPanelRow
                    rowTitle="Priorità"
                    rowValue={<PriorityInlineBadgeLabel priority={paperwork?.priority} />}
                  />
                  <DetailPanelRow
                    rowTitle="Aging"
                    rowValue={<AgingInlineBadgeLabel aging={agingFromPaperworkHistory(paperwork?.historyList)} />}
                  />
                  {assignee ? (
                    <DetailPanelRow
                      rowTitle={
                        paperwork.status.id === PAPERWORK_STATUS.DRAFT ||
                        paperwork.status.id === PAPERWORK_STATUS.RETURNED
                          ? 'Assegnata a'
                          : 'In carico a'
                      }
                      rowValue={<UserName username={assignee} />}
                    />
                  ) : null}
                  <DetailPanelRow
                    direction="row"
                    rowTitle="Stato"
                    rowValue={paperwork?.status ? <PaperworkStatusChip status={paperwork?.status.id} /> : '—'}
                    sx={{ paddingTop: 1 }}
                  />
                </DetailPanel>
                {template ? (
                  <DetailPanel>
                    <DetailPanelRow
                      rowTitle="Tipologia di pratica"
                      rowValue={template.paperworkType.name ?? <DetailPanelEmptyValue>{'—'}</DetailPanelEmptyValue>}
                    />
                    <DetailPanelRow
                      rowTitle="Tipologia di vendita"
                      rowValue={template.saleType.name ?? <DetailPanelEmptyValue>{'—'}</DetailPanelEmptyValue>}
                    />
                    <DetailPanelRow
                      rowTitle="Tipologia di movimentazione"
                      rowValue={template.movementType?.name ?? <DetailPanelEmptyValue>{'—'}</DetailPanelEmptyValue>}
                    />
                    <DetailPanelRow
                      rowTitle="Paese destinatario"
                      rowValue={<CountryInlineBadgeLabel countryCode={paperwork.country} />}
                    />
                  </DetailPanel>
                ) : null}
                <DetailPanel>
                  <DetailPanelRow
                    rowTitle="Programmi"
                    rowValue={
                      <Stack direction="row" gap={1} flexWrap="wrap">
                        {paperwork?.programList?.length ? (
                          paperwork.programList.map((programName) => {
                            const program = programsQuery.data?.map?.[programName];
                            return program ? <ProgramChip key={program.id} program={program} /> : null;
                          })
                        ) : (
                          <DetailPanelEmptyValue>{'(Nessun programma associato)'}</DetailPanelEmptyValue>
                        )}
                      </Stack>
                    }
                  />
                </DetailPanel>
                <SigningDetailPanel />
              </Stack>
            </DetailSection>
            <Navigation />
          </Stack>
          <HistoryDrawer />
        </Stack>
        <NavigationPanel
          leftActions={
            <>
              <NavigationButton component={Link} to={'/paperworks'}>
                {`Torna alla lista pratiche`}
              </NavigationButton>
              <PaperworkDebugMenu />
            </>
          }
          primaryActions={<PaperworkDetailPrimaryActions />}
        ></NavigationPanel>
      </Stack>
    </Layout>
  );
}

export const PaperworkDetailPage = React.memo(function PaperworkDetailPageWrapper() {
  return (
    <HistoryDrawerProvider>
      <PaperworkDetailPageComponent />
    </HistoryDrawerProvider>
  );
});
