import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';

export enum MaterialTransferModeValues {
  MOVE_IT = 'MoveIT',
  EMAIL = 'E-mail',
  FILE_SHARING = 'File Sharing',
  WEB = 'Web',
  OTHER = 'Altro',
}

export function useMaterialTransferModeColumn(): GridSingleSelectColDef {
  const valueOptions = Object.values(MaterialTransferModeValues).map((v) => ({ value: v, label: v }));

  return useMemo(
    () => ({
      headerName: 'Modalità di trasferimento',
      type: 'singleSelect',
      valueOptions,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE,
      width: 150,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]?.value;
      },
    }),
    [valueOptions]
  );
}
