import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { stringDateColumn } from '../../components/DataGrid/stringDateColumn';
import { usePaperworkPriorityColumn } from '../../components/DataGrid/usePaperworkPriorityColumn';
import { userColumn } from '../../components/DataGrid/userColumn';
import { useSerniListUpdateStatusColumn } from '../../components/DataGrid/useSerniListUpdateStatusColumn';

export function useColumns(): GridColDef[] {
  const priorityColumn = usePaperworkPriorityColumn();
  const statusColumn = useSerniListUpdateStatusColumn();

  return useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
      },
      {
        ...userColumn,
        headerName: 'Utente',
        field: 'assignee',
        width: 180,
      },
      { ...statusColumn },
      {
        ...stringDateColumn,
        headerName: 'Data creazione',
        field: 'createdAt',
      },
      { ...priorityColumn },
    ],
    [priorityColumn, statusColumn]
  );
}
