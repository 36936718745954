import React from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { useNotifications } from '../../hooks/useNotifications';
import { useDeletePaperworkMutation } from '../../services/paperwork';

type PaperworkDeleteDialogProps = Omit<ConfirmDialogProps, 'title' | 'onClose' | 'onConfirm' | 'inProgress'> & {
  paperworkId: number;
  handleClose: () => void;
  handleConfirm: () => void;
};

function PaperworkDeleteDialogComponent(props: PaperworkDeleteDialogProps): JSX.Element {
  const { paperworkId, handleClose, handleConfirm, ...dialogProps } = props;
  const { pushNotification } = useNotifications();
  const [deletePaperwork, deletePaperworkRequest] = useDeletePaperworkMutation();

  return (
    <DeleteConfirmDialog
      {...dialogProps}
      onClose={() => {
        deletePaperworkRequest.reset();
        handleClose();
      }}
      title={'Elimina richiesta in bozza'}
      description={"Questa richiesta, attualmente in bozza, sarà eliminata. Quest'operazione è irreversibile."}
      confirmText="elimina"
      inProgress={deletePaperworkRequest.isLoading}
      error={deletePaperworkRequest.error}
      onConfirm={async () => {
        await deletePaperwork({ paperworkId: paperworkId }).unwrap();
        pushNotification(`Richiesta autorizzazione #${paperworkId} eliminata`);
        handleConfirm();
      }}
    />
  );
}

export const PaperworkDeleteDialog = React.memo(PaperworkDeleteDialogComponent);
