import React from 'react';
import { DetailSection } from '../../../components/DetailSection';
import { DataEntryPages, SerniListUpdatePhases } from '../../../hooks/useSerniListUpdate/serniListUpdateNavigation';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useSerniListUpdateNavigation } from '../../../hooks/useSerniListUpdate/useSerniListUpdateNavigation';
import { useReadSerniListUpdateCompletedPagesQuery } from '../../../services/serniListUpdate';
import { WizardList } from '../../Paperwork/PaperworkDetail/WizardList';
import { WizardListItem } from '../../Paperwork/PaperworkDetail/WizardListItem';

function NavigationComponent() {
  const { routes } = useSerniListUpdateNavigation();
  const { serniListUpdate } = useSerniListUpdate();
  const { data } = useReadSerniListUpdateCompletedPagesQuery(
    { serniListUpdateId: serniListUpdate?.id ?? 1 },
    { skip: !serniListUpdate }
  );

  const configurationRoutes = routes[SerniListUpdatePhases.Configuration];
  const dataEntryRoute = routes[SerniListUpdatePhases.DataEntry];

  return (
    <>
      <DetailSection sectionTitle={configurationRoutes.title}>
        <WizardList>
          <WizardListItem
            text={configurationRoutes.title}
            completed
            reviewCompleted={false}
            mode="DATA_ENTRY"
            linkTo={configurationRoutes.absolutePath}
          />
        </WizardList>
      </DetailSection>
      <DetailSection sectionTitle={dataEntryRoute.title}>
        <WizardList>
          <WizardListItem
            text={dataEntryRoute.pages[DataEntryPages.DataEntry].title}
            linkTo={dataEntryRoute.pages[DataEntryPages.DataEntry].absolutePath}
            commentCount={0}
            reviewCompleted={false}
            mode="DATA_ENTRY"
            completed={data?.completedPages ? data.completedPages.dataEntry?.dataEntryCompleted ?? false : false}
          />
        </WizardList>
      </DetailSection>
    </>
  );
}

export const Navigation = React.memo(NavigationComponent);
