import {
  SerniListUpdate,
  SerniListUpdateSchema,
  SerniListUpdateType,
  SerniListUpdateTypeSchema,
  SerniListUpdatePriority,
  SerniListUpdatePrioritySchema,
  AddSerniListUpdateRequest,
  SerniListUpdateCompletedPagesSchema,
  SerniListUpdateCompletedPages,
  UpdateSerniListUpdateListPayload,
} from '../entities/SerniListUpdate';
import { api, TAG_TYPES } from './baseApi';

const url = 'serni-list-update';

const serniListUpdateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSerniListUpdateList: builder.query<Array<SerniListUpdate>, void>({
      query: () => ({ method: 'GET', url: `${url}` }),
      transformResponse: (data) => SerniListUpdateSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    readSerniListUpdate: builder.query<SerniListUpdate, { serniListUpdateId: SerniListUpdate['id'] }>({
      query: (params) => ({ url: `${url}/${params.serniListUpdateId}` }),
      transformResponse: (data) => SerniListUpdateSchema.parse(data),
      providesTags: (_res, _error, args) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: args.serniListUpdateId }],
    }),
    createSerniListUpdate: builder.mutation<{ id: number }, AddSerniListUpdateRequest>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    updateSerniListUpdate: builder.mutation<{ id: number }, UpdateSerniListUpdateListPayload>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: (_res, _error, args) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: args.id }],
    }),
    deleteSerniListUpdate: builder.mutation<void, { serniListUpdateId: SerniListUpdate['id'] }>({
      query: ({ serniListUpdateId }) => ({ url: `${url}/${serniListUpdateId}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'LIST' }],
    }),
    readSerniListUpdateTypeList: builder.query<Array<SerniListUpdateType>, void>({
      query: () => ({ url: 'serniListUpdate-types' }),
      transformResponse: (data) => SerniListUpdateTypeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'TYPE_LIST' }],
    }),
    readSerniListUpdatePriorityList: builder.query<Array<SerniListUpdatePriority>, void>({
      query: () => ({ url: 'paperwork-priorities' }),
      transformResponse: (data) => SerniListUpdatePrioritySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: 'PRIORITY_LIST' }],
    }),
    readSerniListUpdateCompletedPages: builder.query<
      SerniListUpdateCompletedPages,
      { serniListUpdateId: SerniListUpdate['id'] }
    >({
      query: ({ serniListUpdateId }) => ({ url: `${url}/${serniListUpdateId}/completed-pages` }),
      transformResponse: (data) => SerniListUpdateCompletedPagesSchema.parse(data),
      providesTags: (_res, _err, req) => [
        { type: TAG_TYPES.SERNI_LIST_UPDATE, id: `COMPLETED_PAGES_${req.serniListUpdateId}` },
      ],
    }),
    patchSerniListUpdateCompletedPages: builder.mutation<void, SerniListUpdateCompletedPages>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}/completed-pages`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.SERNI_LIST_UPDATE, id: `COMPLETED_PAGES_${req.id}` }],
    }),
  }),
});

export const {
  useReadSerniListUpdateListQuery,
  useReadSerniListUpdateQuery,
  useDeleteSerniListUpdateMutation,
  useReadSerniListUpdatePriorityListQuery,
  useCreateSerniListUpdateMutation,
  useUpdateSerniListUpdateMutation,
  useReadSerniListUpdateCompletedPagesQuery,
  usePatchSerniListUpdateCompletedPagesMutation,
} = serniListUpdateApi;
