import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store/store';

export const TAG_TYPES = {
  ATTACHMENT: 'Attachment',
  COUNTRY: 'Country',
  CURRENCY: 'Currency',
  MATERIAL: 'Material',
  MOVEMENT_TYPE: 'MovementType',
  PAPERWORK_VALUE: 'PaperworkValue',
  PAPERWORK: 'Paperwork',
  PAPERWORK_COMPLETED_PAGES: 'PaperworkCompletedPages',
  PAPERWORK_REVIEWS: 'PaperworkReviews',
  PEC_SETTINGS: 'PecSettings',
  SALE_TYPE: 'SaleType',
  SERNI_LIST_UPDATE: 'SerniListUpdate',
  STAKEHOLDER: 'Stakeholder',
  SERNI: 'Serni',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
