import React from 'react';
import Typography from '@mui/material/Typography';
import { useSerniListUpdate } from '../../hooks/useSerniListUpdate/useSerniListUpdate';

function SerniListUpdateInfoPanelComponent() {
  const { serniListUpdate } = useSerniListUpdate();

  if (!serniListUpdate) {
    return null;
  }

  return (
    <Typography fontSize="0.85rem">
      {[`Pratica #${serniListUpdate?.id}`].filter((s) => s && s?.length > 0).join(' — ')}
    </Typography>
  );
}

export const SerniListUpdateInfoPanel = React.memo(SerniListUpdateInfoPanelComponent);
