import React, { useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ProgramAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { EquipmentSchema } from '../../../../../entities/Equipment';
import { MaterialDraft, MaterialSchema, getMaterialDraftId } from '../../../../../entities/Material';
import { UserRole } from '../../../../../entities/User';
import { useMaterials } from '../../../../../hooks/useMaterials';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { useReadEccnListQuery } from '../../../../../services/eccnApi';
import { useReadEquipmentListQuery } from '../../../../../services/equipmentiApi';

interface EditMaterialDialogFormProps {
  formId: string;
  material: MaterialDraft;
  onSubmit: (data: MaterialDraft) => Promise<void>;
  onReset: () => void;
  withoutEquipment?: boolean;
  withoutPn?: boolean;
}

function EditMaterialDialogFormComponent(props: EditMaterialDialogFormProps) {
  const { formId, material, withoutEquipment, withoutPn, onReset } = props;
  const [existingMaterialWarning, setExistingMaterialWarning] = useState(false);

  const readEccnListQuery = useReadEccnListQuery();
  const readSerniListQuery = useReadEquipmentListQuery();

  const { materials } = useMaterials();

  const EditMaterialDialogFormSchema = MaterialSchema.omit({
    id: true,
    imported: true,
    hts: true,
    importReason: true,
    serniCode: true,
    pn: true,
  }).extend({
    hts: MaterialSchema.shape.hts.unwrap(),
    importReason: props.material.imported ? z.undefined() : MaterialSchema.shape.importReason.unwrap(),
    serniCode: withoutEquipment ? z.undefined() : EquipmentSchema.shape.serniCode, //Controller complains about the optional field
    pn: withoutPn ? z.undefined() : MaterialSchema.shape.pn, //Controller complains about the optional field
  });
  type EditMaterialDialogForm = z.infer<typeof EditMaterialDialogFormSchema>;

  const auth = useAuth();

  const { control, handleSubmit, formState } = useForm<EditMaterialDialogForm>({
    defaultValues: material,
    resolver: zodResolver(EditMaterialDialogFormSchema),
  });

  function onSubmit(values: EditMaterialDialogForm) {
    const material = {
      ...props.material,
      ...values,
    };

    const newDraftId = getMaterialDraftId(material);

    if (materials[newDraftId] && !material.id) {
      setExistingMaterialWarning(true);
      return;
    }

    props.onSubmit(material);
  }

  useEffect(() => {
    if (formState.isValidating) {
      setExistingMaterialWarning(false);
    }
  }, [formState.isValidating]);

  return (
    <Box component="form" id={formId} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} paddingTop={2} paddingBottom={1}>
          {existingMaterialWarning ? (
            <Alert severity="warning">{'Questo materiale è già presente in tabella'}</Alert>
          ) : (
            <Alert severity="info">{'Si ricorda che la descrizione deve coincidere con i dati SAP.'}</Alert>
          )}
        </Grid>
        <Grid item xs={6} paddingTop={2}>
          {!withoutPn ? (
            <Controller
              control={control}
              name="pn"
              render={({ field: { ...field }, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  label="Part Number"
                  type="text"
                  fullWidth
                  helperText={error?.message}
                  error={invalid}
                  {...field}
                  disabled
                />
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} paddingTop={2}>
          <Controller
            control={control}
            name="description"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="Descrizione"
                type="text"
                fullWidth
                helperText={error?.message}
                error={invalid}
                disabled={material.imported && !!material.description && !auth.hasRole(UserRole.GT)}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} paddingTop={2}>
          <Controller
            control={control}
            name="program"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <ProgramAutocomplete
                {...field}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                label="Programma"
                fullWidth
                helperText={error?.message}
                error={invalid}
                disabled={material.imported && !!material.program && !auth.hasRole(UserRole.GT)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} paddingTop={2}>
          {withoutEquipment ? (
            <Box flex={1} />
          ) : (
            <Controller
              control={control}
              name="serniCode"
              render={({ field: { ...field }, fieldState: { error, invalid } }) => (
                <Autocomplete
                  options={readSerniListQuery.data?.list.map((s) => s.serniCode) ?? []}
                  getOptionLabel={(option) => {
                    if (readSerniListQuery.data?.bySerniCode && readSerniListQuery.data?.bySerniCode[option]) {
                      return `${option} - ${readSerniListQuery.data?.bySerniCode[option].description}`;
                    }
                    return option;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Equipaggiamento"
                      fullWidth
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                  {...field}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  disabled={material.imported && !!material.serniCode && !auth.hasRole(UserRole.GT)}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6} paddingTop={2}>
          <Controller
            control={control}
            name="eccn"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <Autocomplete
                options={readEccnListQuery.data?.map((eccn) => eccn.id) ?? []}
                renderInput={(params) => (
                  <TextField {...params} label="Classifica" fullWidth error={invalid} helperText={error?.message} />
                )}
                {...field}
                value={field.value}
                onChange={(_, newValue) => field.onChange(newValue)}
                disabled={material.imported && !!material.eccn && !auth.hasRole(UserRole.GT)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} paddingTop={2}>
          <Controller
            control={control}
            name="hts"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="HTS"
                type="text"
                fullWidth
                helperText={error?.message}
                error={invalid}
                disabled={material.imported && !!material.hts && !auth.hasRole(UserRole.GT)}
                {...field}
              />
            )}
          />
        </Grid>
        {!material.imported ? (
          <Grid item xs={12} paddingTop={2}>
            <Controller
              control={control}
              name="importReason"
              render={({ field: { ...field }, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  label="Giustificazione inserimento manuale"
                  type="text"
                  fullWidth
                  helperText={error?.message}
                  error={invalid}
                  disabled={material.imported}
                  multiline
                  rows={3}
                  {...field}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

interface EditMaterialDialogProps {
  material: MaterialDraft;
  open: boolean;
  onClose: () => void;
  withoutEquipment?: boolean;
  withoutPn?: boolean;
}

function EditMaterialDialogComponent(props: EditMaterialDialogProps) {
  const { material, open, onClose, withoutEquipment, withoutPn } = props;
  const titleId = useId();
  const formId = useId();

  const { updateMaterial } = useMaterials();

  const { pushNotification } = useNotifications();

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (material: MaterialDraft) => {
    updateMaterial(props.material.draftId, { ...material, draftId: getMaterialDraftId(material) });

    pushNotification(`Dati del P/N ${material.pn} aggiornati`);
    onClose();
  };

  return (
    <div>
      <React.Fragment>
        <Dialog open={open} aria-labelledby={titleId} fullWidth maxWidth="md">
          <DialogTitle id={titleId}>{`Modifica materiale${withoutPn ? '' : ` (P/N: ${material.pn})`}`}</DialogTitle>
          <DialogContent>
            {material && (
              <EditMaterialDialogFormComponent
                formId={formId}
                material={material}
                onSubmit={onSubmit}
                onReset={handleClose}
                withoutEquipment={withoutEquipment}
                withoutPn={withoutPn}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button type="reset" form={formId} color="inherit">
              {'Annulla'}
            </Button>
            <Button type="submit" form={formId}>
              {'Modifica'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export const EditMaterialDialog = React.memo(EditMaterialDialogComponent);
