import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { getFieldComponent } from '../../../../../components/Fields/getFieldComponent';

import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';

export default function AdditionalEconomicConditions() {
  const { contextFields } = usePaperworkFields();

  return (
    <Stack direction="column" alignItems="stretch" height="100%">
      <Box flex={1}>
        <Grid container rowSpacing={3} columnSpacing={2} flex={1} paddingTop={3}>
          {contextFields.map((field) => (
            <Grid key={field.id} item xs={12} md={6} lg={6}>
              {getFieldComponent(field)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}
