import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DeleteIcon, FileDocumentOutlineIcon, TrayArrowDownIcon } from './Icons';

interface FileCardProps extends CardProps {
  file: File;
  onDelete?: () => void;
  localeText?: Partial<{
    download: string;
    delete: string;
  }>;
}

export function FileCard(props: FileCardProps) {
  const { file, onDelete, localeText, ...cardProps } = props;

  return (
    <Card
      variant="elevation"
      onClick={(e) => e.stopPropagation()}
      {...cardProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        padding: 0.5,
        width: '100%',
        cursor: 'default',
        ...cardProps.sx,
      }}
    >
      <Box sx={{ padding: 1, height: 40 }}>
        <FileDocumentOutlineIcon />
      </Box>
      <Typography flex={1} variant="body2" fontFamily="monospace">
        {file.name}
      </Typography>
      <IconButton
        LinkComponent="a"
        size="small"
        href={URL.createObjectURL(file)}
        download={file.name}
        onClick={(event) => {
          event.stopPropagation();
        }}
        title={localeText?.download ?? 'Scarica document'}
      >
        <TrayArrowDownIcon />
      </IconButton>
      {onDelete ? (
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
          title={localeText?.delete ?? 'Elimina documento'}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
    </Card>
  );
}
