import { SaleType, SaleTypeSchema } from '../entities/SaleType';

import { api, TAG_TYPES } from './baseApi';

const url = 'sale-types';

const saleTypeAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSaleTypeList: builder.query<Array<SaleType>, void>({
      query: () => ({ url }),
      transformResponse: (data) => SaleTypeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SALE_TYPE, id: 'LIST' }],
    }),
  }),
});

export const { useReadSaleTypeListQuery } = saleTypeAPi;
