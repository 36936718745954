import React, { useMemo } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { PAPERWORK_AGING, PAPERWORK_STATUS, Paperwork } from '../../entities/Paperwork';
import { capitalize } from '../../utils/strings';
import { InlineBadgeLabel, InlineBadgeLabelProps } from './InlineBadgeLabel';

interface AgingInlineBadgeLabelProps extends Omit<InlineBadgeLabelProps, 'badgeColor'> {
  aging: PAPERWORK_AGING | undefined | null;
}

export function agingFromPaperworkHistory(
  historyList: Paperwork['historyList'] | null | undefined
): PAPERWORK_AGING | null {
  if (!historyList) return null;
  if (historyList[0] && historyList[0].status.id === PAPERWORK_STATUS.WAITING) {
    const statusUpdatedDate = historyList[0].updatedAt;
    const daysFromLastStatusChange = differenceInCalendarDays(new Date(), new Date(statusUpdatedDate));
    if (daysFromLastStatusChange < 6) return PAPERWORK_AGING.ON_TIME;
    if (daysFromLastStatusChange < 11) return PAPERWORK_AGING.OVERDUE;
    return PAPERWORK_AGING.URGENT;
  }
  return null;
}

function AgingInlineBadgeLabelComponent(props: AgingInlineBadgeLabelProps) {
  const { aging, ...inlineBadgeLabelProps } = props;

  const color = useMemo(() => {
    switch (aging) {
      case PAPERWORK_AGING.ON_TIME:
        return 'success';
      case PAPERWORK_AGING.OVERDUE:
        return 'warning';
      default:
        return 'error';
    }
  }, [aging]);

  if (!aging) {
    return '—';
  }

  return (
    <InlineBadgeLabel {...inlineBadgeLabelProps} badgeColor={color}>
      {capitalize(aging)}
    </InlineBadgeLabel>
  );
}

export const AgingInlineBadgeLabel = React.memo(AgingInlineBadgeLabelComponent);
