import { Paperwork, PaperworkReview, PaperworkReviewSchema, PaperworkReviewUpdate } from '../entities/Paperwork';
import { api, TAG_TYPES } from './baseApi';

const url = 'paperworks';

export const paperworkReviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPaperworkReviews: builder.query<PaperworkReview[], { paperworkId: Paperwork['id'] }>({
      query: (params) => ({ url: `${url}/${params.paperworkId}/reviews` }),
      transformResponse: (data) => PaperworkReviewSchema.array().parse(data),
      providesTags: (_res, _error, args) => [{ type: TAG_TYPES.PAPERWORK_REVIEWS, id: args.paperworkId }],
    }),
    updatePaperworkReview: builder.mutation<{ id: number }, PaperworkReviewUpdate>({
      query: ({ paperworkId, ...body }) => ({ url: `${url}/${paperworkId}/reviews`, method: 'PUT', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK_REVIEWS, id: req.paperworkId }],
    }),
  }),
});

export const { useReadPaperworkReviewsQuery, useUpdatePaperworkReviewMutation } = paperworkReviewApi;
