import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useReadProgramsQuery } from '@top-solution/microtecnica-utils';
import { createMultipleValuesFilterOperators } from '../../utils/datagrid';
import { ProgramChip } from '../ProgramChip';

export function usePaperworkProgramsColumn(): GridSingleSelectColDef {
  const { data } = useReadProgramsQuery();

  const valueOptions = useMemo(() => data?.list.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'programList',
      headerName: 'Programmi',
      type: 'singleSelect',
      valueOptions,
      filterOperators: createMultipleValuesFilterOperators((item) => item as string),
      valueFormatter: (value) => (Array.isArray(value) ? ([...value] as string[]).sort().join(', ') : value),
      renderCell: ({ row }) =>
        row.programList?.length > 0 ? (
          <Stack direction={'row'} alignItems="center" gap={1} sx={{ height: '100%' }}>
            {row.programList?.map((programName: string) => {
              const program = data?.map[programName];
              return program ? <ProgramChip key={program.id} program={program} /> : null;
            })}
          </Stack>
        ) : undefined,
    }),
    [data?.map, valueOptions]
  );
}
