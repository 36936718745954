import { ArchiveIcon, ArchiveSyncIcon } from '../../components/Icons';
import { AppSection } from '../../entities/AppSection';

export const serniListUpdateSection: AppSection = {
  path: '/serni-list-updates',
  title: 'Richieste aggiornamento lista Serni',
  icon: <ArchiveSyncIcon />,
};

export const serniListUpdateCreateSection: AppSection = {
  path: 'create',
  title: 'Configurazione richiesta aggiornamento lista Serni',
  icon: <ArchiveIcon />,
};
