import { useCallback, useMemo } from 'react';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { Paperwork, PAPERWORK_FIELD_DATATYPE, PaperworkFieldValue, PaperworkRoute } from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { useReadCountryListQuery } from '../../services/countries';
import {
  DataEntryPages,
  AttachmentsSections,
  OtherDocumentsSubsections,
  PaperworkPhases,
  PaperworksNavigationStructure,
  PreparationSigningPages,
} from './paperworkNavigation';

export function usePaperworkRouteExceptions() {
  const { data: commonDataCountries } = useReadCountriesQuery();
  const { data: ilcmCountries } = useReadCountryListQuery();

  const isCountryNato = useCallback(
    (countryCode: string) => {
      const ilcmCountry = ilcmCountries?.find((country) => country.code === countryCode);
      if (ilcmCountry) {
        return ilcmCountry.category === 'NATO';
      }

      const commonDataCountry = commonDataCountries?.byISO[countryCode];
      if (commonDataCountry) {
        return commonDataCountry.isNato;
      }

      return false;
    },
    [commonDataCountries?.byISO, ilcmCountries]
  );

  const isCountryExtraNato = useCallback(
    (countryCode: string) => {
      const ilcmCountry = ilcmCountries?.find((country) => country.code === countryCode);
      if (ilcmCountry) {
        return ilcmCountry.category === 'NON-NATO';
      }

      const commonDataCountry = commonDataCountries?.byISO[countryCode];
      if (commonDataCountry) {
        return !commonDataCountry.isEU && !commonDataCountry.isNato;
      }

      return false;
    },
    [commonDataCountries?.byISO, ilcmCountries]
  );

  const applyPaperworkRouteExceptions = useCallback(
    (
      routes: PaperworksNavigationStructure<PaperworkRoute>,
      paperwork: Paperwork,
      paperworkValues: PaperworkFieldValue[]
    ) => {
      const shipToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
      );

      const otherDocumentsSubsections =
        routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.OtherDocuments]
          .subsections ?? {};

      if (
        paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Licenza Framework' ||
        paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Individuale/Tangibile'
      ) {
        const endUserStakeholder =
          paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS) ??
          paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO);

        // #### Matrice 8 ############################################################

        const attachmentSections = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections ?? {};

        // eslint-disable-next-line no-inner-declarations
        function disableAllAttachments() {
          for (const [_, section] of Object.entries(attachmentSections)) {
            section.enabled = false;

            for (const [_, subsection] of Object.entries(section.subsections ?? {})) {
              subsection.enabled = false;
            }
          }
        }

        if (paperwork.programList?.includes('EFTR')) {
          //  se il programma è EFA (paese utilizzatore finale IT - DE - ES - UK - AT) non sono necessari documenti, il tool non proporrà nessun documento da caricare nella richiesta

          routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].active = false;
          disableAllAttachments();
        } else {
          if (paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Individuale/Tangibile') {
            // L. 185 - Licenza individuale - Esportazione definitiva

            if (paperwork.paperworkTemplate.movementType?.name === 'Importazione Definitiva') {
              // nessun documento per il momento	il tool deve segnalare all'utente di verificare con GT eventuali documenti da presentare

              disableAllAttachments();
            } else if (paperwork.paperworkTemplate.movementType?.name === 'Esportazione Definitiva') {
              if (
                shipToStakeholder?.country &&
                isCountryNato(shipToStakeholder?.country) &&
                endUserStakeholder?.country &&
                isCountryExtraNato(endUserStakeholder.country)
              ) {
                // Paese destinatario NATO / End User Extra Nato
                // Ordine - Accettazione d'ordine - EUS - IIC - Certificato di ditta abilitata - Copia di una licenza valida di esportazione

                attachmentSections[AttachmentsSections.Framework].enabled = false;
              } else if (shipToStakeholder?.country && isCountryNato(shipToStakeholder?.country)) {
                // Paese destinatario NATO
                // Ordine - Accettazione d'ordine - EUS

                attachmentSections[AttachmentsSections.Framework].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = false;
              } else if (shipToStakeholder?.country && isCountryExtraNato(shipToStakeholder?.country)) {
                // Paese destinatario Extra NATO - End User = Forza Armata
                // Ordine - Accettazione d'ordine -  IIC - Certificato di ditta abilitata

                attachmentSections[AttachmentsSections.Framework].enabled = false;
                attachmentSections[AttachmentsSections.Eus].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = false;
              }
            } else if (
              paperwork.paperworkTemplate.movementType?.name === 'Temporanea Importazione e Successiva Riesportazione'
            ) {
              // L. 185 - Licenza individuale - Temporanea Importazione e successiva riesportazione
              if (
                shipToStakeholder?.country &&
                isCountryNato(shipToStakeholder?.country) &&
                endUserStakeholder?.country &&
                isCountryExtraNato(endUserStakeholder.country)
              ) {
                // Paese destinatario NATO / End User Extra Nato
                // Ordine - Accettazione d'ordine - EUS

                attachmentSections[AttachmentsSections.Framework].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = false;
                otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = false;
              }
            }
          } else if (paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Licenza Framework') {
            // L. 185 - Licenza Framework
            // Framework
            attachmentSections[AttachmentsSections.Eus].enabled = false;
            otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = false;
            otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = false;
            otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = false;
          }
        }

        // #### Matrice 8 ############################################################
      }

      if (paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Intangibile') {
        // #### Matrice 16 ###########################################################

        if (shipToStakeholder) {
          if (isCountryExtraNato(shipToStakeholder.country)) {
            otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = false;
          }
        }

        // #### Matrice 16 ###########################################################
      }

      // #### Other Matrice-unrelated stuff ##########################################

      // Do not ask for End User Statements if the end user is Microtecnica itself
      const endUserMatchesValue = paperworkValues?.find(
        (value) => value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
      );
      if (endUserMatchesValue && endUserMatchesValue.value === 'microtecnica') {
        if (paperworkValues) {
          routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[
            AttachmentsSections.Eus
          ].enabled = false;
        }
      }
      // #### Other Matrice-unrelated stuff ##########################################

      // #### FIXME: DEBUG #########################################################
      routes[PaperworkPhases.PreparationSigning].enabled = true;
      routes[PaperworkPhases.PreparationSigning].active = true;
      // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].enabled = true;
      // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].active = true;
      routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].enabled = true;
      routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].active = true;
      // #### FIXME: DEBUG #########################################################
    },
    [isCountryExtraNato, isCountryNato]
  );

  return useMemo(
    () => ({
      applyPaperworkRouteExceptions,
    }),
    [applyPaperworkRouteExceptions]
  );
}
