import React, { useId, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { STAKEHOLDER_ROLE, StakeholderDraft } from '../../../../../entities/Stakeholder';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { FilterTab } from './FilterTab';
import { SearchTab } from './SearchTab';

const TITLE = 'Aggiungi parti coinvolte';

interface ImportStakeholdersDialogProps {
  onImportStakeholders: (stakeholder: StakeholderDraft[]) => void;
  role: STAKEHOLDER_ROLE;
}

function ImportStakeholdersDialogComponent(props: ImportStakeholdersDialogProps) {
  const { onImportStakeholders, role } = props;
  const { pushNotification } = useNotifications();

  const [activeStep, setActiveStep] = useState(0);

  const titleId = useId();
  const [open, setOpen] = useState(false);

  const [searchResults, setSearchResults] = useState<StakeholderDraft[]>([]);
  const [manualStakeholders, setManualStakeholders] = useState<StakeholderDraft[]>([]);

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {TITLE}
      </Button>
      <Dialog
        open={open}
        aria-labelledby={titleId}
        fullWidth
        maxWidth="lg"
        TransitionProps={{
          onExited: () => {
            setSearchResults([]);
            setActiveStep(0);
          },
        }}
        PaperProps={{ sx: { height: 'calc(100% - 64px)', maxHeight: 600 } }}
      >
        <DialogTitle id={titleId}>{TITLE}</DialogTitle>
        <Stepper activeStep={activeStep} sx={{ paddingX: 2 }}>
          <Step key={0}>
            <StepLabel>{'Ricerca parti coinvolte'}</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{'Importazione parti coinvolte'}</StepLabel>
          </Step>
        </Stepper>
        <Stack direction="column" flex={1}>
          {activeStep === 0 ? (
            <SearchTab
              onNext={(searchResults, manualStakeholders) => {
                setSearchResults(searchResults);
                setManualStakeholders(manualStakeholders);
                setActiveStep(1);
              }}
              onCancel={() => setOpen(false)}
              role={role}
            />
          ) : (
            <FilterTab
              searchResults={searchResults}
              manualStakeholders={manualStakeholders}
              onNext={(stakeholders) => {
                onImportStakeholders(
                  stakeholders.map((stakeholder) => ({
                    ...stakeholder,
                    role,
                  }))
                );
                setOpen(false);
                pushNotification(`Aggiunte ${stakeholders.length} parti coinvolte`);
              }}
              onCancel={() => setOpen(false)}
            />
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}

export const ImportStakeholdersDialog = React.memo(ImportStakeholdersDialogComponent);
