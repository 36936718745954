import { createListenerMiddleware } from '@reduxjs/toolkit';

import { DraftOperationEnum } from '../../entities/Drafts';
import { PatchMaterialListRequest } from '../../entities/Material';
import { materialAPi as materialAPI } from '../../services/material';
import { paperworksApi } from '../../services/paperwork';
import { MaterialsStateSlice, replaceMaterials, setSavingState } from '../materialsStateSlice';

export const materialListenerMiddleware = createListenerMiddleware();

/**
 * Replace cached materials after the GET query is successful
 */
materialListenerMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    if (!action.payload.materialList) {
      return;
    }

    listenerApi.dispatch(
      replaceMaterials({
        paperworkId: action.payload.id,
        materials: action.payload.materialList,
      })
    );
  },
});

/**
 * Set the "saving" state to false after a successful save
 */
materialListenerMiddleware.startListening({
  matcher: materialAPI.endpoints.patchMaterialList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setSavingState({ saving: false }));
  },
});

/**
 * Save the materials right after "saving"
 */
materialListenerMiddleware.startListening({
  actionCreator: setSavingState,
  effect: async (action, listenerApi) => {
    if (action.payload.saving === true) {
      const state = listenerApi.getState() as { materialsState: MaterialsStateSlice };

      const paperworkId = state.materialsState.paperworkId;

      if (paperworkId !== undefined) {
        const patchPayload: PatchMaterialListRequest = {
          paperworkId,
          create: [],
          update: [],
          delete: [],
        };
        const { materials, originalMaterials, operations } = state.materialsState;
        for (const [draftId, operation] of Object.entries(operations)) {
          if (operation === DraftOperationEnum.Values.Create) {
            const material = materials[draftId];
            if (material) {
              patchPayload.create.push(material);
            }
          } else if (operation === DraftOperationEnum.Values.Delete) {
            const id = originalMaterials[draftId].id;
            if (id) {
              patchPayload.delete.push({ id });
            }
          } else if (operation === DraftOperationEnum.Values.Update) {
            const id = materials[draftId].id;
            const material = materials[draftId];
            if (id && material) {
              patchPayload.update.push({
                ...material,
                id,
              });
            }
          }
        }

        listenerApi.dispatch(materialAPI.endpoints.patchMaterialList.initiate(patchPayload));

        // Every time occurs a change in the material list, flag the material page as not completed
        listenerApi.dispatch(
          paperworksApi.endpoints.patchPaperworkCompletedPages.initiate({
            id: paperworkId,
            completedPages: {
              materials: { dataEntryCompleted: false },
            },
          })
        );
      }
    }
  },
});
