import React, { ElementType } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import Button, { ButtonProps } from '@mui/material/Button';

// To prevent MUI to act crazy with component={Link}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MuiElementTypeGeneric = ElementType<any, keyof JSX.IntrinsicElements>;

function NavigationButtonComponent<T extends MuiElementTypeGeneric>(props: ButtonProps<T>) {
  return <Button color="inherit" variant="outlined" {...props} />;
}

function ForwardButtonComponent<T extends MuiElementTypeGeneric>(props: ButtonProps<T>) {
  return <NavigationButtonComponent {...props}>{'Avanti'}</NavigationButtonComponent>;
}

function BackButtonComponent<T extends MuiElementTypeGeneric>(props: ButtonProps<T>) {
  return <NavigationButtonComponent {...props}>{'Indietro'}</NavigationButtonComponent>;
}

function PrimaryButtonComponent<T extends MuiElementTypeGeneric>(props: ButtonProps<T>) {
  return <Button color="primary" variant="contained" {...props} />;
}

function AccentButtonComponent<T extends MuiElementTypeGeneric>(props: ButtonProps<T>) {
  return <Button color="accent" variant="contained" {...props} />;
}

function SaveButtonComponent(props: LoadingButtonProps) {
  return (
    <LoadingButton color="secondary" variant="contained" {...props}>
      {'Salva'}
    </LoadingButton>
  );
}

export const NavigationButton = React.memo(NavigationButtonComponent);
export const ForwardButton = React.memo(ForwardButtonComponent);
export const BackButton = React.memo(BackButtonComponent);
export const PrimaryButton = React.memo(PrimaryButtonComponent);
export const AccentButton = React.memo(AccentButtonComponent);
export const SaveButton = React.memo(SaveButtonComponent);
