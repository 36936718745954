import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { eccnColumn } from '../../../../../components/DataGrid/eccnColumn';
import { htsColumn } from '../../../../../components/DataGrid/htsColumn';
import { IncompleteRowsDatagrid } from '../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { materialDescriptionColumn } from '../../../../../components/DataGrid/materialDescriptionColumn';
import { partNumberColumn } from '../../../../../components/DataGrid/partNumberColumn';
import { useProgramColumn } from '../../../../../components/DataGrid/programColumn';
import { useSerniCodeColumn } from '../../../../../components/DataGrid/serniCodeColumn';
import { DeleteIcon, EditIcon, KeyboardOutlineIcon } from '../../../../../components/Icons';
import { MaterialDraft } from '../../../../../entities/Material';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

interface SelectPartNumbersDatagridProps {
  materials: MaterialDraft[];
  onDelete: (id: MaterialDraft['draftId']) => void;
  onSelectMaterial: (material: MaterialDraft['draftId']) => void;
  incompleteRows: Set<string>;
  withoutEquipment?: boolean;
  withoutPn?: boolean;
}

function SelectPartNumbersDatagridComponent(props: SelectPartNumbersDatagridProps) {
  const { materials, incompleteRows, onDelete, onSelectMaterial, withoutEquipment, withoutPn } = props;

  const programColumn = useProgramColumn();
  const serniCodeColumn = useSerniCodeColumn();

  const dataGridColumns = useMemo(() => {
    let columns: Array<GridColDef | GridActionsColDef> = [];

    if (!withoutPn) {
      columns.push(partNumberColumn);
    }

    columns = columns.concat([materialDescriptionColumn, programColumn]);

    if (!withoutEquipment) {
      columns.push(serniCodeColumn);
    }

    columns = columns.concat([eccnColumn, htsColumn]);

    columns.push({
      field: 'imported',
      type: 'boolean',
      headerName: 'Manuale',
      width: 40,
      valueGetter: (value) => !value,
      renderCell: ({ value }) => {
        return value === false ? null : (
          <Tooltip title="Inserito manualmente">
            <span>
              <KeyboardOutlineIcon />
            </span>
          </Tooltip>
        );
      },
    });

    columns.push({
      field: 'importReason',
      type: 'string',
      headerName: 'Giustificazione inserimento manuale',
      minWidth: 400,
    });

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 90,
      getActions: ({ row }: GridRowParams<MaterialDraft>) => [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          onClick={() => onSelectMaterial(row.draftId)}
          label="Modifica materiale"
          title="Modifica materiale"
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          onClick={() => onDelete(row.draftId)}
          label="Elimina materiale"
          title="Elimina materiale"
        />,
      ],
    });

    return columns;
  }, [onDelete, onSelectMaterial, programColumn, serniCodeColumn, withoutEquipment, withoutPn]);

  return (
    <>
      <IncompleteRowsDatagrid
        density="compact"
        rows={materials}
        columns={dataGridColumns}
        pinnedColumns={pinnedColumns}
        disableRowGrouping
        disableAggregation
        getRowId={(material) => material.draftId}
        getRowClassName={(params) => (incompleteRows.has(params.row.draftId) ? 'incomplete' : '')}
        sx={{ border: 0 }}
      />
    </>
  );
}

export const SelectPartNumbersDatagrid = React.memo(SelectPartNumbersDatagridComponent);
