import React from 'react';
import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { userSection } from './sections';

const PAGE_TITLE = userSection.title;

export default function UserList(): React.ReactElement {
  return (
    <Layout title={PAGE_TITLE} header={<PageTitle pageTitle={PAGE_TITLE} />} disableGutters>
      <AppUserListGrid appId={import.meta.env.VITE_APP_ID} />
    </Layout>
  );
}
