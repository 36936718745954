import React, { useMemo } from 'react';
import { z } from 'zod';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DecimalTextField } from '@top-solution/microtecnica-mui';
import { PAPERWORK_FIELD_DATATYPE } from '../../../entities/Paperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { Currency } from '../../../pages/Currency/currency';
import { useReadCurrencyListQuery } from '../../../services/currency';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

const PriceAdjustmentSchema = z.object({
  flag: z.boolean(),
  value: z.union([z.null(), z.number().optional()]),
});

function MaterialPriceAdjustmentInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);
  const { data: currencies } = useReadCurrencyListQuery();
  const { fieldValues } = usePaperworkFields();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const getSelectedCurrencySymbol = (c: Currency['id']) => {
    if (!c || !currenciesById?.has(c)) return '-';
    return currenciesById.get(c)?.symbol;
  };

  const currencyValue = useMemo(() => {
    const value = Object.values(fieldValues).find(
      (v) => v.fieldDatatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY
    )?.value;
    return Number(value);
  }, [fieldValues]);

  const value = useMemo(() => {
    if (!fieldValue?.value) return { flag: false, value: undefined };
    return PriceAdjustmentSchema.parse(JSON.parse(fieldValue.value));
  }, [fieldValue]);

  return (
    <Grid container rowSpacing={3} columnSpacing={2} flex={1} paddingTop={3}>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.flag}
              onChange={(_, checked) => {
                updateValue(JSON.stringify({ value: undefined, flag: checked }));
              }}
            />
          }
          label={field.label}
        />
      </Grid>
      {value.flag && (
        <Grid item xs={9}>
          <DecimalTextField
            fullWidth
            label={'Valore di revisione'}
            value={value.value}
            onChange={(e, v) => {
              return updateValue(JSON.stringify({ ...value, value: v }));
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{getSelectedCurrencySymbol(currencyValue)}</InputAdornment>,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export const MaterialPriceAdjustmentInput = React.memo(MaterialPriceAdjustmentInputComponent);
