import { useCallback } from 'react';
import { PaperworkRoute } from '../../entities/Paperwork';
import { useReadPaperworkCompletedPagesQuery } from '../../services/paperwork';
import { usePaperwork } from './usePaperwork';

export function useIsPaperworkRouteEnabled() {
  const { paperwork } = usePaperwork();

  const readPaperworkCompletedPagesQuery = useReadPaperworkCompletedPagesQuery(
    { paperworkId: paperwork?.id ?? 1 },
    { skip: !paperwork }
  );

  return useCallback(
    (route: PaperworkRoute) => {
      if (readPaperworkCompletedPagesQuery.data) {
        if (route.path === 'attachments') {
          if (readPaperworkCompletedPagesQuery.data?.completedPages?.stakeholders?.dataEntryCompleted === true) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    [readPaperworkCompletedPagesQuery]
  );
}
