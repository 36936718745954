import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useAttachments } from '../../hooks/useAttachments';
import { useMaterials } from '../../hooks/useMaterials';
import { usePaperworkFields } from '../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../hooks/usePaperwork/usePaperworkNavigation';
import { useStakeholders } from '../../hooks/useStakeholders';
import { usePaperworkReviews } from '../review/usePaperworkReviews';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const UndefinedLabel = () => {
  return (
    <Typography color="text.disabled" fontFamily="monospace" fontSize="1em">
      {'undefined'}
    </Typography>
  );
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      direction="column"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        p: 1,
        width: '100%',
        height: '100%',
        flex: ' 1 1 0',
        minHeight: 0,
        display: value !== index ? 'none' : undefined,
      }}
    >
      {value === index && <>{children}</>}
    </Stack>
  );
}

const operationColDef: GridColDef = {
  field: 'operation',
  headerName: 'Operation',
  width: 80,
  type: 'string',
};

/**
 *  Paperwork debug panel
 *  To be removed in prod (or not, it would be a nice-to-have to debug edge cases)
 */
function LinkedEntitiesDrawerComponent(props: { open: boolean; onClose: () => void }) {
  const { open, onClose } = props;
  const { fieldValues, operations } = usePaperworkFields();
  const { materials, operations: materialsOperations } = useMaterials();
  const { stakeholders, operations: stakeholdersOperations } = useStakeholders();
  const { attachments, operations: attachmentsOperations } = useAttachments();
  const paperworkNavigation = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();

  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        slotProps={
          {
            // paper: {
            //   variant: 'elevation',
            //   elevation: 20,
            // },
          }
        }
      >
        <Stack direction="column" width={'calc(100vw - 32px)'} height={'70vh'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Navigation" />
              <Tab label="Materials" />
              <Tab label="Stakeholders" />
              <Tab label="Attachments" />
              <Tab label="Field values state" />
              <Tab label="Reviews" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tab} index={0}>
            <Paper component={Stack} direction="column" gap={1} padding={1} sx={{ flex: '0 0 auto' }}>
              <Typography variant="h6">Navigation context</Typography>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight={500}>Key:</Typography>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight={500}>Phase:</Typography>
                {`${paperworkNavigation.navigationContext.phase}`}
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight={500}>Page:</Typography>
                {`${paperworkNavigation.navigationContext.page}`}
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight={500}>Section:</Typography>
                {`${paperworkNavigation.navigationContext.section}`}
              </Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography fontWeight={500}>Subsection:</Typography>
                {`${paperworkNavigation.navigationContext.subsection}`}
              </Stack>
            </Paper>
            <Paper component="pre" sx={{ flex: '1 1 0', minHeight: 0, overflow: 'auto' }}>
              {JSON.stringify(paperworkNavigation, null, 2)}
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <Paper sx={{ overflow: 'auto' }}>
              <DataGridPremium
                density="compact"
                rows={Object.values(materials).map(
                  (value) =>
                    value && {
                      ...value,
                      operation: materialsOperations[value.draftId],
                    }
                )}
                columns={[
                  operationColDef,
                  {
                    field: 'id',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'draftId',
                    width: 300,
                    flex: 1,
                  },
                  {
                    field: 'pn',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'program',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'serniCode',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'eccn',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'hts',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'imported',
                    type: 'boolean',
                    width: 100,
                  },
                ]}
                disableDensitySelector
                disableColumnSelector
                disableColumnPinning
                getRowId={(row) => row.draftId}
                sx={{ border: 0 }}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <Paper sx={{ overflow: 'auto' }}>
              <DataGridPremium
                density="compact"
                rows={Object.values(stakeholders).map(
                  (value) =>
                    value && {
                      ...value,
                      operation: stakeholdersOperations[value.draftId],
                    }
                )}
                columns={[
                  operationColDef,
                  {
                    field: 'id',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'draftId',
                    width: 300,
                    flex: 1,
                  },
                  {
                    field: 'ptId',
                    width: 300,
                    flex: 1,
                  },
                  {
                    field: 'role',
                    width: 100,
                    maxWidth: 100,
                  },
                  {
                    field: 'name',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'address',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'country',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'eccn',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'imported',
                    type: 'boolean',
                    width: 100,
                  },
                ]}
                disableDensitySelector
                disableColumnSelector
                disableColumnPinning
                getRowId={(row) => row.draftId}
                sx={{ border: 0 }}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={3}>
            <Paper sx={{ overflow: 'auto' }}>
              <DataGridPremium
                density="compact"
                rows={Object.values(attachments).map(
                  (value) =>
                    value && {
                      ...value,
                      operation: attachmentsOperations[value.draftId],
                    }
                )}
                columns={[
                  operationColDef,
                  {
                    field: 'id',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'draftId',
                    width: 300,
                    flex: 1,
                  },
                  {
                    field: 'attachmentId',
                    width: 100,
                    maxWidth: 100,
                    valueGetter: (_, row) => row.attachment.id,
                  },
                  {
                    field: 'savedInContextId',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                  {
                    field: 'stakeholderId',
                    width: 100,
                    maxWidth: 150,
                    flex: 1,
                  },
                ]}
                disableDensitySelector
                disableColumnSelector
                disableColumnPinning
                getRowId={(row) => row.draftId}
                sx={{ border: 0 }}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={4}>
            <Paper sx={{ overflow: 'auto' }}>
              <DataGridPremium
                density="compact"
                rows={Object.values(fieldValues).map(
                  (value) =>
                    value && {
                      ...value,
                      operation: operations[value.draftId],
                    }
                )}
                columns={[
                  operationColDef,
                  {
                    field: 'id',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'draftId',
                    width: 300,
                    flex: 1,
                  },
                  {
                    field: 'fieldDatatype',
                    width: 200,
                    maxWidth: 300,
                    flex: 1,
                  },
                  {
                    field: 'materialID',
                    headerName: 'mtrID',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'stakeholderID',
                    headerName: 'stkID',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'stakeholderDraftID',
                    headerName: 'stkDRID',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'attachmentID',
                    headerName: 'atcID',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'progIndex',
                    headerName: 'PI',
                    width: 40,
                    type: 'number',
                  },
                  {
                    field: 'originalValue',
                    width: 200,
                    flex: 1,
                    renderCell: ({ value }) => (
                      <Typography fontFamily="monospace" fontSize={13}>
                        {value === undefined ? <UndefinedLabel /> : value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'value',
                    width: 200,
                    flex: 1,
                    renderCell: ({ value }) => (
                      <Typography fontFamily="monospace" fontSize={13}>
                        {value === undefined ? <UndefinedLabel /> : value}
                      </Typography>
                    ),
                  },
                ]}
                disableDensitySelector
                disableColumnSelector
                disableColumnPinning
                getRowId={(row) => row.draftId}
                sx={{ border: 0 }}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={5}>
            <Paper sx={{ overflow: 'auto' }}>
              <DataGridPremium
                density="compact"
                rows={reviews}
                columns={[
                  {
                    field: 'contextId',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'materialId',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'stakeholderId',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'attachmentId',
                    width: 80,
                    type: 'number',
                  },
                  {
                    field: 'author',
                    width: 80,
                    type: 'string',
                    valueGetter: (value, row) => row.review.author,
                  },
                  {
                    field: 'comment',
                    flex: 1,
                    type: 'string',
                    valueGetter: (value, row) => row.review.comment,
                  },
                  {
                    field: 'isApproved',
                    width: 80,
                    type: 'boolean',
                    valueGetter: (value, row) => row.review.isApproved,
                  },
                  {
                    field: 'updatedAt',
                    width: 160,
                    type: 'dateTime',
                    valueGetter: (value, row) => new Date(row.review.updatedAt),
                  },
                ]}
                disableDensitySelector
                disableColumnSelector
                disableColumnPinning
                getRowId={(row) => JSON.stringify(row)}
                sx={{ border: 0 }}
              />
            </Paper>
          </CustomTabPanel>
        </Stack>
      </Drawer>
    </>
  );
}

export const LinkedEntitiesDrawer = React.memo(LinkedEntitiesDrawerComponent);
