import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOperationEnum } from '../entities/Drafts';
import { MaterialDraft, getMaterialDraftId } from '../entities/Material';
import { Paperwork } from '../entities/Paperwork';
import { RootState } from './store';

export type MaterialsStateSlice = {
  paperworkId: Paperwork['id'] | undefined;
  materials: Record<string, MaterialDraft>;
  originalMaterials: Record<string, MaterialDraft>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: MaterialsStateSlice = {
  paperworkId: undefined,
  materials: {},
  originalMaterials: {},
  operations: {},
  saving: false,
};

const materialsSlice = createSlice({
  name: 'materialsState',
  initialState,
  reducers: {
    updateMaterial: (
      state,
      action: PayloadAction<{
        currentDraftId: string | undefined;
        materialDraft?: MaterialDraft;
      }>
    ) => {
      const currentDraftId = action.payload.currentDraftId;
      const material = action.payload.materialDraft;
      const draftId = material?.draftId;

      if (!material?.id && draftId) {
        // The material has no ID: CREATE
        state.operations[draftId] = DraftOperationEnum.Values.Create;
        state.materials[draftId] = material;
        if (currentDraftId) {
          // The material was modified after creation
          delete state.materials[currentDraftId];
          delete state.operations[currentDraftId];
        }
      } else if (currentDraftId && !material) {
        // The material is in the store, but now is undefined: DELETE

        const id = state.originalMaterials[currentDraftId]?.id;

        if (id === undefined) {
          // The material is not on DB: just remove it from the store
          delete state.materials[currentDraftId];
          delete state.operations[currentDraftId];
        } else {
          // The material had an ID and was on DB: mark it for deletion
          state.operations[currentDraftId] = DraftOperationEnum.Values.Delete;
          delete state.materials[currentDraftId];
        }
      } else if (draftId !== currentDraftId && currentDraftId && draftId) {
        // The material in the store and the new one are different: UPDATE
        delete state.operations[currentDraftId];
        delete state.materials[currentDraftId];

        state.operations[draftId] = DraftOperationEnum.Values.Update;
        state.materials[draftId] = material;
      }
    },
    setSavingState: (state, action: PayloadAction<{ saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    replaceMaterials: (
      state,
      action: PayloadAction<{
        materials: Omit<MaterialDraft, 'draftId'>[];
        paperworkId: Paperwork['id'];
      }>
    ) => {
      state.originalMaterials = {};
      state.materials = {};
      for (const value of action.payload.materials) {
        const draftId = getMaterialDraftId(value);
        state.materials[draftId] = { ...value, draftId };
        state.originalMaterials[draftId] = { ...value, draftId };
      }
      state.operations = {};
      state.paperworkId = action.payload.paperworkId;
    },
    discardOperations: (state) => {
      state.materials = state.originalMaterials;
      state.operations = {};
    },
  },
});

export const { updateMaterial, setSavingState, replaceMaterials, discardOperations } = materialsSlice.actions;

export const selectMaterialsState = (state: RootState) => state.materialsState;

export default materialsSlice.reducer;
