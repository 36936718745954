import { z } from 'zod';

export const ImportMaterialsSearchFormSchema = z
  .object({
    partNumbers: z.string().array(),
  })
  .superRefine((val, ctx) => {
    if (!val.partNumbers.length) {
      ctx.addIssue({ code: z.ZodIssueCode.too_small, minimum: 1, inclusive: true, type: 'array' });
    }
  });
export type ImportMaterialsSearchForm = z.infer<typeof ImportMaterialsSearchFormSchema>;
