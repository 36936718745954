import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import EuFlag from '../../assets/eu-flag-square.svg?react';

function EuFlagRoundComponent(props: BoxProps) {
  return (
    <Tooltip title={"Il paese fa parte dell'Unione Europea"}>
      <Box
        component="span"
        {...props}
        sx={{
          width: '1.5em',
          height: '1.5em',
          borderRadius: '50%',
          overflow: 'hidden',
          ...props.sx,
        }}
      >
        <EuFlag />
      </Box>
    </Tooltip>
  );
}

export const EuFlagRound = React.memo(EuFlagRoundComponent);
