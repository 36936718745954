import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DecimalTextField } from '@top-solution/microtecnica-mui';
import { SaveIcon } from '../../../../../components/Icons';
import { AddBillingYear, AddBillingYearSchema, BillingYear } from './BillingYear';

export type BillingYearAddDialogProps = Omit<DialogProps, 'onClose' | 'onSubmit'> & {
  onClose: () => void;
  onSubmit: (data: BillingYear) => void;
  currentCurrencySymbol: string;
  disabledYears: number[];
};

export default function BillingYearAddDialog(props: BillingYearAddDialogProps): React.ReactElement {
  const { open, title, onSubmit, onClose, currentCurrencySymbol, disabledYears, ...dialogProps } = props;

  const AddBillingYearSchemaRefined = useMemo(
    () =>
      AddBillingYearSchema.superRefine((val, ctx) => {
        if (val.billingYear) {
          const currentYear = val.billingYear.getFullYear();
          if (disabledYears.includes(currentYear)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Anno di fatturazione già inserito',
              path: ['billingYear'],
            });
          }
        }
      }),
    [disabledYears]
  );

  const form = useForm<AddBillingYear>({
    defaultValues: {
      billingYear: new Date(),
      customerValue: 0,
      supplierValue: 0,
    },
    resolver: zodResolver(AddBillingYearSchemaRefined),
  });

  // Preselect with the next year after the last inserted
  useEffect(() => {
    form.reset({
      billingYear: disabledYears.length ? new Date(`11-11-${Math.max(...disabledYears) + 1}`) : new Date(),
    });
  }, [disabledYears, form]);

  const onHandleSubmit = useCallback(
    async (data: AddBillingYear) => {
      onSubmit({ ...data, billingYear: data.billingYear.getFullYear() });
      form.reset();
      onClose();
    },
    [form, onClose, onSubmit]
  );

  return (
    <Dialog open={open} {...dialogProps} fullWidth maxWidth={'xs'}>
      <form onSubmit={form.handleSubmit(onHandleSubmit)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ py: 1 }}>
          <Box sx={{ py: 1 }}>
            <Controller
              control={form.control}
              name="billingYear"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Anno di riferimento"
                  {...field}
                  views={['year']}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: Boolean(error),
                      helperText: error?.message || ' ',
                    },
                  }}
                  disablePast
                />
              )}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <Controller
              control={form.control}
              name="supplierValue"
              render={({ field, fieldState: { error } }) => (
                <DecimalTextField
                  min={0}
                  max={undefined}
                  label="Valore dal fornitore"
                  {...field}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{currentCurrencySymbol}</InputAdornment>,
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <Controller
              control={form.control}
              name="customerValue"
              render={({ field, fieldState: { error } }) => (
                <DecimalTextField
                  min={0}
                  max={undefined}
                  label="Valore dal cliente"
                  {...field}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{currentCurrencySymbol}</InputAdornment>,
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              form.reset();
              onClose();
            }}
          >
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={false}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
