import { useMemo } from 'react';
import {
  PaperworkField,
  PaperworkFieldValue,
  PaperworkFieldValueDraft,
  addDraftIdToPaperworkFieldValue,
  getPaperworkFieldValueDraftId,
} from '../../entities/Paperwork';
import { usePaperworkFields } from '../../hooks/usePaperwork/usePaperworkFields';

export interface FieldComponentProps {
  field: PaperworkField;
  fieldLinks?: Partial<Pick<PaperworkFieldValue, 'materialID' | 'stakeholderID' | 'attachmentID'>>;
}

export function useFieldComponentProps(field: PaperworkField, fieldLinks?: FieldComponentProps['fieldLinks']) {
  const { fieldValues, updateValue } = usePaperworkFields();

  const draftId = getPaperworkFieldValueDraftId({
    fieldDatatype: field.datatype,
    progIndex: 0,
  });

  return useMemo(
    () => ({
      fieldValue: fieldValues[draftId] as PaperworkFieldValueDraft | undefined,
      updateValue: (value: string | undefined) =>
        updateValue(
          addDraftIdToPaperworkFieldValue({
            ...fieldLinks,
            ...fieldValues[draftId],
            fieldDatatype: field.datatype,
            progIndex: 0,
            value: value,
          })
        ),
    }),
    [draftId, field.datatype, fieldLinks, fieldValues, updateValue]
  );
}
