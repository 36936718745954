import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AttachmentDraft, getAttachmentDraftId } from '../entities/Attachment';
import { DraftOperationEnum } from '../entities/Drafts';
import { Paperwork } from '../entities/Paperwork';
import { RootState } from './store';

export type AttachmentsStateSlice = {
  paperworkId: Paperwork['id'] | undefined;
  attachments: Record<string, AttachmentDraft>;
  originalAttachments: Record<string, AttachmentDraft>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: AttachmentsStateSlice = {
  paperworkId: undefined,
  attachments: {},
  originalAttachments: {},
  operations: {},
  saving: false,
};

const attachmentSlice = createSlice({
  name: 'attachmentsState',
  initialState,
  reducers: {
    createAttachment: (
      state,
      action: PayloadAction<{
        attachmentDraft: AttachmentDraft;
      }>
    ) => {
      const attachmentDraft = action.payload.attachmentDraft;
      const draftId = attachmentDraft.draftId;

      if (attachmentDraft.id) {
        return;
      }

      state.operations[draftId] = DraftOperationEnum.Values.Create;
      state.attachments[draftId] = attachmentDraft;
    },
    updateAttachment: (
      state,
      action: PayloadAction<{
        currentDraftId: string | undefined;
        attachmentDraft: AttachmentDraft;
      }>
    ) => {
      const currentDraftId = action.payload.currentDraftId;
      const attachmentDraft = action.payload.attachmentDraft;

      if (!currentDraftId) {
        return;
      }

      if (state.operations[currentDraftId] !== DraftOperationEnum.Values.Create) {
        state.operations[currentDraftId] = DraftOperationEnum.Values.Update;
      }

      state.attachments[currentDraftId] = attachmentDraft;
    },
    deleteAttachment: (
      state,
      action: PayloadAction<{
        draftId: AttachmentDraft['draftId'];
        id: AttachmentDraft['id'];
      }>
    ) => {
      const draftId = action.payload.draftId;
      const id = action.payload.draftId;

      if (id === undefined) {
        // The attachment is not on DB: just remove it from the store
        delete state.attachments[draftId];
        delete state.operations[draftId];
      } else {
        // The attachment had an ID and was on DB: mark it for deletion
        state.operations[draftId] = DraftOperationEnum.Values.Delete;
      }
    },
    setSavingState: (state, action: PayloadAction<{ saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    replaceAttachments: (
      state,
      action: PayloadAction<{
        attachments: Omit<AttachmentDraft, 'draftId'>[];
        paperworkId: Paperwork['id'];
      }>
    ) => {
      state.originalAttachments = {};
      state.attachments = {};
      for (const value of action.payload.attachments) {
        const draftId = getAttachmentDraftId(value);
        state.attachments[draftId] = { ...value, draftId };
        state.originalAttachments[draftId] = { ...value, draftId };
      }
      state.operations = {};
      state.paperworkId = action.payload.paperworkId;
    },
    discardOperations: (state) => {
      state.attachments = state.originalAttachments;
      state.operations = {};
    },
  },
});

export const {
  createAttachment,
  updateAttachment,
  deleteAttachment,
  setSavingState,
  replaceAttachments,
  discardOperations,
} = attachmentSlice.actions;

export const selectAttachmentsState = (state: RootState) => state.attachmentsState;

export default attachmentSlice.reducer;
