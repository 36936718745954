import React, { useMemo } from 'react';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { materialDescriptionColumn } from '../../../../components/DataGrid/materialDescriptionColumn';
import { useProgramColumn } from '../../../../components/DataGrid/programColumn';
import { DeleteIcon, EditIcon } from '../../../../components/Icons';
import { EquipmentDraft } from '../../../../entities/Equipment';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

interface EquipmentsStepDatagridProps {
  equipments: EquipmentDraft[];
  onSelectEquipment: (material: EquipmentDraft) => void;
  onDelete: (id: EquipmentDraft['equipment']) => void;
}

function EquipmentsStepDatagridComponent(props: EquipmentsStepDatagridProps) {
  const { equipments, onSelectEquipment, onDelete } = props;

  const programColumn = useProgramColumn();

  const dataGridColumns = useMemo(() => {
    const columns: Array<GridColDef | GridActionsColDef> = [
      { ...materialDescriptionColumn, field: 'equipment', headerName: 'Nome' },
      { ...materialDescriptionColumn, field: 'equipmentDescription', headerName: 'Descrizione', flex: 2 },
      { ...programColumn, field: 'equipmentProgram', valueGetter: (value) => [value], headerName: 'Programma' },
    ];

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 90,
      getActions: ({ row }: GridRowParams<EquipmentDraft>) => [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          onClick={() => onSelectEquipment(row)}
          label="Modifica equipaggiamento"
          title="Modifica equipaggiamento"
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          onClick={() => onDelete(row.equipment)}
          label="Elimina equipaggiamento"
          title="Elimina equipaggiamento"
        />,
      ],
    });

    return columns;
  }, [onDelete, onSelectEquipment, programColumn]);

  return (
    <DataGridPremium
      density="compact"
      rows={equipments}
      columns={dataGridColumns}
      pinnedColumns={pinnedColumns}
      disableRowGrouping
      disableAggregation
      getRowId={(equipment) => equipment.equipment}
      sx={{ border: 0 }}
    />
  );
}

export const EquipmentsStepDatagrid = React.memo(EquipmentsStepDatagridComponent);
