import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../../../../../components/Icons';
import { SerialNumber, SerialNumberSchema } from './SerialNumber';

export type BillingYearAddDialogProps = Omit<DialogProps, 'onClose' | 'onSubmit'> & {
  onClose: () => void;
  onSubmit: (data: SerialNumber) => void;
};

export default function SerialNumberAddDialog(props: BillingYearAddDialogProps): React.ReactElement {
  const { open, title, onSubmit, onClose, ...dialogProps } = props;

  const form = useForm<SerialNumber>({
    defaultValues: {
      serialNumber: '',
      orderRow: '',
    },
    resolver: zodResolver(SerialNumberSchema),
  });

  const onHandleSubmit = useCallback(
    async (data: SerialNumber) => {
      onSubmit(data);
      form.reset();
      onClose();
    },
    [form, onClose, onSubmit]
  );

  return (
    <Dialog open={open} {...dialogProps} fullWidth maxWidth={'xs'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={1} rowSpacing={2} sx={{ my: 1 }}>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name="serialNumber"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="S/N"
                  type="text"
                  fullWidth
                  helperText={error?.message}
                  error={Boolean(error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={form.control}
              name="orderRow"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Riga d'ordine"
                  type="text"
                  fullWidth
                  helperText={error?.message}
                  error={Boolean(error)}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            form.reset();
            onClose();
          }}
        >
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={false}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={form.handleSubmit(onHandleSubmit)}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
