import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Paperwork, PaperworkSchema, PaperworkTemplate } from '../../entities/Paperwork';
import { useReadPaperworkTypeListQuery } from '../../services/paperwork';

function getPaperworkTypeId(row: Paperwork | PaperworkTemplate): number {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork)?.paperworkTemplate?.paperworkType?.id;
  }
  return (row as PaperworkTemplate)?.paperworkType?.id;
}

function getPaperworkTypeName(row: Paperwork | PaperworkTemplate): string {
  if (PaperworkSchema.safeParse(row).success) {
    return (row as Paperwork)?.paperworkTemplate?.paperworkType?.name;
  }
  return (row as PaperworkTemplate)?.paperworkType?.name;
}

export function usePaperworkTypeColumn(): GridSingleSelectColDef<Paperwork> {
  const { data } = useReadPaperworkTypeListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'paperworkType',
      headerName: 'Tipologia richiesta autorizzazione',
      type: 'singleSelect',
      valueOptions,
      groupingValueGetter: (value, row) => getPaperworkTypeName(row),
      valueGetter: (value, row) => {
        return getPaperworkTypeId(row);
      },
    }),
    [valueOptions]
  );
}
