import { z } from 'zod';
import { CountrySchema } from '@top-solution/microtecnica-utils';

export const CountryCategorySchema = z.enum(['NATO', 'EU', 'NON-NATO']);

export const EnabledCountrySchema = z.object({
  code: CountrySchema.shape.id,
  category: CountryCategorySchema,
});
export type EnabledCountry = z.infer<typeof EnabledCountrySchema>;
