import React from 'react';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { formatDateTime } from '../../../utils/dates';
import { DetailPanel, DetailPanelRow } from '../PaperworkDetail/DetailPanel';

function SigningDetailPanelComponent() {
  const { paperwork } = usePaperwork();

  return (
    <DetailPanel>
      <DetailPanelRow
        rowTitle={'PEC Inviata'}
        rowValue={paperwork?.pecStatus?.sentAt ? formatDateTime(new Date(paperwork?.pecStatus?.sentAt)) : `—`}
      />
      <DetailPanelRow
        rowTitle={'PEC Consegnata'}
        rowValue={paperwork?.pecStatus?.receivedAt ? formatDateTime(new Date(paperwork?.pecStatus?.receivedAt)) : `—`}
      />
      <DetailPanelRow
        rowTitle={'PEC Accettata'}
        rowValue={paperwork?.pecStatus?.acceptedAt ? formatDateTime(new Date(paperwork?.pecStatus?.acceptedAt)) : `—`}
      />
    </DetailPanel>
  );
}

export const SigningDetailPanel = React.memo(SigningDetailPanelComponent);
