import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { BackButton, NavigationButton, SaveButton } from '../../../components/NavigationPanel/NavigationButtons';
import { Attachment } from '../../../entities/Attachment';
import { EquipmentDraft } from '../../../entities/Equipment';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../hooks/useNotifications';
import {
  // MaterialsSections,
  SerniListUpdatePhases,
  DataEntryPages,
  DataEntrySections,
} from '../../../hooks/useSerniListUpdate/serniListUpdateNavigation';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useSerniListUpdateNavigation } from '../../../hooks/useSerniListUpdate/useSerniListUpdateNavigation';
import {
  usePatchSerniListUpdateCompletedPagesMutation,
  useUpdateSerniListUpdateMutation,
} from '../../../services/serniListUpdate';
import { AttachmentsStep } from './AttachmentsStep/AttachmentsStep';
import { EquipmentsStep } from './EquipmentsStep/EquipmentsStep';
const InfoTooltipPlaceholder = styled(Stack)(({ theme }) => ({ flex: `0 0 ${theme.spacing(4)}` }));

export default function SerniListUpdateDataEntryPagePage() {
  const navigate = useNavigate();
  const { pushPredefinedNotification } = useNotifications();

  const [updateSerniListUpdate, updateSerniListUpdateRequest] = useUpdateSerniListUpdateMutation();

  const { routes, detailsPath } = useSerniListUpdateNavigation();
  const { serniListUpdate, serniListUpdateRequest } = useSerniListUpdate();
  const dataEntrySteps: Array<DataEntrySections> = [];
  const dataEntryRoute = routes[SerniListUpdatePhases.DataEntry].pages[DataEntryPages.DataEntry];
  const dataEntrySections = dataEntryRoute.sections;
  const [updatePage] = usePatchSerniListUpdateCompletedPagesMutation();

  for (const [pageName] of Object.entries(dataEntryRoute.sections)) {
    dataEntrySteps.push(pageName as DataEntrySections);
  }

  const activeStepName = useMatch(dataEntryRoute.absoluteMatcher)?.params?.['*'] as DataEntrySections | undefined;
  const activeStepIndex = activeStepName ? dataEntrySteps.indexOf(activeStepName) : -1;
  const activeStep = activeStepName ? dataEntrySections[activeStepName] : undefined;

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [equipments, setEquipments] = useState<EquipmentDraft[]>([]);
  const [attachments, setAttachments] = useState<Attachment['attachment'][]>([]);

  useEffect(() => {
    if (serniListUpdateRequest.isSuccess && serniListUpdate) {
      setEquipments(serniListUpdate.equipmentList);
      setAttachments(serniListUpdate.attachmentList);
    }
  }, [serniListUpdateRequest.isSuccess, serniListUpdate]);

  const handleForward = async () => {
    if (activeStepIndex > dataEntrySteps.length - 2) {
      if (serniListUpdate && serniListUpdate.equipmentList.length > 0) {
        // At the end of the flow set the page as completed
        await updatePage({
          id: serniListUpdate.id,
          completedPages: {
            dataEntry: { dataEntryCompleted: true },
          },
        }).unwrap();

        navigate(detailsPath);
      }
    } else {
      navigate(dataEntrySteps[activeStepIndex + 1]);
    }
  };

  const handleBack = () => {
    navigate(dataEntrySteps[activeStepIndex - 1]);
  };

  async function handleSave() {
    if (serniListUpdate) {
      await updateSerniListUpdate({
        ...serniListUpdate,
        statusId: serniListUpdate.status.id,
        priorityId: serniListUpdate.priority.id,
        equipmentList: equipments.map((equipment) => ({
          equipment: equipment.equipment,
          equipmentDescription: equipment.equipmentDescription,
          equipmentProgram: equipment.equipmentProgram,
        })),
        attachmentList: attachments.map((attachment) => attachment),
      });

      // At the end of the flow set the page as completed
      await updatePage({
        id: serniListUpdate.id,
        completedPages: {
          dataEntry: { dataEntryCompleted: equipments.length > 0 },
        },
      }).unwrap();

      setUnsavedChanges(false);

      pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_SAVE_SUCCESS);
    }
  }

  if (!activeStep) {
    if (dataEntrySections && dataEntrySteps[0] && dataEntrySections[dataEntrySteps[0]]) {
      return <Navigate to={dataEntrySections[dataEntrySteps[0]]?.path} replace />;
    }
    return null;
  }

  const leftActions = (
    <>
      <NavigationButton component={Link} to={detailsPath}>
        {`Torna alla dashboard pratica`}
      </NavigationButton>
    </>
  );

  const saveButton = (
    <SaveButton
      key="save"
      onClick={() => handleSave()}
      loading={updateSerniListUpdateRequest.isLoading}
      disabled={!unsavedChanges}
    />
  );

  const stepper =
    dataEntrySteps.length > 1 || activeStep?.tooltip ? (
      <Stack direction="row" alignItems="center" gap={4} sx={{ padding: 2, paddingTop: 1 }}>
        <InfoTooltipPlaceholder />
        {dataEntrySteps.length > 1 ? (
          <Stepper activeStep={activeStepIndex} nonLinear sx={{ flex: 1, height: 32 }}>
            {dataEntrySteps.map((stepName) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={stepName} {...stepProps}>
                  <StepLabel {...labelProps}>{dataEntrySections[stepName].title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <InfoTooltipPlaceholder>
          {activeStep.tooltip ? <InfoTooltip>{activeStep.tooltip}</InfoTooltip> : null}
        </InfoTooltipPlaceholder>
      </Stack>
    ) : (
      <Box sx={{ padding: 1 }} />
    );

  return (
    <Routes>
      <Route
        path={dataEntrySections[DataEntrySections.Equipments].matcher}
        element={
          <EquipmentsStep
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            onForward={handleForward}
            leftActions={leftActions}
            stepper={stepper}
            equipments={equipments}
            onEquipmentsChange={(equipments: EquipmentDraft[]) => {
              setEquipments(equipments);
              setUnsavedChanges(true);
            }}
            saveButton={saveButton}
          />
        }
      />
      <Route
        path={dataEntrySections[DataEntrySections.Attachments].matcher}
        element={
          <AttachmentsStep
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            onForward={handleForward}
            leftActions={leftActions}
            stepper={stepper}
            attachments={attachments}
            onAttachmentsChange={(attachments: Attachment['attachment'][]) => {
              setAttachments(attachments);
              setUnsavedChanges(true);
            }}
            saveButton={saveButton}
          />
        }
      />
    </Routes>
  );
}
