import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertCircleIcon } from '../../../components/Icons';
import { Attachment } from '../../../entities/Attachment';
import { PAPERWORK_FIELD_DATATYPE } from '../../../entities/Paperwork';
import { usePaperworkFields } from '../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { formatDateTime } from '../../../utils/dates';
import { AttachmentDeleteDialog } from '../DataEntry/Documents/AttachmentsDeleteDialog';
import { DownloadButton } from '../DataEntry/Documents/DownloadButton';

interface MailAttachmentCardProps {
  mailAttachmentContextId: number;
  attachment: Attachment;
  disableDelete?: boolean;
  checked: boolean;
  onCheckChange?: (checked: boolean) => void;
}

function MailAttachmentCardComponent(props: MailAttachmentCardProps) {
  const { attachment, checked, onCheckChange, mailAttachmentContextId } = props;

  const { searchFieldValue } = usePaperworkFields();
  const { routeByContextId } = usePaperworkNavigation();
  const attachmentRoute = routeByContextId.get(attachment.savedInContextId);

  const isMailAttachment = useMemo(
    () => attachment.savedInContextId === mailAttachmentContextId,
    [attachment.savedInContextId, mailAttachmentContextId]
  );

  const digitallySigned = useMemo(() => {
    if (isMailAttachment) {
      return undefined;
    }

    const fieldValue = searchFieldValue({
      fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED,
      attachmentID: attachment.attachment.id,
    });

    if (!fieldValue) {
      return undefined;
    }

    return fieldValue.value === 'yes';
  }, [attachment, isMailAttachment, searchFieldValue]);

  function handleCheckChange() {
    onCheckChange?.(!checked);
  }

  return (
    <Paper
      sx={{
        flex: 1,
        minWidth: 640,
        maxWidth: 736,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          paddingX: 1,
          paddingY: 1.5,
        }}
      >
        <Stack direction="row" alignItems="flex-start" gap={1}>
          <Checkbox
            checked={checked || isMailAttachment}
            onChange={handleCheckChange}
            sx={{ paddingY: '5px' }}
            disabled={isMailAttachment}
          />
          <Stack direction="column" flex={1} gap={1}>
            <Stack direction="row" alignItems="center" gap={1} flex={1}>
              <Typography
                variant="h6"
                flex={1}
                overflow="hidden"
                maxWidth="100%"
                textOverflow="ellipsis"
                sx={{ cursor: 'pointer' }}
                onClick={handleCheckChange}
              >
                {attachment.attachment.name}
              </Typography>
              <DownloadButton attachment={attachment} />
              {isMailAttachment ? <AttachmentDeleteDialog attachment={attachment} /> : null}
            </Stack>
            <Stack direction="row" alignItems="center" gap={2} flex={1}>
              {attachmentRoute ? (
                <Typography variant="body2" fontWeight={500} color="primary.dark" marginRight="auto">
                  {attachmentRoute.title}
                </Typography>
              ) : null}
              <Typography variant="body2">{`${formatDateTime(new Date(attachment.attachment.uploadDate))}`}</Typography>
              {!isMailAttachment ? (
                digitallySigned === true ? (
                  <Chip variant="outlined" color="primary" size="small" label="Firmato digitalmente" />
                ) : digitallySigned === false ? (
                  <Chip
                    variant="outlined"
                    color="warning"
                    size="small"
                    label="Senza firma"
                    icon={<AlertCircleIcon />}
                  />
                ) : null
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
}

export const MailAttachmentCard = React.memo(MailAttachmentCardComponent);
