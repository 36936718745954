import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadPaperworkStatusListQuery } from '../../../services/paperwork';

function PaperworkStatusSelectComponent(props: TextFieldProps, ref: React.Ref<HTMLDivElement>) {
  const { label, ...otherProps } = props;

  const statusList = useReadPaperworkStatusListQuery();

  return (
    <TextField label={label} variant="outlined" select {...otherProps} ref={ref}>
      {statusList.data ? (
        statusList.data.list.map((status) => (
          <MenuItem key={status.id} value={status.id}>
            {status.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value={110}>-</MenuItem>
      )}
    </TextField>
  );
}

export default React.forwardRef(PaperworkStatusSelectComponent);
