import { PecSettings, PecSettingsSchema } from '../entities/Pec';
import { api, TAG_TYPES } from './baseApi';

const url = 'pec-settings';

export const pecSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPecSettings: builder.query<PecSettings, void>({
      query: () => ({ method: 'GET', url }),
      transformResponse: (data) => PecSettingsSchema.parse(data),
      providesTags: () => [{ type: TAG_TYPES.PEC_SETTINGS, id: 'LIST' }],
    }),
  }),
});

export const { useReadPecSettingsQuery } = pecSettingsApi;
