import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { DataGrid } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../../components/Layout';
import { PaperworkTemplate } from '../../../entities/Paperwork';
import {
  PaperworkPhases,
  DataEntryPages,
  MaterialsSections,
  StakeholdersSections,
  AttachmentsSections,
} from '../../../hooks/usePaperwork/paperworkNavigation';
import { useReadPaperworkTemplatesListQuery } from '../../../services/paperwork';

function PanelDetail(props: { row: PaperworkTemplate }) {
  const apiRef = useGridApiRef();

  const [rowGroupingModel, setRowGroupingModel] = React.useState(['context']);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    rowGroupingModel,
  });

  const rows = useMemo(() => {
    const rows = [];
    for (const ctx of props.row.paperworkContextList) {
      const label = [ctx.phase, ctx.page, ctx.section, ctx.subsection].filter((t) => t !== undefined).join(' / ');

      for (const field of ctx.fieldList ?? []) {
        rows.push({ context: label, ...field });
      }
    }

    return rows;
  }, [props.row.paperworkContextList]);

  return (
    <Box padding={1}>
      <Paper sx={{ minHeight: 352, minWidth: 250, maxWidth: '90vw' }}>
        <DataGridPremium
          apiRef={apiRef}
          rowGroupingModel={rowGroupingModel}
          density="compact"
          columns={[
            { field: 'context', flex: 2, width: 400 },
            { field: 'id', type: 'number', width: 120 },
            { field: 'datatype', flex: 1 },
            { field: 'isRequired', type: 'boolean', width: 60 },
            { field: 'label', flex: 1 },
            { field: 'linkedTo', flex: 1 },
          ]}
          initialState={{
            ...initialState,
            sorting: {
              sortModel: [{ field: 'context', sort: 'asc' }],
            },
          }}
          rows={rows ?? []}
          sx={{ flex: 1 }}
          defaultGroupingExpansionDepth={-1}
          onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
          hideFooter
        />
      </Paper>
    </Box>
  );
}

function TemplatesDebugComponent() {
  const { data: templates } = useReadPaperworkTemplatesListQuery();

  const tcolumns = useMemo<GridColDef<PaperworkTemplate>[]>(() => {
    const defaultColDef: Partial<GridColDef> = {
      width: 320,
    };
    const tcolumns: GridColDef<PaperworkTemplate>[] = [
      {
        field: 'id',
        type: 'number',
        valueGetter: (_, row) => row.id,
      },
      {
        field: 'warn-select-material',
        type: 'boolean',
        headerName: 'Manca il template per selezionare il materiale',
        valueGetter: (_, row) =>
          !row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              (c.section === MaterialsSections.SelectPartNumbersWithoutPn ||
                c.section === MaterialsSections.SelectPartNumbers ||
                c.section === MaterialsSections.SelectPartNumbersWithoutEquipment)
          ),
      },
      {
        field: 'warn-stakeholders',
        headerName: 'Mancano i template per gli stakeholder',
        type: 'boolean',
        valueGetter: (_, row) =>
          !row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Stakeholders &&
              c.section === StakeholdersSections.ShipTo
          ),
      },
      {
        field: 'warn-attachments',
        headerName: 'Mancano i template per gli attachment',
        type: 'boolean',
        valueGetter: (_, row) =>
          !row.paperworkContextList.find(
            (c) => c.phase === PaperworkPhases.DataEntry && c.page === DataEntryPages.Attachments
          ),
      },
      {
        field: 'paperworkType',
        type: 'string',
        ...defaultColDef,
        valueGetter: (_, row) => row.paperworkType.name,
      },
      {
        field: 'movementType',
        type: 'string',
        ...defaultColDef,
        valueGetter: (_, row) => row.movementType?.name,
      },
      {
        field: 'saleType',
        type: 'string',
        ...defaultColDef,
        valueGetter: (_, row) => row.saleType.name,
      },
      {
        field: 'countryCategory',
        type: 'string',
        valueGetter: (_, row) => row.countryCategory,
      },
      {
        field: 'configuration',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) => row.paperworkContextList.find((c) => c.phase === PaperworkPhases.Configuration),
      },
      {
        field: 'data-entry-materials-billing-years',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.BillingYears
          ),
      },
      {
        field: 'data-entry-materials-select-part-numbers',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.SelectPartNumbers
          ),
      },
      {
        field: 'data-entry-materials-select-part-numbers-without-pn',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.SelectPartNumbersWithoutPn
          ),
      },
      {
        field: 'data-entry-materials-select-part-numbers-without-equipment',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.SelectPartNumbersWithoutEquipment
          ),
      },
      {
        field: 'data-entry-materials-materials-data',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.MaterialsData
          ),
      },
      {
        field: 'data-entry-materials-additional-economic-conditions',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Materials &&
              c.section === MaterialsSections.AdditionalEconomicConditions
          ),
      },
      {
        field: 'data-entry-stakeholders-ship-to',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Stakeholders &&
              c.section === StakeholdersSections.ShipTo
          ),
      },
      {
        field: 'data-entry-stakeholders-order-from',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Stakeholders &&
              c.section === StakeholdersSections.OrderFrom
          ),
      },
      {
        field: 'data-entry-stakeholders-bill-to',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Stakeholders &&
              c.section === StakeholdersSections.BillTo
          ),
      },
      {
        field: 'data-entry-stakeholders-end-user',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Stakeholders &&
              c.section === StakeholdersSections.EndUser
          ),
      },
      {
        field: 'data-entry-attachments-eus',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Attachments &&
              c.section === AttachmentsSections.Eus
          ),
      },
      {
        field: 'data-entry-attachments-order',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Attachments &&
              c.section === AttachmentsSections.Order
          ),
      },
      {
        field: 'data-entry-attachments-visura-camerale',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Attachments &&
              c.section === AttachmentsSections.VisuraCamerale
          ),
      },
      {
        field: 'data-entry-attachments-other-documents',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) =>
              c.phase === PaperworkPhases.DataEntry &&
              c.page === DataEntryPages.Attachments &&
              c.section === AttachmentsSections.OtherDocuments
          ),
      },
      {
        field: 'data-entry-contractual-terms',
        type: 'boolean',
        ...defaultColDef,
        valueGetter: (_, row) =>
          row.paperworkContextList.find(
            (c) => c.phase === PaperworkPhases.DataEntry && c.page === DataEntryPages.ContractualTerms
          ),
      },
    ];
    return tcolumns;
  }, []);

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    ({ row }) => {
      return <PanelDetail row={row} />;
    },
    []
  );

  return (
    <AuthGuard>
      <Layout title="Template debug" maxWidth={false}>
        <DataGrid
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            },
          }}
          columns={tcolumns}
          rows={templates ?? []}
          getDetailPanelHeight={() => 'auto'}
          getDetailPanelContent={getDetailPanelContent}
          onCellClick={() => {
            //
          }}
          disableRowGrouping
          disableAggregation
          checkboxSelection
        />
      </Layout>
    </AuthGuard>
  );
}

export const TemplatesDebug = React.memo(TemplatesDebugComponent);
