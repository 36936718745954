import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { Currency } from '../../../../Currency/currency';

export function useMaterialCustomerBillingValuePerPNColumn(): GridColDef {
  const { data: currencies } = useReadCurrencyListQuery();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );
  return useMemo(
    () => ({
      headerName: 'Valore di Fatturazione (verso Cliente) per PN',
      type: 'number',
      width: 320,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN]?.value);
        return Number.isNaN(v) ? null : v;
      },
      valueFormatter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        if (!currenciesById || !row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]?.value) return value;
        return `${value} ${currenciesById!.get(Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]!.value))?.symbol}`;
      },
    }),
    [currenciesById]
  );
}

export function useMaterialVendorBillingValuePerPNColumn(): GridColDef {
  const { data: currencies } = useReadCurrencyListQuery();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );
  return useMemo(
    () => ({
      headerName: 'Valore di Fatturazione (verso Fornitore) per PN',
      type: 'number',
      width: 320,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN]?.value);
        return Number.isNaN(v) ? null : v;
      },
      valueFormatter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        if (!currenciesById || !row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]?.value) return value;
        return `${value} ${currenciesById!.get(Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]!.value))?.symbol}`;
      },
    }),
    [currenciesById]
  );
}
