import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { SearchPaperworkListPayload } from '../../entities/Paperwork';
import { AdvancedFilters } from './AdvancedFilters/AdvancedFilters';
import { AdvancedFiltersButton } from './AdvancedFilters/AdvancedFiltersButton';
import PaperworkListGrid from './PaperworkListGrid';
import { paperworkCreateSection, paperworkSection } from './sections';

const PAGE_TITLE = paperworkSection.title;

export default function PaperworkList(): React.ReactElement {
  const [advancedFilters, setAdvancedFilters] = useState<SearchPaperworkListPayload>({
    serniCode: [],
    pn: [],
    eccn: [],
  });
  const [advacendFiltersOpen, setAdvacendFiltersOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Layout
      title={PAGE_TITLE}
      header={
        <PageTitle
          pageTitle={PAGE_TITLE}
          rightItems={
            <>
              <AdvancedFiltersButton
                checked={advacendFiltersOpen}
                advancedFilters={advancedFilters}
                onChange={() => setAdvacendFiltersOpen(!advacendFiltersOpen)}
              />

              <Button
                color="primary"
                onClick={() => {
                  navigate(`${paperworkCreateSection.path}`);
                }}
                size="small"
                variant="contained"
              >
                Crea Pratica
              </Button>
            </>
          }
        />
      }
      maxWidth={false}
    >
      <Stack direction="column" minHeight={0}>
        <AdvancedFilters
          open={advacendFiltersOpen}
          advancedFilters={advancedFilters}
          onAdvancedFiltersChange={setAdvancedFilters}
        />
        <DataGridWrapper flex={'1 1 auto'}>
          <PaperworkListGrid advancedFilters={advancedFilters} />
        </DataGridWrapper>
      </Stack>
    </Layout>
  );
}
