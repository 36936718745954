import React from 'react';
import { usePaperworkReviews } from './usePaperworkReviews';

interface ReviewGuardProps {
  editReview?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Renders the child(ren) if the user can currently see the paperwork revision, or make reviews.
 */
function ReviewGuardComponent(props: ReviewGuardProps) {
  const { children, editReview } = props;

  const { showReviews, showEditReviews } = usePaperworkReviews();

  if (editReview) {
    if (showEditReviews) {
      return children;
    }

    return null;
  }

  if (showReviews) {
    return children;
  }

  return null;
}

export const ReviewGuard = React.memo(ReviewGuardComponent);
