import React from 'react';
import TextField from '@mui/material/TextField';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function ContractualTermsLoiReferenceTextFieldComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  return (
    <TextField
      fullWidth
      label={field.label}
      value={fieldValue?.value ?? ''}
      onChange={(event) => {
        return updateValue(event.target.value ?? undefined);
      }}
    />
  );
}

export const ContractualTermsLoiReferenceTextField = React.memo(ContractualTermsLoiReferenceTextFieldComponent);
