import React from 'react';
import Badge from '@mui/material/Badge';
import { ButtonProps } from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SearchPaperworkListPayload } from '../../../entities/Paperwork';

function AdvancedFiltersButtonComponent(
  props: ButtonProps & { checked: boolean; onChange: () => void; advancedFilters: SearchPaperworkListPayload }
) {
  const button = (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={props.checked ? 'filters' : ''}
      exclusive
      onChange={props.onChange}
      aria-label="Platform"
    >
      <ToggleButton value="filters" sx={{ lineHeight: 1 }}>
        {'Filtri avanzati'}
      </ToggleButton>
    </ToggleButtonGroup>
  );

  let filtersLength = 0;
  for (const [_, filter] of Object.entries(props.advancedFilters)) {
    filtersLength = filtersLength + filter.length;
  }

  if (filtersLength > 0) {
    return (
      <Badge badgeContent={`${filtersLength}`} color="primary">
        {button}
      </Badge>
    );
  }

  return button;
}

export const AdvancedFiltersButton = React.memo(AdvancedFiltersButtonComponent);
