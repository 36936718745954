import React from 'react';
import { BadgeProps } from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';

export interface InlineBadgeLabelProps extends StackProps {
  badgeColor: BadgeProps['color'];
}

function InlineBadgeLabelComponent(props: InlineBadgeLabelProps) {
  const { badgeColor, children, ...stackProps } = props;
  return (
    <Stack direction="row" alignItems="baseline" {...stackProps} gap={'0.8ch'}>
      <Box
        sx={{
          width: '1em',
          height: '1em',
          backgroundColor: `${badgeColor}.main`,
          borderRadius: '50%',
          position: 'relative',
          top: '0.125em',
        }}
      />
      {children}
    </Stack>
  );
}

export const InlineBadgeLabel = React.memo(InlineBadgeLabelComponent);
