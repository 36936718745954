import React, { useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { eccnColumn } from '../../../../../../components/DataGrid/eccnColumn';
import { htsColumn } from '../../../../../../components/DataGrid/htsColumn';
import { IncompleteRowsDatagrid } from '../../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { materialDescriptionColumn } from '../../../../../../components/DataGrid/materialDescriptionColumn';
import { partNumberColumn } from '../../../../../../components/DataGrid/partNumberColumn';
import { useProgramColumn } from '../../../../../../components/DataGrid/programColumn';
import { useSerniCodeColumn } from '../../../../../../components/DataGrid/serniCodeColumn';
import { MaterialDraft } from '../../../../../../entities/Material';
import { ImportMaterialsFooter } from './ImportMaterialsFooter';
import { ImportMaterialsNoRows } from './ImportMaterialsNoRows';

interface FilterTabProps {
  searchResults: MaterialDraft[];
  onNext: (materials: MaterialDraft[]) => void;
  onCancel: () => void;
  withoutEquipment?: boolean;
}

const gridInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 50,
    },
  },
};

function FilterTabComponent(props: FilterTabProps) {
  const { searchResults, onCancel, onNext, withoutEquipment } = props;

  const programColumn = useProgramColumn();
  const serniCodeColumn = useSerniCodeColumn();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const dataGridColumns = useMemo(() => {
    let columns = [partNumberColumn, materialDescriptionColumn, programColumn];
    if (!withoutEquipment) {
      columns.push(serniCodeColumn);
    }
    columns = columns.concat([eccnColumn, htsColumn]);
    return columns;
  }, [programColumn, serniCodeColumn, withoutEquipment]);

  const searchResultsByDraftId = useMemo(() => {
    const byId: Record<string, MaterialDraft> = {};

    if (searchResults) {
      for (const material of searchResults) {
        byId[material.draftId] = material;
      }
    }
    return byId;
  }, [searchResults]);

  const rows: Array<MaterialDraft | Pick<MaterialDraft, 'draftId' | 'pn'>> = useMemo(() => {
    return [...searchResults];
  }, [searchResults]);

  const incompleteManualRows = useMemo(() => rows.find((row) => !('program' in row)), [rows]);

  return (
    <Stack direction="column" flex="1 1 0" minHeight="100%">
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1, minHeight: 0 }}>
        {incompleteManualRows ? (
          <Alert severity="warning">
            {
              'I materiali contrassegnati in giallo non sono state importati e i dati mancanti dovranno essere integrati manualemente.'
            }
          </Alert>
        ) : rows.length > 2 ? (
          <Alert severity="info">
            {'I risultati possono essere ulteriormente raffinati applicando i filtri della tabella.'}
          </Alert>
        ) : null}
        <Paper variant="outlined" sx={{ flex: 1, minHeight: 0 }}>
          <IncompleteRowsDatagrid
            density="compact"
            rows={rows}
            columns={dataGridColumns}
            disableRowGrouping
            disableAggregation
            disableDensitySelector
            disableColumnSelector
            disableColumnPinning
            hideFooter
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            initialState={gridInitialState}
            rowSelectionModel={rowSelectionModel}
            getRowId={(material) => material.draftId}
            slots={{
              footer: ImportMaterialsFooter,
              noRowsOverlay: () => <ImportMaterialsNoRows />,
            }}
            getRowClassName={(params) => (!params.row.program ? 'incomplete' : '')}
            sx={{ border: 0 }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {'Annulla'}
        </Button>
        <Button
          onClick={() => onNext(rowSelectionModel.map((draftId) => searchResultsByDraftId[draftId]))}
          disabled={rowSelectionModel.length === 0}
        >
          {rowSelectionModel.length > 0 ? `Aggiungi ${rowSelectionModel.length} materiali` : 'Aggiungi'}
        </Button>
      </DialogActions>
    </Stack>
  );
}

export const FilterTab = React.memo(FilterTabComponent);
