import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { CheckConfirmDialog } from '../CheckConfirmDialog';

function CancelButtonComponent(props: Omit<ButtonProps, 'onClick'> & { onConfirm: () => void }) {
  const { onConfirm, ...buttonProps } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="secondary" variant="outlined" {...buttonProps} onClick={handleClickOpen}>
        {'Annulla'}
      </Button>
      <CheckConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={onConfirm}
        dialogTitleLabel="Annulla le modifiche non salvate"
        dialogDescriptionLabel={
          "Tutte le modifiche effettuate su questa pagina a partire dall'ultimo salvataggio saranno annullate e non potranno essere recuperate."
        }
        confirmTextLabel={"Confermo di voler annullare tutte le modifiche effettuate dall'ultimo salvataggio"}
        cancelButtonLabel={'Mantieni modifiche'}
        confirmButtonLabel={'Annulla modifiche'}
        maxWidth="md"
      />
    </>
  );
}

export const CancelButton = React.memo(CancelButtonComponent);
