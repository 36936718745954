import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AttachmentDraft } from '../entities/Attachment';
import { usePatchAttachmentListMutation } from '../services/attachment';
import {
  createAttachment,
  deleteAttachment,
  discardOperations,
  selectAttachmentsState,
  setSavingState,
  updateAttachment,
} from '../store/attachmentsStateSlice';

export function useAttachments() {
  const dispatch = useDispatch();

  const [_, patchAttachmentListRequest] = usePatchAttachmentListMutation();

  const { attachments, operations } = useSelector(selectAttachmentsState);

  // Operations grouped by the attachment id (ignores stakeholder permutations)
  const uniqueOperations = useMemo(() => {
    const operationsWithoutStakeholder = Object.keys(operations)
      .filter((value) => value !== undefined)
      .map((draftId) => draftId.replace(/--[0-9]+$/, ''));
    return Array.from(new Set(operationsWithoutStakeholder)).length;
  }, [operations]);

  return useMemo(() => {
    return {
      /**
       * Save the state of all attachments for the current paperwork.
       */
      saveAttachments: async () => {
        /**
         * Dispatch the saving state
         * Do not save directly but "enqueue" the saving action, so that redux does that after
         * applying all the operations
         */
        dispatch(setSavingState({ saving: true }));
      },
      /**
       * Update a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      createAttachment: (attachment: AttachmentDraft) => dispatch(createAttachment({ attachmentDraft: attachment })),
      /**
       * Update a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      updateAttachment: (currentDraftId: string | undefined, attachment: AttachmentDraft) =>
        dispatch(
          updateAttachment({
            currentDraftId: currentDraftId,
            attachmentDraft: attachment,
          })
        ),
      /**
       * Delete a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      deleteAttachment: (draftId: AttachmentDraft['draftId'], id: AttachmentDraft['id']) =>
        dispatch(
          deleteAttachment({
            draftId: draftId,
            id: id,
          })
        ),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchAttachmentListRequest: patchAttachmentListRequest,
      discardOperations: () => dispatch(discardOperations()),
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      attachments: attachments,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: uniqueOperations,
    };
  }, [patchAttachmentListRequest, attachments, operations, uniqueOperations, dispatch]);
}
