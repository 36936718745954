import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../store/notificationsSlice';

// TODO: please keep sorted
export enum NOTIFICATION_PREDEFINED_MESSAGES {
  PAPERWORK_SAVE_ERROR = 'Errore durante il salvataggio della pratica',
  PAPERWORK_SAVE_SUCCESS = 'Pratica salvata',
  PAPERWORK_DISCARD_SUCCESS = 'Modifiche annullate',
}

export function useNotifications() {
  const dispatch = useDispatch();
  const uiPushNotification = useCallback(
    (message: string) => {
      dispatch(pushNotification({ message, id: `${message}-${new Date().toISOString()}` }));
    },
    [dispatch]
  );

  const uiPushPredefinedNotification = useCallback(
    (message: NOTIFICATION_PREDEFINED_MESSAGES) => {
      uiPushNotification(message);
    },
    [uiPushNotification]
  );

  return useMemo(
    () => ({ pushNotification: uiPushNotification, pushPredefinedNotification: uiPushPredefinedNotification }),
    [uiPushNotification, uiPushPredefinedNotification]
  );
}
