import { useMemo } from 'react';
import { GridActionsColDef, GridColDef, GridPinnedColumnFields, GridRowModel } from '@mui/x-data-grid-premium';
import { DataGrid, DataGridProps, ErrorAlert } from '@top-solution/microtecnica-mui';
import { Equipment } from '../../entities/Equipment';
import { useReadEquipmentListQuery } from '../../services/equipmentiApi';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

type CurrencyListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'error'
  | 'columns'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
>;

export default function EquipmentListGrid(props: CurrencyListGridProps): JSX.Element {
  const { ...gridProps } = props;
  const { data, isLoading, error } = useReadEquipmentListQuery();
  const rows = useMemo<GridRowModel<Equipment>[]>(() => data?.list.map((item) => item) ?? [], [data]);

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'id', headerName: 'ID', filterable: true, width: 80 },
      { field: 'description', headerName: 'Descrizione', flex: 1 },
      { field: 'secrecyCategory', headerName: 'Classe segretezza', width: 80 },
      { field: 'equipmentType', headerName: 'Tipologia', width: 80 },
      { field: 'category', headerName: 'Categoria', width: 80 },
      { field: 'paragraph', headerName: 'Paragrafo', width: 80 },
      { field: 'subParagraph', headerName: 'Sotto Paragrafo', width: 80 },
      { field: 'serniCode', headerName: 'N° Materiale', width: 200 },
    ],
    []
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <DataGrid
      density="compact"
      columns={columns}
      pinnedColumns={pinnedColumns}
      editMode="row"
      disableRowGrouping
      disableAggregation
      loading={isLoading}
      {...gridProps}
      columnVisibilityModel={{
        id: false,
      }}
      rows={rows}
      columnGroupingModel={[
        {
          groupId: 'Codice Materiale',
          children: [
            { field: 'equipmentType' },
            { field: 'category' },
            { field: 'paragraph' },
            { field: 'subParagraph' },
            { field: 'materialNumber' },
          ],
        },
        {
          groupId: 'MICROTECNICA S.R.L. - N° Iscr.: 00564',
          children: [{ field: 'description' }],
        },
      ]}
    />
  );
}
