import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import PaperworkCreatePage from './PaperworkCreatePage/PaperworkCreatePage';
import PaperworkDetailsRouter from './PaperworkDetailsRouter';
import PaperworkList from './PaperworkList';
import PaperworkTemplateList from './PaperworkTemplateList';

export default function PaperworkRouter(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Routes>
        <Route path="/" element={<PaperworkList />} />
        <Route path="templates" element={<PaperworkTemplateList />} />
        <Route path=":id/*" element={<PaperworkDetailsRouter />} />
        <Route path="create" element={<PaperworkCreatePage />} />
      </Routes>
    </AuthGuard>
  );
}
