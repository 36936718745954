import {
  Item,
  ItemSchema,
  PagedRequestParams,
  PagedResponse,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { procurementToolApi } from './procurementToolApi';

const url = 'items';

const itemApi = procurementToolApi.injectEndpoints({
  endpoints: (builder) => ({
    searchItem: builder.query<PagedResponse<Item>, PagedRequestParams & { isEnditem?: boolean }>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ItemSchema).parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useLazySearchItemQuery } = itemApi;
