import { GridColDef } from '@mui/x-data-grid-premium';
import { PAPERWORK_FIELD_DATATYPE, PaperworkField, PaperworkFieldValueDraft } from '../../entities/Paperwork';
import { stakeholderMilitaryBaseColDef } from './colDefs/stakeholderMilitaryBaseColDef';

export function getFieldColDef(
  field: PaperworkField,
  updateValue: (value: Partial<PaperworkFieldValueDraft>) => void
): GridColDef {
  const datatype = field.datatype as PAPERWORK_FIELD_DATATYPE;
  switch (datatype) {
    case PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE:
      return stakeholderMilitaryBaseColDef(field, updateValue);
  }

  return {
    field: `invalid---${field.datatype}`,
  };
}
