import { useMemo } from 'react';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { PAPERWORK_AGING, Paperwork } from '../../entities/Paperwork';
import { AgingInlineBadgeLabel, agingFromPaperworkHistory } from '../InlineBadgeLabel/AgingInlineBadgeLabel';

export function usePaperworkAgingColumn(): GridSingleSelectColDef<Paperwork> {
  const valueOptions = Object.values(PAPERWORK_AGING);

  return useMemo(
    () => ({
      field: 'aging',
      headerName: 'Aging',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Paperwork) => {
        return agingFromPaperworkHistory(row.historyList);
      },
      renderCell: ({ value }: GridRenderCellParams<Paperwork, PAPERWORK_AGING>) => (
        <AgingInlineBadgeLabel aging={value} />
      ),
    }),
    [valueOptions]
  );
}
