import React from 'react';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface DetailPanelProps extends ListProps {}

function DetailPanelComponent(props: DetailPanelProps) {
  const { children, ...listProps } = props;
  return (
    <Paper>
      <List
        {...listProps}
        sx={{
          width: 320,
          ...props.sx,
        }}
      >
        {children}
      </List>
    </Paper>
  );
}

export const DetailPanel = React.memo(DetailPanelComponent);

function DetailPanelEmptyValueComponent(props: TypographyProps) {
  return <Typography variant="caption" color="text.secondary" {...props} />;
}

export const DetailPanelEmptyValue = React.memo(DetailPanelEmptyValueComponent);

interface DetailPanelRowProps extends ListItemProps {
  direction?: 'row' | 'column';
  rowTitle: string;
  rowValue: string | React.ReactNode | React.ReactNode[];
  ellipsis?: boolean;
}

function DetailPanelRowComponent(props: DetailPanelRowProps) {
  const { direction, rowTitle, rowValue, ellipsis, ...listItemProps } = props;

  const rowDirection = direction === 'row';

  const value = (
    <Typography
      variant="body1"
      color="text.primary"
      component="div"
      overflow={ellipsis ? 'hidden' : undefined}
      textOverflow={ellipsis ? 'ellipsis' : undefined}
      whiteSpace={ellipsis ? 'nowrap' : undefined}
    >
      {rowValue}
    </Typography>
  );

  return (
    <ListItem
      alignItems="flex-start"
      {...listItemProps}
      sx={{
        paddingY: 0,
        borderBottom: '1px solid',
        borderBottomColor: 'divider',
        '&:last-of-type': {
          borderBottom: 0,
        },
        ...props.sx,
      }}
    >
      <Stack direction={direction ?? 'column'} sx={{ paddingY: 0.75, gap: 0, width: '100%' }}>
        <Typography
          component="span"
          color="text.secondary"
          sx={{ marginBottom: rowDirection ? 0 : 0.5, flex: rowDirection ? 1 : undefined }}
        >
          {`${rowTitle}:`}
        </Typography>
        {ellipsis ? <Tooltip title={rowValue}>{value}</Tooltip> : value}
      </Stack>
    </ListItem>
  );
}

export const DetailPanelRow = React.memo(DetailPanelRowComponent);
