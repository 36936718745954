import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Paperwork } from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { useReadStakeholderListQuery } from '../../services/stakeholder';

export function usePaperworkShipToColumn(): GridSingleSelectColDef<Paperwork> {
  const { data } = useReadStakeholderListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'shipTo',
      headerName: 'Ship to',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Paperwork) => {
        return row.stakeholderList?.find((s) => s.role === STAKEHOLDER_ROLE.SHIP_TO)?.id;
      },
    }),
    [valueOptions]
  );
}
