import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { useReadCurrencyListQuery } from '../../../../../services/currency';

export function useMaterialCurrencyColumn(): GridSingleSelectColDef {
  const { data } = useReadCurrencyListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);

  return useMemo(
    () => ({
      headerName: 'Valuta',
      type: 'singleSelect',
      valueOptions,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY,
      width: 150,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]?.value);
        return Number.isNaN(v) ? null : v;
      },
    }),
    [valueOptions]
  );
}
