import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Paperwork } from '../../entities/Paperwork';
import { useReadPaperworkPriorityListQuery } from '../../services/paperwork';
import { capitalize } from '../../utils/strings';

export function usePaperworkPriorityColumn(): GridSingleSelectColDef<Paperwork> {
  const { data } = useReadPaperworkPriorityListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: capitalize(v.name) })), [data]);
  return useMemo(
    () => ({
      field: 'priority',
      headerName: 'Priorità',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Paperwork) => {
        return row.priority.id;
      },
    }),
    [valueOptions]
  );
}
