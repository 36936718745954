import { useAuth } from '@top-solution/microtecnica-utils';
import { AppSection } from '../entities/AppSection';
import { countrySection } from '../pages/Country/sections';
import { currencySection } from '../pages/Currency/sections';
import { equipmentSection } from '../pages/Equipment/sections';
import { paperworkSection, paperworkTemplateSection } from '../pages/Paperwork/sections';
import { serniListUpdateSection } from '../pages/SerniListUpdate/sections';
import { userSection } from '../pages/User/sections';

export function useSectionList(): { adminSectionList: AppSection[]; userSectionList: AppSection[] } {
  const { isAdmin } = useAuth();

  const userSectionList: AppSection[] = [
    {
      ...paperworkSection,
    },
    {
      ...serniListUpdateSection,
    },
  ];

  const adminSectionList: AppSection[] = isAdmin
    ? [
        { ...countrySection },
        { ...currencySection },
        { ...paperworkTemplateSection },
        { ...userSection },
        { ...equipmentSection },
      ]
    : [];

  return {
    adminSectionList,
    userSectionList,
  };
}
