import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { darken } from '@mui/material/styles';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import { DropDownMenu } from '../DropDownMenu';
import { LinkedEntitiesDrawer } from './LinkedEntitiesDrawer';
import { UpdateStatusDialog } from './UpdateStatusDialog';

function PaperworkDebugMenuComponent() {
  const { paperwork } = usePaperwork();
  const [linkedEntitiesDrawerOpen, setLinkedEntitiesDrawerOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <DropDownMenu
        direction="up"
        label="Paperwork debug"
        slotProps={{
          button: {
            sx: {
              background: (theme) => {
                const v1 = darken(theme.palette.warning.main, 0.1);
                const v2 = darken(theme.palette.warning.dark, 0.2);
                return `repeating-linear-gradient(-45deg,${v1},${v1} 10px,${v2} 10px,${v2} 20px)`;
              },
            },
          },
        }}
      >
        {(onClose) => (
          <>
            <MenuItem
              onClick={() => {
                onClose();
                navigate(`/paperworks/${paperwork?.id}/debug`);
              }}
            >
              {'Paperwork debug'}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLinkedEntitiesDrawerOpen(true);
                onClose();
              }}
            >
              {'Linked entities debug'}
            </MenuItem>
            <UpdateStatusDialog onClose={onClose} />
          </>
        )}
      </DropDownMenu>
      <LinkedEntitiesDrawer open={linkedEntitiesDrawerOpen} onClose={() => setLinkedEntitiesDrawerOpen(false)} />
    </>
  );
}

export const PaperworkDebugMenu = React.memo(PaperworkDebugMenuComponent);
