import React from 'react';
import List, { ListProps } from '@mui/material/List';
import Paper from '@mui/material/Paper';

function WizardListComponent(props: ListProps) {
  return (
    <Paper>
      <List {...props} />
    </Paper>
  );
}

export const WizardList = React.memo(WizardListComponent);
