import React, { forwardRef, useMemo } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Country, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { useReadCountryListQuery } from '../../../services/countries';

export type CountryAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<Country['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'>;

function AvailableCountryAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: CountryAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, ...autocompleteProps } = props;
  const { data: countries, isFetching, error: loadingCountryError } = useReadCountriesQuery();

  const {
    data: availableCountries,
    isFetching: isFetchingAvailableCountries,
    error: loadingAvailableCountriesError,
  } = useReadCountryListQuery();

  const filteredCountries = useMemo(
    () => countries?.list.filter((c) => availableCountries?.find((availableCountry) => availableCountry.code === c.id)),
    [availableCountries, countries?.list]
  );

  const loadingError = useMemo(
    () => loadingCountryError || loadingAvailableCountriesError,
    [loadingAvailableCountriesError, loadingCountryError]
  );

  return (
    <Autocomplete
      value={value}
      options={filteredCountries?.map(({ id }) => id) ?? []}
      getOptionLabel={(countryCode) => countries?.byISO[countryCode]?.name ?? countryCode}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          inputRef={ref}
          sx={sx}
        />
      )}
      loading={isFetching || isFetchingAvailableCountries}
      {...autocompleteProps}
    />
  );
}

export const AvailableCountryAutocomplete = forwardRef(
  AvailableCountryAutocompleteComponent
) as typeof AvailableCountryAutocompleteComponent;
