import { createListenerMiddleware } from '@reduxjs/toolkit';
import { NOTIFICATION_PREDEFINED_MESSAGES } from '../../hooks/useNotifications';
import { attachmentApi } from '../../services/attachment';
import { materialAPi } from '../../services/material';
import { paperworksApi } from '../../services/paperwork';
import { stakeholderAPi } from '../../services/stakeholder';
import { closeNotification, pushNotification } from '../notificationsSlice';

export const notificationsMiddleware = createListenerMiddleware();

/**
 * Auto-close the notifications after 4 seconds
 */
notificationsMiddleware.startListening({
  actionCreator: pushNotification,
  effect: async (action, listenerApi) => {
    setTimeout(() => {
      listenerApi.dispatch(closeNotification({ id: action.payload.id }));
    }, 4000);
  },
});

const pushPaperworkSaveSuccessNotification = () =>
  pushNotification({
    message: NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_SAVE_SUCCESS,
    id: `${NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_SAVE_SUCCESS}-${new Date().toISOString()}`,
  });

/**
 * Show the "Paperwork saved" notification when a field is saved
 */
notificationsMiddleware.startListening({
  matcher: paperworksApi.endpoints.patchPaperworkValues.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(pushPaperworkSaveSuccessNotification());
  },
});

/**
 * Show the "Paperwork saved" notification when a material is saved
 */
notificationsMiddleware.startListening({
  matcher: materialAPi.endpoints.patchMaterialList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(pushPaperworkSaveSuccessNotification());
  },
});

/**
 * Show the "Paperwork saved" notification when a stakeholder is saved
 */
notificationsMiddleware.startListening({
  matcher: stakeholderAPi.endpoints.patchStakeholderList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(pushPaperworkSaveSuccessNotification());
  },
});

/**
 * Show the "Paperwork saved" notification when an attachment is saved
 */
notificationsMiddleware.startListening({
  matcher: attachmentApi.endpoints.patchAttachmentList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(pushPaperworkSaveSuccessNotification());
  },
});
