import React, { Fragment, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FileCard } from '../../../../components/FileCard';
import { FileDropzone } from '../../../../components/FileDropzone';
import { Attachment, addDraftIdToAttachment } from '../../../../entities/Attachment';
import {
  PAPERWORK_FIELD_DATATYPE,
  PaperworkRoute,
  addDraftIdToPaperworkFieldValue,
} from '../../../../entities/Paperwork';
import { StakeholderSchema } from '../../../../entities/Stakeholder';
import { useAttachments } from '../../../../hooks/useAttachments';
import { OrderSubsections } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { useStakeholders } from '../../../../hooks/useStakeholders';
import { useCreateAttachmentMutation } from '../../../../services/attachment';
import { useAddAttachmentDialogColumns } from './useAddAttachmentDialogColumns';

interface AddOrderDialogProps {
  section: PaperworkRoute;
}

function AddOrderDialogComponent(props: AddOrderDialogProps) {
  const { section } = props;
  const [open, setOpen] = React.useState(false);
  const [uploadedOrder, setUploadedOrder] = useState<Attachment['attachment']>();
  const [uploadedOrderConfirmation, setUploadedOrderConfirmation] = useState<Attachment['attachment']>();

  const [step, setStep] = useState<0 | 1>(0);

  const { updateValue } = usePaperworkFields();

  const { paperwork } = usePaperwork();
  const { stakeholders } = useStakeholders();
  const {
    createAttachment: createPaperworkAttachment,
    saveAttachments,
    discardOperations: discardAttachmentOperations,
  } = useAttachments();
  const { saveValues } = usePaperworkFields();
  const [createAttachment, createAttechmentRequest] = useCreateAttachmentMutation();

  const AddAttachmentFormSchema = useMemo(
    () =>
      z.object({
        orderNumber: z.string().min(1),
        orderDate: z.string().datetime(),
        orderFile: z
          .object(
            {
              name: z.string(),
            },
            { required_error: 'Caricare un documento' }
          )
          .passthrough(),
        orderConfirmationFile: z
          .object(
            {
              name: z.string(),
            },
            { required_error: 'Caricare un documento' }
          )
          .passthrough(),
        stakeholders: StakeholderSchema.shape.id.array().min(1, 'Selezionare almeno una parte coinvolta'),
      }),
    []
  );

  type AddAttachmentForm = z.infer<typeof AddAttachmentFormSchema>;

  const { control, handleSubmit, reset, watch } = useForm<AddAttachmentForm>({
    defaultValues: {
      orderNumber: '',
      orderDate: new Date().toISOString(),
      orderFile: undefined,
      orderConfirmationFile: undefined,
      stakeholders: [],
    },
    resolver: zodResolver(AddAttachmentFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dataGridColumns = useAddAttachmentDialogColumns();

  async function onSubmit(values: AddAttachmentForm) {
    if (uploadedOrder && uploadedOrderConfirmation && paperwork) {
      // Save the paperwork attachments (attachmentId ⨯ stakeholderId)
      await saveAttachments();

      // Push paperwork values for each paperwork attachment
      for (const stakeholder of values.stakeholders) {
        updateValue(
          addDraftIdToPaperworkFieldValue({
            fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_NUMBER,
            progIndex: 0,
            attachmentID: uploadedOrder.id,
            stakeholderID: stakeholder,
            value: values.orderNumber,
          })
        );
        updateValue(
          addDraftIdToPaperworkFieldValue({
            fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_DATE,
            progIndex: 0,
            attachmentID: uploadedOrder.id,
            stakeholderID: stakeholder,
            value: values.orderDate,
          })
        );
        updateValue(
          addDraftIdToPaperworkFieldValue({
            fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_NUMBER,
            progIndex: 0,
            attachmentID: uploadedOrderConfirmation.id,
            stakeholderID: stakeholder,
            value: values.orderNumber,
          })
        );
        updateValue(
          addDraftIdToPaperworkFieldValue({
            fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORDER_DATE,
            progIndex: 0,
            attachmentID: uploadedOrderConfirmation.id,
            stakeholderID: stakeholder,
            value: values.orderDate,
          })
        );
      }

      // Save the operations above
      saveValues();

      // Close the dialog
      handleClose();
    }
  }

  const orderFile = watch('orderFile');
  const orderConfirmationFile = watch('orderConfirmationFile');

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        {'Aggiungi ordine'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
        fullWidth
        maxWidth="lg"
        TransitionProps={{
          onExited: () => {
            reset();
            setStep(0);
            discardAttachmentOperations();
          },
        }}
      >
        <DialogTitle>{'Aggiungi ordine'}</DialogTitle>
        <DialogContent>
          {createAttechmentRequest.error ? (
            <Alert severity="error">{JSON.stringify(createAttechmentRequest.error, null, 2)}</Alert>
          ) : null}
          <DialogContentText component="div" display="flex" flexDirection="column" gap={2}>
            {step === 0 ? (
              <Fragment>
                <Stepper activeStep={step}>
                  <Step>
                    <StepLabel>{'Caricamento documenti'}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{'Dati ordine'}</StepLabel>
                  </Step>
                </Stepper>
                <Typography variant="h6" marginTop={2}>
                  {'Ordine'}
                </Typography>
                <Controller
                  control={control}
                  name="orderFile"
                  render={({ field, fieldState: { error } }) => (
                    <FileDropzone
                      error={error}
                      onChange={async (file) => {
                        field.onChange(file);

                        if (file) {
                          const createAttachmentResponse = await createAttachment({
                            file: file as unknown as File,
                          }).unwrap();
                          setUploadedOrder(createAttachmentResponse);
                        } else {
                          setUploadedOrder(undefined);
                        }
                        // setFile(file);
                      }}
                      value={field.value as unknown as File}
                    />
                  )}
                />
                <Typography variant="h6" marginTop={2}>
                  {"Accettazione dell'ordine"}
                </Typography>
                <Controller
                  control={control}
                  name="orderConfirmationFile"
                  render={({ field, fieldState: { error } }) => (
                    <FileDropzone
                      error={error}
                      onChange={async (file) => {
                        field.onChange(file);

                        if (file) {
                          const createAttachmentResponse = await createAttachment({
                            file: file as unknown as File,
                          }).unwrap();
                          setUploadedOrderConfirmation(createAttachmentResponse);
                        } else {
                          setUploadedOrderConfirmation(undefined);
                        }
                        // setFile(file);
                      }}
                      value={field.value as unknown as File}
                    />
                  )}
                />
              </Fragment>
            ) : uploadedOrder ? (
              <>
                <Grid container columnSpacing={1} rowSpacing={3}>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {"La data dell'ordine deve essere successiva alla data di rilascio del N.O."}
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="orderNumber"
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Numero d'ordine"
                          fullWidth
                          {...field}
                          sx={{ marginTop: 1 }}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="orderDate"
                      render={({ field }) => (
                        <DatePicker
                          label="Data dell'ordine"
                          {...field}
                          value={new Date(field.value)}
                          onChange={(date) => field.onChange(date?.toISOString())}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                          sx={{ marginTop: 1 }}
                        />
                      )}
                    />
                  </Grid>
                  {orderFile ? (
                    <Grid item xs={12}>
                      <Typography fontWeight="bold" gutterBottom>
                        {'Ordine caricato'}
                      </Typography>
                      <FileCard file={orderFile as unknown as File} variant="outlined" />
                    </Grid>
                  ) : null}
                  {orderConfirmationFile ? (
                    <Grid item xs={12}>
                      <Typography fontWeight="bold" gutterBottom>
                        {'Accettazione dell’ordine caricata'}
                      </Typography>
                      <FileCard file={orderConfirmationFile as unknown as File} variant="outlined" />
                    </Grid>
                  ) : null}
                </Grid>
                <Controller
                  control={control}
                  name="stakeholders"
                  render={({ field, fieldState: { error } }) => (
                    <Stack direction="column" gap={1} marginTop={2}>
                      <Typography variant="h6" color="text.primary">
                        {'Allega il documento a una o più parti coinvolte corrispondente/i'}
                      </Typography>
                      {error ? (
                        <Alert severity="error">
                          {"L'ordine caricato deve essere associato almeno ad una parte coinvolta"}
                        </Alert>
                      ) : null}
                      <Paper sx={{ height: 300, borderColor: error ? 'error.main' : undefined }}>
                        <DataGridPremium
                          density="compact"
                          rows={Object.values(stakeholders)}
                          columns={dataGridColumns}
                          disableRowGrouping
                          disableAggregation
                          checkboxSelection
                          hideFooter
                          rowSelectionModel={field.value ?? []}
                          onRowSelectionModelChange={(rowSelectionModel) => {
                            field.onChange(rowSelectionModel);
                            discardAttachmentOperations();
                            if (uploadedOrder && uploadedOrderConfirmation) {
                              createPaperworkAttachment(
                                addDraftIdToAttachment({
                                  attachment: uploadedOrder,
                                  savedInContextId: section.subsections?.[OrderSubsections.Order].context?.id ?? -1,
                                  stakeholderIdList: rowSelectionModel.map((id) => Number(id)),
                                })
                              );
                              createPaperworkAttachment(
                                addDraftIdToAttachment({
                                  attachment: uploadedOrderConfirmation,
                                  savedInContextId:
                                    section.subsections?.[OrderSubsections.OrderApproval].context?.id ?? -1,
                                  stakeholderIdList: rowSelectionModel.map((id) => Number(id)),
                                })
                              );
                            }
                          }}
                          sx={{ border: 0 }}
                        />
                      </Paper>
                    </Stack>
                  )}
                />
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {'Annulla'}
          </Button>
          {step === 0 ? (
            <Button
              type="button"
              disabled={!uploadedOrder || !uploadedOrderConfirmation}
              onClick={(e) => {
                e.preventDefault();
                setStep(1);
              }}
            >
              {'Avanti'}
            </Button>
          ) : (
            <Button type="submit">{'Aggiungi'}</Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const AddOrderDialog = React.memo(AddOrderDialogComponent);
