import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { PaperworkContext, PaperworkRoute, PaperworkRouteConfiguration } from '../../entities/Paperwork';
import { paperworkSection } from '../../pages/Paperwork/sections';

import { useReadPaperworkValuesQuery } from '../../services/paperwork';
import { PaperworksNavigationStructure, PaperworkPhases } from './paperworkNavigation';
import { paperworkRoutesConfiguration } from './paperworkRouteConfiguration';
import { usePaperworkRouteExceptions } from './paperworkRouteExceptions';
import { usePaperwork } from './usePaperwork';

/**
 * Find the matching PaperworkContext given an array of PaperworkContext.
 * Its generic ancestor contexts will match.
 */
export function findContext(
  contexts: PaperworkContext[] | undefined,
  phase: string,
  page?: string,
  section?: string,
  subsection?: string
): PaperworkContext | undefined {
  return contexts?.find(
    (context) =>
      context.phase === phase &&
      (page === undefined || context.page === page) &&
      (section === undefined || context.section === section) &&
      (subsection === undefined || context.subsection === subsection)
  );
}

/**
 * Find the exact matching PaperworkContext given an array of PaperworkContext.
 */
export function findStrictContext(
  contexts: PaperworkContext[] | undefined,
  phase: string,
  page?: string,
  section?: string,
  subsection?: string
): PaperworkContext | undefined {
  return contexts?.find(
    (context) =>
      context.phase === phase &&
      context.page === page &&
      context.section === section &&
      context.subsection === subsection
  );
}

export function usePaperworkNavigation() {
  const { pathname } = useLocation();
  const { paperwork, contexts } = usePaperwork();
  const { data: paperworkValues } = useReadPaperworkValuesQuery(
    { paperworkId: paperwork?.id ?? -1 },
    { skip: !paperwork }
  );
  const { applyPaperworkRouteExceptions } = usePaperworkRouteExceptions();

  // The path of the Paperwork Details page
  const detailsPath = useMemo(() => [`${paperworkSection.path}`, paperwork?.id].join('/'), [paperwork?.id]);

  const routes = useMemo(() => {
    // The NavigationContext of the specific route the browser is currently on
    let currentNavigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'> = {
      phase: PaperworkPhases.Configuration,
    };

    let currentRoute: PaperworkRoute = {
      path: '',
      absolutePath: '',
      title: '',
      matcher: '',
      absoluteMatcher: '',
      active: false,
      enabled: false,
    };

    const routeByContextId = new Map<number, PaperworkRoute>();

    /**
     * Semi-magic function that takes a list of paperworkRoutesConfiguration branches and generates a
     * PaperworkRoute object out of it.
     * The function is called recursively on the route sub-routes (i.e. for a page, is recursively called on
     * its sections)
     * @param routeConfiguration a paperworkRoutesConfiguration branch object
     * @param depth the recursion depth level
     * @param parentPath the parent route path
     * @param parentNavigationContext the parent route navigation context
     * @returns a PaperworkRoute object.
     */
    const generateRoute = (
      routeConfiguration: Record<string, PaperworkRouteConfiguration>,
      depth: number,
      parentPath: string[],
      parentNavigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'>
    ) => {
      const route: Record<string, PaperworkRoute> = {};

      // Iterate all configurations (i.e. all the pages inside a phase)
      for (const [key, value] of Object.entries(routeConfiguration)) {
        const absolutePath = [...parentPath, key].join('/');
        const matcher = `${key}/*`;
        const absoluteMatcher = `${paperworkSection.path}/:id/${absolutePath}/*`;
        const title = paperwork ? value.title(paperwork) : '';

        // Find out if the name of this route is actually a phase, a page, etc...
        // and merge that info with the parent context — in this way the context
        // is recursively built
        const navigationContext = { ...parentNavigationContext };
        if (depth === 0) {
          navigationContext.phase = key;
        }
        if (depth === 1) {
          navigationContext.page = key;
        }
        if (depth === 2) {
          navigationContext.section = key;
        }
        if (depth === 3) {
          navigationContext.subsection = key;
        }
        const paperworkRoute: PaperworkRoute = {
          ...value,
          path: key,
          absolutePath,
          matcher,
          absoluteMatcher,
          active: false,
          enabled: false,
          title,
          icon: value.icon,
        };

        // Check if this route is matched by the current browser path (the user is *now* on this route)
        if (matchPath(absoluteMatcher, pathname)) {
          currentNavigationContext = { ...currentNavigationContext, ...navigationContext };
          currentRoute = paperworkRoute;
        }

        // Generate child routes
        // Sadly, I couldn't find a way to nicely have value strongly typed
        if ('pages' in value) {
          paperworkRoute['pages'] = generateRoute(
            value.pages as Record<string, PaperworkRouteConfiguration>,
            depth + 1,
            [...parentPath, key],
            navigationContext
          );
        }
        if ('sections' in value) {
          paperworkRoute['sections'] = generateRoute(
            value.sections as Record<string, PaperworkRouteConfiguration>,
            depth + 1,
            [...parentPath, key],
            navigationContext
          );
        }
        if ('subsections' in value) {
          paperworkRoute['subsections'] = generateRoute(
            value.subsections as Record<string, PaperworkRouteConfiguration>,
            depth + 1,
            [...parentPath, key],
            navigationContext
          );
        }

        // Find the PaperworkContext matching the navigation context
        const context =
          findStrictContext(
            contexts,
            navigationContext.phase,
            navigationContext.page,
            navigationContext.section,
            navigationContext.subsection
          ) ??
          findContext(
            contexts,
            navigationContext.phase,
            navigationContext.page,
            navigationContext.section,
            navigationContext.subsection
          );
        paperworkRoute.context = context;

        if (paperwork && context) {
          paperworkRoute.active = Boolean(context);
          paperworkRoute.enabled = paperworkRoute.active && paperwork?.status.id >= context?.availableFromStatus.id;
        }

        route[key] = paperworkRoute;

        if (context) {
          routeByContextId.set(context.id, paperworkRoute);
        }
      }

      return route;
    };

    const phasesRoutes = generateRoute(paperworkRoutesConfiguration, 0, [], {
      phase: '',
    }) as PaperworksNavigationStructure<PaperworkRoute>;

    if (paperwork) {
      applyPaperworkRouteExceptions(phasesRoutes, paperwork, paperworkValues ?? []);
    }

    const paperworkNavigation = {
      /**
       * A PaperworksNavigationStructure-like object describing the routes of /paperwork
       */
      routes: phasesRoutes,
      /**
       * The NavigationContext related to the route the browser is currently on
       */
      navigationContext: currentNavigationContext,
      /**
       * The path of the Paperwork Details page
       */
      detailsPath,
      /**
       * The the route the browser is currently on
       */
      route: currentRoute as unknown as PaperworkRoute | undefined,
      /**
       * Get a route in exchange for a context ID
       */
      routeByContextId,
    };

    return paperworkNavigation;
  }, [applyPaperworkRouteExceptions, contexts, detailsPath, paperwork, paperworkValues, pathname]);

  return routes;
}
