import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
// import SerniListUpdateCreatePage from './SerniListUpdateCreatePage/SerniListUpdateCreatePage';
// import SerniListUpdateDetailsRouter from './SerniListUpdateDetailsRouter';
import SerniListUpdateCreatePage from './SerniListConfigurationPage/SerniListConfigurationPage';
import SerniListUpdateDetailsRouter from './SerniListUpdateDetailsRouter';
import SerniListUpdateList from './SerniListUpdateList';

export default function SerniListUpdateRouter(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Routes>
        <Route path="/" element={<SerniListUpdateList />} />
        <Route path=":id/*" element={<SerniListUpdateDetailsRouter />} />
        <Route path="create" element={<SerniListUpdateCreatePage />} />
      </Routes>
    </AuthGuard>
  );
}
