import { Eccn, EccnSchema } from '../entities/Eccn';

import { procurementToolApi, PT_TAG_TYPES } from './procurementToolApi';

const url = 'eccn';

const eccnApi = procurementToolApi.injectEndpoints({
  endpoints: (builder) => ({
    readEccnList: builder.query<Array<Eccn>, void>({
      query: () => ({ url }),
      transformResponse: (data) => EccnSchema.array().parse(data),
      providesTags: () => [{ type: PT_TAG_TYPES.ECCN, id: 'LIST' }],
    }),
  }),
});

export const { useReadEccnListQuery } = eccnApi;
