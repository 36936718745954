import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CountryFlag, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { EuFlagRound } from '../Icons/EuFlagRound';
import { NatoFlagRound } from '../Icons/NatoFlagRound';
import { InlineBadgeLabelProps } from './InlineBadgeLabel';

interface CountryInlineBadgeLabelProps extends Omit<InlineBadgeLabelProps, 'badgeColor'> {
  countryCode: string;
}

function CountryInlineBadgeLabelComponent(props: CountryInlineBadgeLabelProps) {
  const { countryCode, ...inlineBadgeLabelProps } = props;
  const countriesQuery = useReadCountriesQuery();

  const commonDataCountry = countriesQuery.data?.byISO[countryCode];

  if (!commonDataCountry) {
    return countryCode;
  }

  return (
    <Stack direction="row" alignItems="center" gap={'0.8ch'} {...inlineBadgeLabelProps}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          top: '0.125em',
          '& img': {
            height: '1.1em',
            borderRadius: '2px',
          },
        }}
      >
        <CountryFlag countryCode={countryCode} format="png" />
      </Box>
      {commonDataCountry.name || countryCode}
      <Box sx={{ flex: 1 }} />
      {commonDataCountry.isEU ? <EuFlagRound /> : null}
      {commonDataCountry.isNato ? <NatoFlagRound /> : null}
    </Stack>
  );
}

export const CountryInlineBadgeLabel = React.memo(CountryInlineBadgeLabelComponent);
