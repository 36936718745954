import { Currency, CurrencySchema } from '../pages/Currency/currency';
import { api, TAG_TYPES } from './baseApi';

const url = 'currencies';

const currencyAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCurrencyList: builder.query<Array<Currency>, void>({
      query: () => ({ url }),
      transformResponse: (data) => CurrencySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.CURRENCY, id: 'LIST' }],
    }),
    createCurrency: builder.mutation<{ id: number }, Currency>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.CURRENCY, id: 'LIST' }],
    }),
    updateCurrency: builder.mutation<void, Currency>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.CURRENCY, id: 'LIST' }],
    }),
    deleteCurrency: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({ url: `${url}/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.CURRENCY, id: 'LIST' }],
    }),
  }),
});

export const {
  useReadCurrencyListQuery,
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation,
} = currencyAPi;
