import { useMemo } from 'react';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useReadEquipmentListQuery } from '../../services/equipmentiApi';

export function useSerniCodeColumn() {
  const { data } = useReadEquipmentListQuery();

  return useMemo(() => {
    const serniCodeColumn: GridColDef<GridValidRowModel, string, string> = {
      headerName: 'Equipaggiamento',
      field: 'serniCode',
      minWidth: 250,
      flex: 1,
      renderCell: ({ value }) => {
        if (value) {
          const equipment = data?.bySerniCode[value];
          if (equipment) {
            return `[${value}] ${equipment.description}`;
          }
        }

        return value ?? '—';
      },
    };

    return serniCodeColumn;
  }, [data?.bySerniCode]);
}
