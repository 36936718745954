import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { PaperworkRoute } from '../../../../entities/Paperwork';
import { useAttachments } from '../../../../hooks/useAttachments';

interface SectionListItemProps {
  section: PaperworkRoute;
}

export function SectionListItem(props: SectionListItemProps) {
  const { section } = props;
  const { attachments } = useAttachments();

  const attachmentsCount = useMemo(() => {
    const attachmentFiles = new Set();
    for (const attachment of Object.values(attachments)) {
      if (attachment.savedInContextId === section.context?.id) {
        attachmentFiles.add(attachment.attachment.id);
      }
    }
    return attachmentFiles.size;
  }, [attachments, section.context?.id]);

  return (
    <ListItem
      key={section.path}
      disablePadding
      secondaryAction={<Badge badgeContent={attachmentsCount} color="primary" sx={{ marginRight: 1 }} />}
    >
      <ListItemButton component={Link} to={`./${section.path}`}>
        <ListItemText primary={section.title} />
      </ListItemButton>
    </ListItem>
  );
}
