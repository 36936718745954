import { z } from 'zod';

export const ReviewSchema = z.object({
  author: z.string(),
  comment: z.string().optional(),
  isApproved: z.boolean(),
  updatedAt: z.string().datetime(),
});

export type Review = z.infer<typeof ReviewSchema>;
