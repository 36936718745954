import React from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../entities/User';
import { useReadCurrencyListQuery } from '../../services/currency';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import CurrencyListGrid from './CurrencyListGrid';
import { currencySection } from './sections';

const PAGE_TITLE = currencySection.title;

export default function CurrencyList(): React.ReactElement {
  const { isLoading, data, error } = useReadCurrencyListQuery();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout title={PAGE_TITLE} maxWidth="lg" header={<PageTitle pageTitle={PAGE_TITLE} />} error={error}>
        <DataGridWrapper>
          <CurrencyListGrid currencies={data || []} loading={isLoading} />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
