import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

const paymentTerms = [30, 60, 90, 120].map((days) => `${days} giorni`);

function ContractualTermsPaymentTermsAutocompleteComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  return (
    <Autocomplete
      disablePortal
      options={paymentTerms}
      renderInput={(params) => <TextField {...params} label={field.label} />}
      value={fieldValue?.value}
      onChange={(event, value) => {
        return updateValue(value ?? undefined);
      }}
    />
  );
}

export const ContractualTermsPaymentTermsAutocomplete = React.memo(ContractualTermsPaymentTermsAutocompleteComponent);
