import {
  AttachmentCreateResponse,
  AttachmentCreateResponseSchema,
  PatchAttachmentListRequest,
} from '../entities/Attachment';
import { TAG_TYPES, api } from './baseApi';

const url = '/attachments';

export const attachmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAttachment: builder.mutation<AttachmentCreateResponse, { file: File }>({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (data) => AttachmentCreateResponseSchema.parse(data),
    }),
    patchAttachmentList: builder.mutation<{ id: number }, PatchAttachmentListRequest>({
      query: ({ paperworkId, ...body }) => ({ url: `/paperworks/${paperworkId}/attachments`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [{ type: TAG_TYPES.PAPERWORK, id: req.paperworkId }],
    }),
  }),
});

export const { useCreateAttachmentMutation, usePatchAttachmentListMutation } = attachmentApi;
