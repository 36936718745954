import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOperationEnum } from '../entities/Drafts';
import { Paperwork, PaperworkFieldValueDraft, getPaperworkFieldValueDraftId } from '../entities/Paperwork';
import { RootState } from './store';

export type PaperworkFieldValuesStateSlice = {
  paperworkId: Paperwork['id'] | undefined;
  fieldValues: Record<string, PaperworkFieldValueDraft>;
  originalFieldValues: Record<string, PaperworkFieldValueDraft>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: PaperworkFieldValuesStateSlice = {
  paperworkId: undefined,
  fieldValues: {},
  originalFieldValues: {},
  operations: {},
  saving: false,
};

const paperworkFieldValuesStateSlice = createSlice({
  name: 'paperworkFieldValuesState',
  initialState,
  reducers: {
    updateValue: (
      state,
      action: PayloadAction<{
        fieldValueDraft: PaperworkFieldValueDraft;
      }>
    ) => {
      const fieldValueDraft = action.payload.fieldValueDraft;
      const draftId = fieldValueDraft.draftId;

      if (!state.fieldValues[draftId]) {
        state.operations[draftId] = DraftOperationEnum.Values.Create;
      } else if (state.operations[draftId] !== DraftOperationEnum.Values.Create) {
        state.operations[draftId] = DraftOperationEnum.Values.Update;
      }

      state.fieldValues[draftId] = fieldValueDraft;
    },
    deleteValue: (
      state,
      action: PayloadAction<{
        draftId: PaperworkFieldValueDraft['draftId'];
        id: PaperworkFieldValueDraft['id'];
      }>
    ) => {
      const draftId = action.payload.draftId;
      const id = action.payload.id;

      if (id === undefined) {
        // The field value is not on DB: just remove it from the store
        delete state.fieldValues[draftId];
        delete state.operations[draftId];
      } else {
        // The field value had an ID and was on DB: mark it for deletion
        state.operations[draftId] = DraftOperationEnum.Values.Delete;
      }
    },
    setSavingState: (state, action: PayloadAction<{ saving: boolean; updatedPaperwork?: Paperwork }>) => {
      state.saving = action.payload.saving;
    },
    replaceFieldValues: (
      state,
      action: PayloadAction<{
        paperworkId: Paperwork['id'];
        fieldValues: Omit<PaperworkFieldValueDraft, 'draftId'>[];
      }>
    ) => {
      state.paperworkId = action.payload.paperworkId;
      state.originalFieldValues = {};
      state.fieldValues = {};
      for (const value of action.payload.fieldValues) {
        const draftId = getPaperworkFieldValueDraftId(value);
        state.fieldValues[draftId] = { ...value, draftId };
        state.originalFieldValues[draftId] = state.fieldValues[draftId];
      }
      state.operations = {};
    },
    discardOperations: (state) => {
      state.fieldValues = state.originalFieldValues;
      state.operations = {};
    },
  },
});

export const { updateValue, deleteValue, setSavingState, replaceFieldValues, discardOperations } =
  paperworkFieldValuesStateSlice.actions;

export const selectPaperworkFieldValuesState = (state: RootState) => state.paperworkFieldValuesState;

export default paperworkFieldValuesStateSlice.reducer;
