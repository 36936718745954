import React, { useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { IncompleteRowsDatagrid } from '../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { stakeholderNameColumn } from '../../../../../components/DataGrid/stakeholderAddressColumn';
import { stakeholderAddressColumn } from '../../../../../components/DataGrid/stakeholderNameColumn';
import { useCountryColumn } from '../../../../../components/DataGrid/useCountryColumn';
import { StakeholderDraft } from '../../../../../entities/Stakeholder';
import { ImportMaterialsFooter } from './ImportStakeholdersFooter';
import { ImportStakeholdersNoRows } from './ImportStakeholdersNoRows';

interface FilterTabProps {
  searchResults: StakeholderDraft[];
  manualStakeholders: StakeholderDraft[];
  onNext: (stakeholders: StakeholderDraft[]) => void;
  onCancel: () => void;
}

const gridInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 50,
    },
  },
};

function FilterTabComponent(props: FilterTabProps) {
  const { searchResults, manualStakeholders, onCancel, onNext } = props;

  const countryColumn = useCountryColumn();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const dataGridColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'ptId',
        type: 'string',
        headerName: 'ID on SC Compliance & End-Use',
        minWidth: 180,
        flex: 0,
      },
      stakeholderNameColumn,
      stakeholderAddressColumn,
      {
        ...countryColumn,
        field: 'country',
        minWidth: 120,
      },
    ],
    [countryColumn]
  );

  const searchResultsByDraftId = useMemo(() => {
    const byId: Record<string, StakeholderDraft> = {};

    if (searchResults.length) {
      for (const material of searchResults) {
        byId[material.draftId] = material;
      }
    } else {
      for (const manualStakeholder of manualStakeholders) {
        byId[manualStakeholder.draftId] = manualStakeholder;
      }
    }
    return byId;
  }, [manualStakeholders, searchResults]);

  const rows: Array<StakeholderDraft | Pick<StakeholderDraft, 'draftId' | 'name'>> = useMemo(() => {
    if (searchResults.length) {
      return searchResults;
    }

    return manualStakeholders;
  }, [manualStakeholders, searchResults]);

  const incompleteManualRows = useMemo(() => rows.find((row) => !('address' in row) || row.address === ''), [rows]);

  return (
    <Stack direction="column" flex="1 1 0" minHeight="100%">
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1, minHeight: 0 }}>
        {incompleteManualRows ? (
          <Alert severity="warning">
            {
              'I materiali contrassegnati in giallo non sono state importati e i dati mancanti dovranno essere integrati manualemente.'
            }
          </Alert>
        ) : rows.length > 2 ? (
          <Alert severity="info">
            {'I risultati possono essere ulteriormente raffinati applicando i filtri della tabella.'}
          </Alert>
        ) : null}
        <Paper variant="outlined" sx={{ flex: 1, minHeight: 0 }}>
          <IncompleteRowsDatagrid
            density="compact"
            rows={rows}
            columns={dataGridColumns}
            disableRowGrouping
            disableAggregation
            disableDensitySelector
            disableColumnSelector
            disableColumnPinning
            hideFooter
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            initialState={gridInitialState}
            rowSelectionModel={rowSelectionModel}
            getRowId={(stakeholder) => stakeholder.draftId}
            slots={{
              footer: ImportMaterialsFooter,
              noRowsOverlay: () => <ImportStakeholdersNoRows />,
            }}
            getRowClassName={(params) => (!params.row.address || params.row.address === '' ? 'incomplete' : '')}
            sx={{ border: 0 }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {'Annulla'}
        </Button>
        <Button
          onClick={() => {
            onNext(rowSelectionModel.map((draftId) => searchResultsByDraftId[draftId]));
          }}
          disabled={rowSelectionModel.length === 0}
        >
          {rowSelectionModel.length > 0 ? `Aggiungi ${rowSelectionModel.length} parti coinvolte` : 'Aggiungi'}
        </Button>
      </DialogActions>
    </Stack>
  );
}

export const FilterTab = React.memo(FilterTabComponent);
