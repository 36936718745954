import React from 'react';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText, { ListItemTextProps, listItemTextClasses } from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

const CompactListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  [`.${listItemTextClasses.primary}`]: {
    ...theme.typography.body1,
  },
  [`.${listItemTextClasses.secondary}`]: {
    color: theme.palette.text.disabled,
    typography: 'caption',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function CompactListItemComponent(props: ListItemTextProps & { listItemProps: ListItemProps }) {
  const { listItemProps, ...listItemTextProps } = props;
  return (
    <ListItem dense {...listItemProps}>
      <CompactListItemText {...listItemTextProps} />
    </ListItem>
  );
}

export const CompactListItem = React.memo(CompactListItemComponent);
