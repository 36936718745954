import React, { useMemo } from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useReadAppUserListQuery } from '@top-solution/microtecnica-utils';
import { PaperworkReview } from '../../entities/Paperwork';
import { formatUsername } from '../../utils/users';

interface ReviewChipProps extends ChipProps {
  review: PaperworkReview | undefined;
}

function ReviewChipComponent(props: ReviewChipProps) {
  const { review, ...chipProps } = props;
  const appUserListQuery = useReadAppUserListQuery(import.meta.env.VITE_APP_ID);

  const author = useMemo(
    () => review?.review.author && appUserListQuery.data?.find((user) => user.username === review?.review.author),
    [appUserListQuery.data, review?.review.author]
  );

  if (!review) {
    return <Chip label="Non revisionato" color="default" variant="outlined" size="small" {...chipProps} />;
  }

  return review?.review.isApproved === false ? (
    <Tooltip
      title={
        <Stack>
          <Typography
            variant="caption"
            fontWeight={700}
          >{`${author ? formatUsername(author) : review?.review.author} ha commentato: `}</Typography>
          <Typography variant="caption">{review.review.comment}</Typography>
        </Stack>
      }
    >
      <Chip label="Dato rifiutato" color="error" variant="outlined" size="small" {...chipProps} />
    </Tooltip>
  ) : (
    <Chip label="Dato approvato" color="success" variant="outlined" size="small" {...chipProps} />
  );
}

export const ReviewChip = React.memo(ReviewChipComponent);
