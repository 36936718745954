import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOperationEnum } from '../entities/Drafts';
import { Paperwork } from '../entities/Paperwork';
import { StakeholderDraft, getStakeholderDraftId } from '../entities/Stakeholder';
import { RootState } from './store';

export type StakeholdersStateSlice = {
  paperworkId: Paperwork['id'] | undefined;
  stakeholders: Record<string, StakeholderDraft>;
  originalStakeholders: Record<string, StakeholderDraft>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: StakeholdersStateSlice = {
  paperworkId: undefined,
  stakeholders: {},
  originalStakeholders: {},
  operations: {},
  saving: false,
};

const stakeholdersSlice = createSlice({
  name: 'stakeholdersState',
  initialState,
  reducers: {
    createStakeholder: (
      state,
      action: PayloadAction<{
        stakeholderDraft: StakeholderDraft;
      }>
    ) => {
      const stakeholderDraft = action.payload.stakeholderDraft;
      const draftId = stakeholderDraft.draftId;

      if (stakeholderDraft.id) {
        return;
      }

      state.operations[draftId] = DraftOperationEnum.Values.Create;
      state.stakeholders[draftId] = stakeholderDraft;
    },
    updateStakeholder: (
      state,
      action: PayloadAction<{
        currentDraftId: string | undefined;
        stakeholderDraft: StakeholderDraft;
      }>
    ) => {
      const currentDraftId = action.payload.currentDraftId;
      const stakeholderDraft = action.payload.stakeholderDraft;

      if (!currentDraftId) {
        return;
      }

      const newDraftId = getStakeholderDraftId(action.payload.stakeholderDraft);

      if (state.operations[currentDraftId] !== DraftOperationEnum.Values.Create) {
        state.operations[currentDraftId] = DraftOperationEnum.Values.Update;
      }

      state.operations[newDraftId] = state.operations[currentDraftId];
      state.stakeholders[newDraftId] = stakeholderDraft;

      delete state.operations[currentDraftId];
      delete state.stakeholders[currentDraftId];
    },
    deleteStakeholder: (
      state,
      action: PayloadAction<{
        draftId: StakeholderDraft['draftId'];
        id: StakeholderDraft['id'];
      }>
    ) => {
      const draftId = action.payload.draftId;
      const id = action.payload.draftId;

      if (id === undefined) {
        // The stakeholder is not on DB: just remove it from the store
        delete state.stakeholders[draftId];
        delete state.operations[draftId];
      } else {
        // The stakeholder had an ID and was on DB: mark it for deletion
        state.operations[draftId] = DraftOperationEnum.Values.Delete;
      }
    },
    setSavingState: (state, action: PayloadAction<{ saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    replaceStakeholders: (
      state,
      action: PayloadAction<{
        stakeholders: Omit<StakeholderDraft, 'draftId'>[];
        paperworkId: Paperwork['id'];
      }>
    ) => {
      state.originalStakeholders = {};
      state.stakeholders = {};
      for (const value of action.payload.stakeholders) {
        const draftId = getStakeholderDraftId(value);
        state.stakeholders[draftId] = { ...value, draftId };
        state.originalStakeholders[draftId] = { ...value, draftId };
      }
      state.operations = {};
      state.paperworkId = action.payload.paperworkId;
    },
    discardOperations: (state) => {
      state.stakeholders = state.originalStakeholders;
      state.operations = {};
    },
  },
});

export const {
  createStakeholder,
  updateStakeholder,
  deleteStakeholder,
  setSavingState,
  replaceStakeholders,
  discardOperations,
} = stakeholdersSlice.actions;

export const selectStakeholdersState = (state: RootState) => state.stakeholdersState;

export default stakeholdersSlice.reducer;
