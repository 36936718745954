import { useMemo } from 'react';
import { GridColDef, GridSingleSelectColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { useReadCountryListQuery } from '../../services/countries';

export function useCountryColumn<T extends GridValidRowModel>(): GridSingleSelectColDef<T> {
  const { data: countries } = useReadCountriesQuery();
  const { data: availableCountries } = useReadCountryListQuery();

  const mtColDef = useCountryGridColDef();

  const filteredCountries = useMemo(
    () => countries?.list.filter((c) => availableCountries?.find((availableCountry) => availableCountry.code === c.id)),
    [availableCountries, countries?.list]
  );

  const valueOptions = useMemo(
    () => filteredCountries?.map((v) => ({ value: v.id, label: v.name })),
    [filteredCountries]
  );
  return useMemo(() => {
    const colDef: GridColDef<T, string> = {
      ...mtColDef,
      field: 'country',
      headerName: 'Paese',
      valueOptions,
      minWidth: 160,
      maxWidth: 400,
      flex: 1,
      renderCell: (...params) => (params[0].value === '' ? null : mtColDef.renderCell?.(...params)),
    };
    return colDef;
  }, [mtColDef, valueOptions]);
}
