import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { closeNotification, removeNotification, selectNotifications } from '../store/notificationsSlice';

function AppNotificationsComponent() {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  return (
    <Box sx={{ position: 'fixed', left: 0, bottom: 0, zIndex: 10000 }}>
      {notifications.map((notification, i) => {
        return (
          <Box
            key={notification.id}
            sx={{
              transform: (theme) => `translateY(-${theme.spacing(8 * i)})`,
              transition: (theme) => theme.transitions.create('transform'),
            }}
          >
            <Snackbar
              open={!notification.closing}
              message={notification.message}
              onClick={() => dispatch(closeNotification({ id: notification.id }))}
              TransitionProps={{ onExited: () => dispatch(removeNotification({ index: i })) }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export const AppNotifications = React.memo(AppNotificationsComponent);
