import { z } from 'zod';

export enum STAKEHOLDER_ROLE {
  SHIP_TO = 'Ship To',
  BILL_TO = 'Bill To',
  ORDER_FROM = 'Order From',
  END_USERS = 'End Users',
}

export const StakeholderSchema = z.object({
  id: z.number(),
  ptId: z.string().optional(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
  role: z.nativeEnum(STAKEHOLDER_ROLE).optional(),
  imported: z.boolean().optional(),
  importReason: z.string().min(1).optional(),
});

export const StakeholderDraftSchema = StakeholderSchema.omit({ id: true }).extend({
  id: StakeholderSchema.shape.id.optional(),
  draftId: z.string(),
});

const PatchStakeholderListRequestSchema = z.object({
  paperworkId: z.number(),
  create: StakeholderSchema.omit({ id: true }).array(),
  update: StakeholderSchema.array(),
  delete: StakeholderSchema.pick({ id: true }).array(),
});

export const PTVendorSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
});

export const PTCustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
});

export const PTEndUserSchema = z.object({
  id: z.number(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
});

export type Stakeholder = z.infer<typeof StakeholderSchema>;
export type StakeholderDraft = z.infer<typeof StakeholderDraftSchema>;
export type PatchStakeholderListRequest = z.infer<typeof PatchStakeholderListRequestSchema>;
export type PTVendor = z.infer<typeof PTVendorSchema>;
export type PTCustomer = z.infer<typeof PTCustomerSchema>;
export type PTEndUser = z.infer<typeof PTEndUserSchema>;

export function getStakeholderDraftId(stakeholder: Partial<Stakeholder>) {
  const { ptId, name, country, address, role } = stakeholder;

  return ['draft', ptId, name, country, address, role]
    .filter(Boolean)
    .map((s) => `${s}`.toLocaleLowerCase().replace(/[\W_]+/g, '-'))
    .join('---');
}

export function addDraftIdToStakeholder(
  fieldValue: Omit<StakeholderDraft, 'draftId'> & { draftId?: StakeholderDraft['draftId'] | undefined }
): StakeholderDraft {
  return {
    ...fieldValue,
    draftId: fieldValue.draftId ?? getStakeholderDraftId(fieldValue),
  };
}
