import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../../../entities/Stakeholder';
import { StakeholdersSection } from './StakeholdersSection';
import { StakeholdersSkipInputSectionSection } from './StakeholdersSkipInputSection';
import { StakeholdersStepProps } from './StakeholdersStepLayout';

export function ShipToStep(props: StakeholdersStepProps) {
  return <StakeholdersSection {...props} role={STAKEHOLDER_ROLE.SHIP_TO} />;
}

export function OrderFromStep(props: StakeholdersStepProps) {
  return <StakeholdersSection {...props} role={STAKEHOLDER_ROLE.ORDER_FROM} />;
}

export function BuyerStep(props: StakeholdersStepProps) {
  return <StakeholdersSection {...props} role={STAKEHOLDER_ROLE.ORDER_FROM} />;
}

export function BillToStep(props: StakeholdersStepProps) {
  // <StakeholdersSkipInputSectionSection
  //   {...props}
  //   role={STAKEHOLDER_ROLE.BILL_TO}
  //   optionFieldDatatype={PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_BILL_TO_MATCHES_SHIP_TO_OR_ORDER_FROM}
  // />
  return <StakeholdersSection {...props} role={STAKEHOLDER_ROLE.BILL_TO} />;
}

export function EndUsersStep(props: StakeholdersStepProps) {
  return (
    <StakeholdersSkipInputSectionSection
      {...props}
      role={STAKEHOLDER_ROLE.END_USERS}
      optionFieldDatatype={PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO}
    />
  );
}
