import React from 'react';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Logo } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import { useSectionList } from '../../hooks';

function HomePageComponent() {
  const { userSectionList, adminSectionList } = useSectionList();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth="sm">
        <Stack direction="row" justifyContent="center" alignItems="center" gap={3} marginBottom={5}>
          <Logo height={42} />
          <Typography variant="h5" textAlign="center" fontWeight="bold">
            {'ILCM - Italian Law Compliance Manager'}
          </Typography>
        </Stack>
        <Paper>
          <List>
            {userSectionList.map((section) => (
              <ListItem disableGutters key={section.path}>
                <ListItemButton component={Link} to={section.path}>
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText primary={section.title} />
                </ListItemButton>
              </ListItem>
            ))}
            {adminSectionList.length > 0 && (
              <>
                <Divider>Amministrazione</Divider>
                {adminSectionList.map((section) => (
                  <ListItem disableGutters key={section.path}>
                    <ListItemButton component={Link} to={section.path}>
                      <ListItemIcon>{section.icon}</ListItemIcon>
                      <ListItemText primary={section.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Paper>
      </Layout>
    </AuthGuard>
  );
}

export default React.memo(HomePageComponent);
