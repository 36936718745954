import { Fragment, useMemo } from 'react';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Layout } from '../../../../components/Layout';
import { BackButton, ForwardButton, NavigationButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../../components/PageTitle';
import { PaperworkDebugMenu } from '../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { useAttachments } from '../../../../hooks/useAttachments';
import {
  DataEntryPages,
  AttachmentsSections,
  PaperworkPhases,
} from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkTemplatesListQuery } from '../../../../services/paperwork';
import { PaperworkInfoPanel } from '../../PaperworkInfoPanel';
import { AttachmentsSectionPage } from './AttachmentsSectionPage';
import { OrdersSectionPage } from './OrdersSectionPage';
import { SectionListItem } from './SectionListItem';
import { useFilteredStakeholders } from './useFilteredStakeholders';

export default function PaperworkAttachmentsPage() {
  const navigate = useNavigate();
  const { isSuccess: useReadPaperworkTemplatesListIsSuccess } = useReadPaperworkTemplatesListQuery();
  const { detailsPath, routes } = usePaperworkNavigation();

  const { operationsCount } = usePaperworkFields();

  const route = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments];
  const sections = useMemo(
    () =>
      Object.values(route?.sections ?? {}).filter((section) => {
        return (
          section.enabled &&
          (!section.subsections || Object.values(section.subsections).find((subSection) => subSection.enabled === true))
        );
      }),
    [route?.sections]
  );

  const { attachments, operationsCount: attachmentsOperationsCount } = useAttachments();
  const { filteredStakeholders, hasMilitaryBases, onlyMilitaryBases } = useFilteredStakeholders();

  const forwardDisabled =
    operationsCount > 0 || (Object.values(filteredStakeholders).length > 0 && Object.keys(attachments).length < 1);

  const leftActions = (
    <>
      <NavigationButton component={Link} to={detailsPath}>
        {`Torna alla dashboard pratica`}
      </NavigationButton>
    </>
  );

  return (
    <Layout
      title={route?.title ?? ''}
      maxWidth={false}
      header={<PageTitle pageTitle={route?.title ?? ''} rightItems={<PaperworkInfoPanel />} />}
      footer={
        <NavigationPanel
          primaryActions={
            <>
              <BackButton
                onClick={() =>
                  navigate(`../${routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].path}`)
                }
                sx={{ mr: 1 }}
              />
              <ForwardButton onClick={() => navigate(detailsPath)} disabled={forwardDisabled} />
            </>
          }
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={attachmentsOperationsCount + operationsCount}
        />
      }
    >
      <Fragment>
        <Stack direction="column" alignItems="stretch" height="100%">
          {useReadPaperworkTemplatesListIsSuccess ? (
            <Stack
              direction="row"
              flex={1}
              gap={1}
              paddingBottom={1}
              paddingX={1}
              minHeight={0}
              minWidth={0}
              overflow="hidden"
            >
              {sections.length > 1 ? (
                <Stack component={Paper} direction="column" flex={'0 0 220px'}>
                  <List>
                    {sections.map((section) => (
                      <SectionListItem key={section.path} section={section} />
                    ))}
                  </List>
                </Stack>
              ) : null}
              <Stack flex={1} minWidth={0}>
                <Routes>
                  <Route path={route.sections[AttachmentsSections.Order].matcher} element={<OrdersSectionPage />} />

                  <Route
                    path={route.sections[AttachmentsSections.Eus].matcher}
                    element={
                      <AttachmentsSectionPage
                        filteredStakeholders={filteredStakeholders}
                        hasMilitaryBases={hasMilitaryBases}
                        onlyMilitaryBases={onlyMilitaryBases}
                      />
                    }
                  />
                  <Route
                    path={route.sections[AttachmentsSections.Framework].matcher}
                    element={
                      <AttachmentsSectionPage
                        filteredStakeholders={filteredStakeholders}
                        hasMilitaryBases={hasMilitaryBases}
                        onlyMilitaryBases={onlyMilitaryBases}
                      />
                    }
                  />
                  <Route
                    path={route.sections[AttachmentsSections.Nda].matcher}
                    element={
                      <AttachmentsSectionPage
                        filteredStakeholders={filteredStakeholders}
                        hasMilitaryBases={hasMilitaryBases}
                        onlyMilitaryBases={onlyMilitaryBases}
                      />
                    }
                  />
                  <Route
                    path={route.sections[AttachmentsSections.VisuraCamerale].matcher}
                    element={
                      <AttachmentsSectionPage
                        filteredStakeholders={filteredStakeholders}
                        hasMilitaryBases={hasMilitaryBases}
                        onlyMilitaryBases={onlyMilitaryBases}
                      />
                    }
                  />
                  <Route
                    path={route.sections[AttachmentsSections.OtherDocuments].matcher}
                    element={
                      <AttachmentsSectionPage
                        filteredStakeholders={filteredStakeholders}
                        hasMilitaryBases={hasMilitaryBases}
                        onlyMilitaryBases={onlyMilitaryBases}
                      />
                    }
                  />
                  <Route path="*" element={sections[0] ? <Navigate to={sections[0].path} /> : null} />
                </Routes>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center" flex={1}>
              <CircularProgress />
            </Stack>
          )}
        </Stack>
      </Fragment>
    </Layout>
  );
}
