import { Navigate, Route, Routes } from 'react-router-dom';
import { DataEntryPages, SerniListUpdatePhases } from '../../hooks/useSerniListUpdate/serniListUpdateNavigation';
import { useSerniListUpdateNavigation } from '../../hooks/useSerniListUpdate/useSerniListUpdateNavigation';
import SerniListUpdateDataEntryPagePage from './DataEntry/SerniListUpdateDataEntryPage';
import SerniListUpdateCreatePage from './SerniListConfigurationPage/SerniListConfigurationPage';
import { SerniListUpdateDetailPage } from './SerniListUpdateDetail/SerniListUpdateDetailPage';

export default function SerniListUpdateDetailsRouter(): JSX.Element | null {
  const { routes } = useSerniListUpdateNavigation();

  const dataEntryPhasePages = routes[SerniListUpdatePhases.DataEntry].pages;

  return (
    <Routes>
      <Route index element={<SerniListUpdateDetailPage />} />
      <Route path={routes[SerniListUpdatePhases.Configuration].matcher} element={<SerniListUpdateCreatePage />} />
      <Route path={routes[SerniListUpdatePhases.DataEntry].matcher}>
        <Route
          path={dataEntryPhasePages[DataEntryPages.DataEntry].matcher}
          element={<SerniListUpdateDataEntryPagePage />}
        />
      </Route>
      {/* <Route path="debug" element={<SerniListUpdateDebug />} /> */}
      <Route path="*" element={<Navigate to={'..'} />} />
    </Routes>
  );
}
