import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { FileDropzone } from '../../../../components/FileDropzone';
import { Attachment, addDraftIdToAttachment } from '../../../../entities/Attachment';
import {
  PAPERWORK_FIELD_DATATYPE,
  PaperworkRoute,
  addDraftIdToPaperworkFieldValue,
} from '../../../../entities/Paperwork';
import { Stakeholder, StakeholderDraft, StakeholderSchema } from '../../../../entities/Stakeholder';
import { useAttachments } from '../../../../hooks/useAttachments';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useCreateAttachmentMutation } from '../../../../services/attachment';
import { useAddAttachmentDialogColumns } from './useAddAttachmentDialogColumns';

interface AddAttachmentDialogProps {
  subsection?: PaperworkRoute;
  stakeholders?: Record<string, StakeholderDraft>;
  disabled?: boolean;
  buttonProps?: ButtonProps;
}

function AddAttachmentDialogComponent(props: AddAttachmentDialogProps) {
  const { subsection, stakeholders, disabled, buttonProps } = props;
  const [open, setOpen] = React.useState(false);
  const [uploadedAttachment, setUploadedAttachment] = useState<Attachment['attachment']>();

  const { paperwork } = usePaperwork();
  const {
    createAttachment: createPaperworkAttachment,
    saveAttachments,
    discardOperations: discardAttachmentOperations,
  } = useAttachments();
  const { saveValues } = usePaperworkFields();
  const [createAttachment, createAttachmentRequest] = useCreateAttachmentMutation();
  const { route } = usePaperworkNavigation();

  const contextId = subsection?.context?.id ?? route?.context?.id ?? -1;

  const { contextFields, updateValue, discardOperations: discardFieldOperations } = usePaperworkFields();

  const { digitallySignedField, originalAvailableField, paperworkDigitallySignedField } = useMemo(
    () => ({
      digitallySignedField: contextFields.find(
        (field) => field.datatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED
      ),
      originalAvailableField: contextFields.find(
        (field) => field.datatype === PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE
      ),
      paperworkDigitallySignedField: contextFields.find(
        (field) => field.datatype === PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED
      ),
    }),
    [contextFields]
  );

  const AddAttachmentFormSchema = useMemo(
    () =>
      z
        .object({
          file: z
            .object(
              {
                name: z.string(),
              },
              { required_error: 'Caricare un documento' }
            )
            .passthrough(),
          stakeholders: stakeholders
            ? StakeholderSchema.shape.id.array().min(1, 'Selezionare almeno una parte coinvolta')
            : z.array(z.unknown()).max(0),
          digitallySigned: digitallySignedField ? z.boolean() : z.undefined(),
          originalAvailable: originalAvailableField ? z.boolean().optional() : z.undefined(),
          paperworkDigitallySigned: paperworkDigitallySignedField ? z.boolean().optional() : z.undefined(),
        })
        .superRefine((value, ctx) => {
          if (value.digitallySigned === false) {
            if (value.originalAvailable === undefined) {
              ctx.addIssue({
                path: ['originalAvailable'],
                code: 'invalid_type',
                message: 'Campo obbligatorio',
                expected: 'boolean',
                received: 'undefined',
              });
            }
          }
        }),
    [digitallySignedField, originalAvailableField, paperworkDigitallySignedField, stakeholders]
  );

  type AddAttachmentForm = z.infer<typeof AddAttachmentFormSchema>;

  const { control, handleSubmit, reset, watch, formState } = useForm<AddAttachmentForm>({
    defaultValues: {
      file: undefined,
      stakeholders: [],
    },
    resolver: zodResolver(AddAttachmentFormSchema),
  });

  const digitallySigned = watch('digitallySigned');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dataGridColumns = useAddAttachmentDialogColumns();

  async function onSubmit(values: AddAttachmentForm) {
    if (uploadedAttachment && paperwork) {
      if (!stakeholders) {
        discardAttachmentOperations();
        if (uploadedAttachment) {
          createPaperworkAttachment(
            addDraftIdToAttachment({
              attachment: uploadedAttachment,
              savedInContextId: contextId,
              stakeholderIdList: [],
            })
          );
        }
        if (values.paperworkDigitallySigned !== undefined) {
          updateValue(
            addDraftIdToPaperworkFieldValue({
              fieldDatatype: PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED,
              progIndex: 0,
              attachmentID: uploadedAttachment.id,
              value: values.paperworkDigitallySigned ? 'yes' : 'no',
            })
          );
        }
      }

      // Save the paperwork attachments (attachmentId ⨯ stakeholderId)
      await saveAttachments();

      // Push paperwork values for each paperwork attachment
      if (values.stakeholders.length > 0) {
        for (const stakeholder of values.stakeholders as Stakeholder['id'][]) {
          if (values.digitallySigned !== undefined) {
            updateValue(
              addDraftIdToPaperworkFieldValue({
                fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED,
                progIndex: 0,
                attachmentID: uploadedAttachment.id,
                stakeholderID: stakeholder,
                value: values.digitallySigned ? 'yes' : 'no',
              })
            );
            if (values.originalAvailable !== undefined) {
              updateValue(
                addDraftIdToPaperworkFieldValue({
                  fieldDatatype: PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE,
                  progIndex: 0,
                  attachmentID: uploadedAttachment.id,
                  stakeholderID: stakeholder,
                  value: values.originalAvailable ? 'yes' : 'no',
                })
              );
            }
          }
        }
      }

      // Save the operations above
      saveValues();

      // Close the dialog
      handleClose();
    }
  }

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} disabled={disabled} {...buttonProps}>
        {buttonProps?.children ?? 'Aggiungi documento'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
        fullWidth
        maxWidth="lg"
        TransitionProps={{
          onExited: () => {
            reset();
            discardFieldOperations();
            discardAttachmentOperations();
            setUploadedAttachment(undefined);
          },
        }}
      >
        <DialogTitle>Aggiungi documento</DialogTitle>
        <DialogContent>
          {createAttachmentRequest.error ? (
            <Alert severity="error">{JSON.stringify(createAttachmentRequest.error, null, 2)}</Alert>
          ) : null}
          <DialogContentText component="div" display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              name="file"
              render={({ field, fieldState: { error } }) => (
                <FileDropzone
                  error={error}
                  onChange={async (file) => {
                    field.onChange(file);

                    if (file) {
                      const createAttachmentResponse = await createAttachment({
                        file: file as unknown as File,
                      }).unwrap();
                      setUploadedAttachment(createAttachmentResponse);
                    } else {
                      setUploadedAttachment(undefined);
                    }
                  }}
                  value={field.value as unknown as File}
                />
              )}
            />
            {uploadedAttachment ? (
              <>
                {contextFields.length ? (
                  <>
                    <Alert severity="warning">{'Per procedere, rispondi alle domande seguenti'}</Alert>
                    <Grid container>
                      {digitallySignedField ? (
                        <Grid key={digitallySignedField.id} item xs={12} md={6} lg={6}>
                          <Controller
                            control={control}
                            name="digitallySigned"
                            render={({ field }) => (
                              <FormControl key={PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_DIGITALLY_SIGNED}>
                                <FormLabel id={digitallySignedField.datatype}>{digitallySignedField.label}</FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={digitallySignedField.datatype}
                                  {...field}
                                  value={field.value === true ? 'yes' : field.value === false ? 'no' : undefined}
                                  onChange={(_, value) => {
                                    field.onChange(value === 'yes');
                                  }}
                                  sx={{
                                    gap: 3,
                                    paddingX: 2,
                                    paddingY: 1,
                                    borderRadius: 1,
                                  }}
                                >
                                  <FormControlLabel value="yes" control={<Radio />} label="Si" />
                                  <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      ) : null}
                      {originalAvailableField ? (
                        <Grid key={originalAvailableField.id} item xs={12} md={6} lg={6}>
                          <Controller
                            control={control}
                            name="originalAvailable"
                            render={({ field }) => (
                              <FormControl
                                key={PAPERWORK_FIELD_DATATYPE.ATTACHMENTS_ORIGINAL_AVAILABLE}
                                disabled={digitallySigned !== false}
                              >
                                <FormLabel id={originalAvailableField.datatype}>
                                  {originalAvailableField.label}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={originalAvailableField.datatype}
                                  {...field}
                                  value={field.value === true ? 'yes' : field.value === false ? 'no' : undefined}
                                  onChange={(_, value) => {
                                    field.onChange(value === 'yes');
                                  }}
                                  sx={{
                                    gap: 3,
                                    paddingX: 2,
                                    paddingY: 1,
                                  }}
                                >
                                  <FormControlLabel value="yes" control={<Radio />} label="Si" />
                                  <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      ) : null}
                      {paperworkDigitallySignedField ? (
                        <Grid key={paperworkDigitallySignedField.id} item xs={12} md={6} lg={6}>
                          <Controller
                            control={control}
                            name="paperworkDigitallySigned"
                            render={({ field }) => (
                              <FormControl key={PAPERWORK_FIELD_DATATYPE.PAPERWORK_DIGITALLY_SIGNED}>
                                <FormLabel id={paperworkDigitallySignedField.datatype}>
                                  {paperworkDigitallySignedField.label}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={paperworkDigitallySignedField.datatype}
                                  {...field}
                                  value={field.value === true ? 'yes' : field.value === false ? 'no' : undefined}
                                  onChange={(_, value) => {
                                    field.onChange(value === 'yes');
                                  }}
                                  sx={{
                                    gap: 3,
                                    paddingX: 2,
                                    paddingY: 1,
                                  }}
                                >
                                  <FormControlLabel value="yes" control={<Radio />} label="Si" />
                                  <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </>
                ) : null}
                {stakeholders ? (
                  <Controller
                    control={control}
                    name="stakeholders"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" gap={1}>
                        <Typography variant="h6" color="text.primary">
                          {'Associa il documento a una o più parti coinvolte'}
                        </Typography>
                        <Alert severity="warning">
                          {'Per procedere seleziona la/le parte/i coinvolta/e a cui allegare il documento'}
                        </Alert>
                        <Paper sx={{ height: 300, borderColor: error ? 'error.main' : undefined }}>
                          <DataGridPremium
                            density="compact"
                            rows={Object.values(stakeholders).filter((stakeholder) => stakeholder.id !== undefined)}
                            columns={dataGridColumns}
                            disableRowGrouping
                            disableAggregation
                            checkboxSelection
                            hideFooter
                            rowSelectionModel={(field.value ?? []) as Stakeholder['id'][]}
                            onRowSelectionModelChange={(rowSelectionModel) => {
                              field.onChange(rowSelectionModel);
                              discardAttachmentOperations();
                              if (uploadedAttachment) {
                                createPaperworkAttachment(
                                  addDraftIdToAttachment({
                                    attachment: uploadedAttachment,
                                    savedInContextId: contextId,
                                    stakeholderIdList: rowSelectionModel as number[],
                                  })
                                );
                              }
                            }}
                            sx={{ border: 0 }}
                          />
                        </Paper>
                        {error ? <FormHelperText error>{error?.message}</FormHelperText> : null}
                      </Stack>
                    )}
                  />
                ) : null}
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {'Annulla'}
          </Button>
          <Button type="submit" disabled={!uploadedAttachment || !formState.isValid}>
            {'Aggiungi'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const AddAttachmentDialog = React.memo(AddAttachmentDialogComponent);
